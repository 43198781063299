export const withdrawalsTypes = {
  CREATE_WITHDRAWAL_START: 'CREATE_WITHDRAWAL_START',
  CREATE_WITHDRAWAL_SUCCESS: 'CREATE_WITHDRAWAL_SUCCESS',
  CREATE_WITHDRAWAL_FAILURE: 'CREATE_WITHDRAWAL_FAILURE',
  UPDATE_WITHDRAWAL_START: 'UPDATE_WITHDRAWAL_START',
  UPDATE_WITHDRAWAL_SUCCESS: 'UPDATE_WITHDRAWAL_SUCCESS',
  UPDATE_WITHDRAWAL_FAILURE: 'UPDATE_WITHDRAWAL_FAILURE',
  DELETE_WITHDRAWAL_START: 'DELETE_WITHDRAWAL_START',
  DELETE_WITHDRAWAL_SUCCESS: 'DELETE_WITHDRAWAL_SUCCESS',
  DELETE_WITHDRAWAL_FAILURE: 'DELETE_WITHDRAWAL_FAILURE',
  GET_WITHDRAWALS_START: 'GET_WITHDRAWALS_START',
  GET_WITHDRAWALS_SUCCESS: 'GET_WITHDRAWALS_SUCCESS',
  GET_WITHDRAWALS_FAILURE: 'GET_WITHDRAWALS_FAILURE',
  GET_MORE_WITHDRAWALS_START: 'GET_MORE_WITHDRAWALS_START',
  GET_MORE_WITHDRAWALS_SUCCESS: 'GET_MORE_WITHDRAWALS_SUCCESS',
  GET_MORE_WITHDRAWALS_FAILURE: 'GET_MORE_WITHDRAWALS_FAILURE',
};
