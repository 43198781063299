import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { PurchaseService } from './purchases.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getPurchasesStart,
  getPurchasesSuccess,
  getPurchasesFailure,
  getMorePurchasesStart,
  getMorePurchasesFailure,
  getMorePurchasesSuccess,
  createPurchaseStart,
  createPurchaseSuccess,
  createPurchaseFailure,
  deletePurchaseStart,
  deletePurchaseSuccess,
  deletePurchaseFailure,
  updatePurchaseStart,
  updatePurchaseSuccess,
  updatePurchaseFailure,
  getPurchaseDetailsStart,
  getPurchaseDetailsSuccess,
  getPurchaseDetailsFailure,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class PurchaseEffects {
  constructor(
    private purchaseService: PurchaseService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  getPurchaseDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPurchaseDetailsStart),
      switchMap(({ payload: { id } }) =>
        this.purchaseService.getPurchaseDetails(id).pipe(
          mergeMap(({ data }) => [getPurchaseDetailsSuccess({ payload: { purchase: data } })]),
          catchError((error) => observableOf(getPurchaseDetailsFailure({ error: error?.message })))
        )
      )
    )
  );

  getPurchasesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPurchasesStart),
      switchMap(({ payload }) =>
        this.purchaseService.getPurchases(payload).pipe(
          mergeMap((data) => [getPurchasesSuccess({ payload: data })]),
          catchError((error) => observableOf(getPurchasesFailure({ error: error?.message })))
        )
      )
    )
  );

  getMorePurchasesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMorePurchasesStart),
      switchMap(({ payload }) =>
        this.purchaseService.getMorePurchases(payload).pipe(
          mergeMap((data) => [getMorePurchasesSuccess({ payload: data, append: !!payload['append'] })]),
          catchError((error) => observableOf(getMorePurchasesFailure({ error: error?.message })))
        )
      )
    )
  );

  createPurchaseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPurchaseStart),
      switchMap(({ payload }) =>
        this.purchaseService.createPurchase(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createPurchaseSuccess(), getPurchasesStart({})];
          }),
          catchError((error) => observableOf(createPurchaseFailure({ error: error?.message })))
        )
      )
    )
  );

  updatePurchaseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePurchaseStart),
      switchMap(({ payload, id }) =>
        this.purchaseService.updatePurchase(payload, id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [updatePurchaseSuccess(), getPurchasesStart({}), getPurchaseDetailsStart({ payload: { id } })];
          }),
          catchError((error) => observableOf(updatePurchaseFailure({ error: error?.message })))
        )
      )
    )
  );

  deletePurchaseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePurchaseStart),
      switchMap(({ payload: { id } }) =>
        this.purchaseService.deletePurchase(id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [deletePurchaseSuccess(), getPurchasesStart({})];
          }),
          catchError((error) => observableOf(deletePurchaseFailure({ error: error?.message })))
        )
      )
    )
  );
}
