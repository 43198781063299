export const transfersTypes = {
  UPDATE_TRANSFER_START: 'UPDATE_TRANSFER_START',
  UPDATE_TRANSFER_SUCCESS: 'UPDATE_TRANSFER_SUCCESS',
  UPDATE_TRANSFER_FAILURE: 'UPDATE_TRANSFER_FAILURE',
  CREATE_TRANSFER_START: 'CREATE_TRANSFER_START',
  CREATE_TRANSFER_SUCCESS: 'CREATE_TRANSFER_SUCCESS',
  CREATE_TRANSFER_FAILURE: 'CREATE_TRANSFER_FAILURE',
  DELETE_TRANSFER_START: 'DELETE_TRANSFER_START',
  DELETE_TRANSFER_SUCCESS: 'DELETE_TRANSFER_SUCCESS',
  DELETE_TRANSFER_FAILURE: 'DELETE_TRANSFER_FAILURE',
  GET_TRANSFERS_START: 'GET_TRANSFERS_START',
  GET_TRANSFERS_SUCCESS: 'GET_TRANSFERS_SUCCESS',
  GET_TRANSFERS_FAILURE: 'GET_TRANSFERS_FAILURE',
  GET_MORE_TRANSFERS_START: 'GET_MORE_TRANSFERS_START',
  GET_MORE_TRANSFERS_SUCCESS: 'GET_MORE_TRANSFERS_SUCCESS',
  GET_MORE_TRANSFERS_FAILURE: 'GET_MORE_TRANSFERS_FAILURE',
};
