export const boxesTypes = {
  CREATE_BOX_START: 'CREATE_BOX_START',
  CREATE_BOX_SUCCESS: 'CREATE_BOX_SUCCESS',
  CREATE_BOX_FAILURE: 'CREATE_BOX_FAILURE',
  EDIT_BOX_START: 'EDIT_BOX_START',
  EDIT_BOX_SUCCESS: 'EDIT_BOX_SUCCESS',
  EDIT_BOX_FAILURE: 'EDIT_BOX_FAILURE',
  DELETE_BOX_START: 'DELETE_BOX_START',
  DELETE_BOX_SUCCESS: 'DELETE_BOX_SUCCESS',
  DELETE_BOX_FAILURE: 'DELETE_BOX_FAILURE',
  GET_BOXES_START: 'GET_BOXES_START',
  GET_BOXES_SUCCESS: 'GET_BOXES_SUCCESS',
  GET_BOXES_FAILURE: 'GET_BOXES_FAILURE',
  GET_MORE_BOXES_START: 'GET_MORE_BOXES_START',
  GET_MORE_BOXES_SUCCESS: 'GET_MORE_BOXES_SUCCESS',
  GET_MORE_BOXES_FAILURE: 'GET_MORE_BOXES_FAILURE',
  GET_BOX_DETAILS_START: 'GET_BOX_DETAILS_START',
  GET_BOX_DETAILS_SUCCESS: 'GET_BOX_DETAILS_SUCCESS',
  GET_BOX_DETAILS_FAILURE: 'GET_BOX_DETAILS_FAILURE',
  GET_BOX_SHIPMENTS_START: 'GET_BOX_SHIPMENTS_START',
  GET_BOX_SHIPMENTS_SUCCESS: 'GET_BOX_SHIPMENTS_SUCCESS',
  GET_BOX_SHIPMENTS_FAILURE: 'GET_BOX_SHIPMENTS_FAILURE',
  GET_MORE_BOX_SHIPMENTS_START: 'GET_MORE_BOX_SHIPMENTS_START',
  GET_MORE_BOX_SHIPMENTS_SUCCESS: 'GET_MORE_BOX_SHIPMENTS_SUCCESS',
  GET_MORE_BOX_SHIPMENTS_FAILURE: 'GET_MORE_BOX_SHIPMENTS_FAILURE',
  ADD_SHIPMENT_TO_BOX_START: 'ADD_SHIPMENT_TO_BOX_START',
  ADD_SHIPMENT_TO_BOX_SUCCESS: 'ADD_SHIPMENT_TO_BOX_SUCCESS',
  ADD_SHIPMENT_TO_BOX_FAILURE: 'ADD_SHIPMENT_TO_BOX_FAILURE',
  REMOVE_SHIPMENT_FROM_BOX_START: 'REMOVE_SHIPMENT_FROM_BOX_START',
  REMOVE_SHIPMENT_FROM_BOX_SUCCESS: 'REMOVE_SHIPMENT_FROM_BOX_SUCCESS',
  REMOVE_SHIPMENT_FROM_BOX_FAILURE: 'REMOVE_SHIPMENT_FROM_BOX_FAILURE',
  UPDATE_BOX_STATUS_START: 'UPDATE_BOX_STATUS_START',
  UPDATE_BOX_STATUS_SUCCESS: 'UPDATE_BOX_STATUS_SUCCESS',
  UPDATE_BOX_STATUS_FAILURE: 'UPDATE_BOX_STATUS_FAILURE',
};
