import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BeyToastService implements OnDestroy {
  constructor(private toast: MatSnackBar) {}
  toastSub$: Subscription = new Subscription();

  open(
    message?: string,
    variant: 'white' | 'blue' | 'error' = 'blue',
    actionTitle?: string,
    actionFunction?: () => void,
    top?: boolean,
    permanent?: boolean,
    marginBottom?: boolean
  ) {
    this.toastSub$.add(
      this.toast
        .open(message ? message : 'Something went wrong, please try again', actionTitle ? actionTitle : null, {
          duration: permanent ? 71000 : 3000,
          panelClass: [variant === 'error' ? 'bg-danger' : 'bg-success', 'snack-bar-aligning-right'],
          verticalPosition: 'top',
          direction: 'rtl',
        })
        .onAction()
        .subscribe(actionFunction)
    );
  }

  ngOnDestroy(): void {
    this.toastSub$.unsubscribe();
  }
}
