<label style="width: 100%; text-align: right" *ngIf="!!label">{{ label }}</label>
<div style="position: relative; width: 100%" class="x-sign">
  <textarea
    *ngIf="type === 'textarea'; else input"
    [dir]="dir"
    [placeholder]="placeholder"
    [name]="name"
    [ngClass]="{
      'push-right': clearable,
      'form-control': true,
      'is-invalid': displayError,
      'form-control-sm': size === 'small',
      'form-control-lg': size === 'large'
    }"
    [(ngModel)]="value"
    (blur)="onBlur()"
    [readOnly]="readonly"
    [id]="id"
    (keyup.enter)="enterClickHandler()"></textarea>
  <ng-template #input>
    <div dir="rtl" class="input-group align-items-center" style="gap: 5px">
      <div class="input-group-append" [hidden]="!append.hasChildNodes()" #append>
        <ng-content select="[append]"></ng-content>
      </div>
      <input
        *ngIf="type === 'amount'; else normalInput"
        type="text"
        pattern="[0-9]*"
        inputmode="numeric"
        dir="ltr"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [name]="name"
        [(ngModel)]="value"
        [required]="required"
        (blur)="onBlur()"
        [readOnly]="readonly || readonlyNoFeedback"
        [id]="id ? id : null"
        [autofocus]="autofocus"
        (keyup.enter)="enterClickHandler()"
        currencyMask
        [ngClass]="{
          'push-right': clearable,
          'form-control': true,
          'is-invalid': displayError,
          'form-control-sm': size === 'small',
          'form-control-lg': size === 'large'
        }"
        [options]="{
          prefix: currencySymbol || '',
          align: 'right',
          thousands: ',',
          decimal: '.',
          precision: allowDecimals ? 3 : 0,
          allowNegative: false
        }" />
      <ng-template #normalInput>
        <input
          [dir]="type === 'tel' || name === 'phone_number' ? 'ltr' : dir"
          [placeholder]="placeholder"
          [type]="type"
          [name]="name"
          [ngClass]="{
            'push-right': clearable,
            'form-control': true,
            'is-invalid': displayError,
            'form-control-sm': size === 'small',
            'form-control-lg': size === 'large'
          }"
          [(ngModel)]="value"
          (input)="onValueChange($event)"
          (blur)="onBlur()"
          [readOnly]="readonly"
          [id]="id"
          (keyup.enter)="enterClickHandler()"
          appNoScrollInput />
      </ng-template>
      <div class="input-group-prepend" [hidden]="!prepend.hasChildNodes()" #prepend>
        <ng-content select="[prepend]"></ng-content>
      </div>
    </div>
  </ng-template>

  <div
    *ngIf="!hideValidationFeedback"
    style="min-height: 17px"
    class="d-flex align-items-center justify-content-between">
    <span style="color: #ea5455; width: 100%; text-align: start" [style.opacity]="displayError ? 1 : 0">{{
      errorMessage || '&nbsp;'
    }}</span>
    <span *ngIf="!!charactersLimit" [ngStyle]="{ color: value?.length > charactersLimit ? '#ea5455' : '#5E5873' }"
      >{{ value?.length
      }}<span [ngStyle]="{ color: value?.length > charactersLimit ? '#ea5455' : '#D8D6DE' }"
        >/{{ charactersLimit }}</span
      ></span
    >
  </div>
  <div *ngIf="clearable && !!value" (click)="onXSignClick()">
    <span data-feather="x"></span>
  </div>
</div>
