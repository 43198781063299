import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BeyDialogData } from '../../interfaces';

@Component({
  selector: 'app-bey-dialog',
  templateUrl: './bey-dialog.component.html',
  styleUrls: ['./bey-dialog.component.scss'],
})
export class BeyDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: BeyDialogData, public dialog: MatDialog) {}

  ngOnInit(): void {}

  onButtonClick(index: number): void {
    if (this.data['buttons'][index]['doesCloseDialog']) {
      this.dialog.closeAll();
    }
    this.data['buttons'][index]['onClick']();
  }
}
