import { createReducer, on } from '@ngrx/store';
import { DepositsState } from './deposits.interfaces';
import {
  getDepositsStart,
  getDepositsSuccess,
  getDepositsFailure,
  getMoreDepositsStart,
  getMoreDepositsSuccess,
  getMoreDepositsFailure,
  createDepositStart,
  createDepositSuccess,
  createDepositFailure,
  deleteDepositStart,
  deleteDepositSuccess,
  deleteDepositFailure,
  updateDepositStart,
  updateDepositSuccess,
  updateDepositFailure,
} from '../actions';

const initialState: DepositsState = {
  deposits: null,
  depositsMeta: null,
  isLoading: {
    getDeposits: false,
    getMoreDeposits: false,
    createDeposit: false,
    deleteDeposit: false,
    updateDeposit: false,
  },
  errorMessages: {
    getDeposits: '',
    getMoreDeposits: '',
    createDeposit: '',
    deleteDeposit: '',
    updateDeposit: '',
  },
};

export const depositReducer = createReducer(
  initialState,
  //----------START----------
  on(getDepositsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getDeposits: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getDeposits: '',
    },
  })),
  on(getMoreDepositsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreDeposits: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreDeposits: '',
    },
  })),
  on(createDepositStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createDeposit: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createDeposit: '',
    },
  })),
  on(updateDepositStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateDeposit: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateDeposit: '',
    },
  })),
  on(deleteDepositStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteDeposit: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteDeposit: '',
    },
  })),
  //----------SUCCESS----------
  on(getDepositsSuccess, (state, { payload }) => ({
    ...state,
    deposits: payload['data'],
    depositsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getDeposits: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getDeposits: '',
      getMoreDeposits: '',
    },
  })),
  on(getMoreDepositsSuccess, (state, { payload }) => ({
    ...state,
    deposits: payload['data'],
    depositsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreDeposits: false,
    },
  })),
  on(createDepositSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createDeposit: false,
    },
  })),
  on(updateDepositSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateDeposit: false,
    },
  })),
  on(deleteDepositSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteDeposit: false,
    },
  })),
  //----------FAILURE----------
  on(getDepositsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getDeposits: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getDeposits: error,
    },
  })),
  on(getMoreDepositsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreDeposits: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreDeposits: error,
    },
  })),
  on(createDepositFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createDeposit: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createDeposit: error,
    },
  })),
  on(updateDepositFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateDeposit: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateDeposit: error,
    },
  })),
  on(deleteDepositFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteDeposit: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteDeposit: error,
    },
  }))
);
