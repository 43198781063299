export const purchasesTypes = {
  GET_PURCHASE_DETAILS_START: 'GET_PURCHASE_DETAILS_START',
  GET_PURCHASE_DETAILS_SUCCESS: 'GET_PURCHASE_DETAILS_SUCCESS',
  GET_PURCHASE_DETAILS_FAILURE: 'GET_PURCHASE_DETAILS_FAILURE',
  CREATE_PURCHASE_START: 'CREATE_PURCHASE_START',
  CREATE_PURCHASE_SUCCESS: 'CREATE_PURCHASE_SUCCESS',
  CREATE_PURCHASE_FAILURE: 'CREATE_PURCHASE_FAILURE',
  EDIT_PURCHASE_START: 'EDIT_PURCHASE_START',
  EDIT_PURCHASE_SUCCESS: 'EDIT_PURCHASE_SUCCESS',
  EDIT_PURCHASE_FAILURE: 'EDIT_PURCHASE_FAILURE',
  DELETE_PURCHASE_START: 'DELETE_PURCHASE_START',
  DELETE_PURCHASE_SUCCESS: 'DELETE_PURCHASE_SUCCESS',
  DELETE_PURCHASE_FAILURE: 'DELETE_PURCHASE_FAILURE',
  GET_PURCHASES_START: 'GET_PURCHASES_START',
  GET_PURCHASES_SUCCESS: 'GET_PURCHASES_SUCCESS',
  GET_PURCHASES_FAILURE: 'GET_PURCHASES_FAILURE',
  GET_MORE_PURCHASES_START: 'GET_MORE_PURCHASES_START',
  GET_MORE_PURCHASES_SUCCESS: 'GET_MORE_PURCHASES_SUCCESS',
  GET_MORE_PURCHASES_FAILURE: 'GET_MORE_PURCHASES_FAILURE',
};
