import { createAction, props } from '@ngrx/store';
import { ErrorType } from '../interfaces';
import {
  GetUserMoreWalletOperationsResponseType,
  GetUserWalletOperationsResponseType,
  GetUserWalletsResponseType,
} from './wallets.interfaces';
import { walletsTypes } from './wallets.types';

export const getUserWalletsStart = createAction(walletsTypes.GET_USER_WALLETS_START);
export const getUserWalletsSuccess = createAction(
  walletsTypes.GET_USER_WALLETS_SUCCESS,
  props<{ payload: GetUserWalletsResponseType }>()
);
export const getUserWalletsFailure = createAction(walletsTypes.GET_USER_WALLETS_FAILURE, props<ErrorType>());

export const getUserWalletOperationsStart = createAction(
  walletsTypes.GET_USER_WALLET_OPERATIONS_START,
  props<{ payload: { wallet_id: number } }>()
);
export const getUserWalletOperationsSuccess = createAction(
  walletsTypes.GET_USER_WALLET_OPERATIONS_SUCCESS,
  props<{ payload: GetUserWalletOperationsResponseType }>()
);
export const getUserWalletOperationsFailure = createAction(
  walletsTypes.GET_USER_WALLET_OPERATIONS_FAILURE,
  props<ErrorType>()
);

export const getMoreUserWalletOperationsStart = createAction(
  walletsTypes.GET_MORE_USER_WALLET_OPERATIONS_START,
  props<{ payload: { wallet_id: number; page: number } }>()
);
export const getMoreUserWalletOperationsSuccess = createAction(
  walletsTypes.GET_MORE_USER_WALLET_OPERATIONS_SUCCESS,
  props<{ payload: GetUserMoreWalletOperationsResponseType }>()
);
export const getMoreUserWalletOperationsFailure = createAction(
  walletsTypes.GET_MORE_USER_WALLET_OPERATIONS_FAILURE,
  props<ErrorType>()
);
