import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BeySelectMenuOption } from '../../interfaces';

@Component({
  selector: 'bey-menu',
  templateUrl: './bey-menu.component.html',
  styleUrls: ['./bey-menu.component.scss'],
})
export class BeyMenuComponent implements OnInit {
  /****
   * the options that will be displayed inside the menu
   */
  @Input()
  options: Array<BeySelectMenuOption> = [];

  /****
   * indicates if multiple options selection is available
   */
  @Input()
  multipleSelection: boolean;

  /****
   * Array of the selected options
   */
  @Input()
  selectedOptions: Array<BeySelectMenuOption>;

  /****
   * indicates if the menu is opened
   */
  @Input()
  opened: boolean;

  @Output()
  itemSelected: EventEmitter<BeySelectMenuOption> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSelect(option: BeySelectMenuOption) {
    this.itemSelected.emit(option);
  }

  checkIfOptionIsSelected(value: any): boolean {
    return false;
    // return Boolean(this.selectedOptions.find((option) => option.value === value));
  }
}
