<div>
  <div class="p-2 d-flex flex-column">
    <div class="d-flex align-items-center w-100 justify-content-between" style="margin-bottom: 22px">
      <h4 class="card-title m-0">{{ title }}</h4>
      <ng-content select="[mainBarButton]"></ng-content>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center flex-wrap">
        <bey-input
          class="mr-1"
          *ngFor="let searchBar of search"
          [placeholder]="searchBar?.placeholder"
          [label]="searchBar?.label"
          [ngStyle]="searchBar.minWidth && { 'min-width': searchBar.minWidth + 'px' }"
          [hideValidationFeedback]="true"
          [clearable]="true"
          size="large"
          [(ngModel)]="searchFields[searchBar.key]"
          (onEnterClick)="onSearchFieldEnterClick()"
          (onClear)="onSearchFieldClear()"></bey-input>
        <bey-filter
          *ngIf="!!filters"
          class="mr-1"
          (onChange)="onSelectingFilter($event)"
          [id]="id"
          [options]="filters.options"
          [multipleSelection]="filters.multipleSelection"
          [placeholder]="filters.placeholder"></bey-filter>
        <bey-sort
          *ngIf="!!sort"
          [options]="sort.options"
          [placeholder]="sort.placeholder"
          (onChange)="onSortOptionSelect($event)"></bey-sort>
        <!-- <bey-months-selector
          *ngIf="filteringByMonths"
          [initialValues]="beyMonthsSelectorInitialValues"
          (durationSelected)="onMonthsDurationSelect($event)">
        </bey-months-selector> -->
      </div>
      <ng-content select="[subBarButton]"></ng-content>
    </div>
  </div>
  <div class="card-body py-0">
    <div class="table-responsive">
      <table class="table table-borderless" [ngStyle]="(pagination.contentIsLoading | async) ? { opacity: 0.2 } : ''">
        <thead>
          <tr>
            <th
              *ngFor="let col of columns"
              (click)="onColumnClicked(col)"
              [ngClass]="col.isSortable ? 'cursor-pointer' : ''"
              [ngStyle]="col.minWidth && { 'min-width': col.minWidth + 'px' }">
              <span class="text-right d-block">{{ col.title }}</span>
              <span
                *ngIf="col.isSortable"
                [inner]="true"
                [data-feather]="
                  col.property === (selectedColumnSubject | async)?.name
                    ? ((selectedColumnSubject | async)?.icon).toString()
                    : 'code'
                "
                [ngClass]="(selectedColumnSubject | async)?.icon === 'code' ? 'rotate ml-50' : 'ml-50'"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-content *ngFor="let row of rows.toArray()"></ng-content>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer">
    <bey-pagination
      (pageChanged)="onPaginationPageChange($event)"
      [tableID]="id"
      [limit]="15"
      [contentIsLoading]="pagination.contentIsLoading"
      [totalItems]="(tableContent | async)?.total"
      [currentPage]="currentPage"></bey-pagination>
  </div>
</div>
