import { createReducer, on } from '@ngrx/store';
import { WithdrawalsState } from './withdrawals.interfaces';
import {
  getWithdrawalsStart,
  getWithdrawalsSuccess,
  getWithdrawalsFailure,
  getMoreWithdrawalsStart,
  getMoreWithdrawalsSuccess,
  getMoreWithdrawalsFailure,
  createWithdrawalStart,
  createWithdrawalSuccess,
  createWithdrawalFailure,
  deleteWithdrawalStart,
  deleteWithdrawalSuccess,
  deleteWithdrawalFailure,
  updateWithdrawalStart,
  updateWithdrawalSuccess,
  updateWithdrawalFailure,
} from '../actions';

const initialState: WithdrawalsState = {
  withdrawals: null,
  withdrawalsMeta: null,
  isLoading: {
    getWithdrawals: false,
    getMoreWithdrawals: false,
    createWithdrawal: false,
    deleteWithdrawal: false,
    updateWithdrawal: false,
  },
  errorMessages: {
    getWithdrawals: '',
    getMoreWithdrawals: '',
    createWithdrawal: '',
    deleteWithdrawal: '',
    updateWithdrawal: '',
  },
};

export const withdrawalReducer = createReducer(
  initialState,
  //----------START----------
  on(getWithdrawalsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getWithdrawals: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getWithdrawals: '',
    },
  })),
  on(getMoreWithdrawalsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreWithdrawals: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreWithdrawals: '',
    },
  })),
  on(createWithdrawalStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createWithdrawal: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createWithdrawal: '',
    },
  })),
  on(updateWithdrawalStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateWithdrawal: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateWithdrawal: '',
    },
  })),
  on(deleteWithdrawalStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteWithdrawal: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteWithdrawal: '',
    },
  })),
  //----------SUCCESS----------
  on(getWithdrawalsSuccess, (state, { payload }) => ({
    ...state,
    withdrawals: payload['data'],
    withdrawalsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getWithdrawals: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getWithdrawals: '',
      getMoreWithdrawals: '',
    },
  })),
  on(getMoreWithdrawalsSuccess, (state, { payload }) => ({
    ...state,
    withdrawals: payload['data'],
    withdrawalsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreWithdrawals: false,
    },
  })),
  on(createWithdrawalSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createWithdrawal: false,
    },
  })),
  on(deleteWithdrawalSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteWithdrawal: false,
    },
  })),
  on(updateWithdrawalSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateWithdrawal: false,
    },
  })),
  //----------FAILURE----------
  on(getWithdrawalsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getWithdrawals: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getWithdrawals: error,
    },
  })),
  on(getMoreWithdrawalsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreWithdrawals: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreWithdrawals: error,
    },
  })),
  on(createWithdrawalFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createWithdrawal: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createWithdrawal: error,
    },
  })),
  on(updateWithdrawalFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateWithdrawal: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateWithdrawal: error,
    },
  })),
  on(deleteWithdrawalFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteWithdrawal: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteWithdrawal: error,
    },
  }))
);
