import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { UserState, Admin } from './user.interfaces';

const selectUser = (state: AppState) => {
  return state.user;
};

//---------1ST LEVEL SELECTORS---------
export const selectUserDetails = createSelector(selectUser, (state: UserState) => state.userDetails);

export const selectUserId = createSelector(selectUser, (state: UserState) => state.userDetails?.id);

export const selectUsers = createSelector(selectUser, (state: UserState) => state.users);
export const selectUsersObject = createSelector(selectUser, (state: UserState) => {
  return {
    results: state.users,
    currentPage: state['usersMeta']?.['current_page'] | 1,
    total: state['usersMeta']?.['total'] | 1,
  };
});

export const selectUsersMeta = createSelector(selectUser, (state: UserState) => state.usersMeta);

export const selectUserIsLoading = createSelector(selectUser, (state: UserState) => state.isLoading);

export const selectUserErrorMessages = createSelector(selectUser, (state: UserState) => state.errorMessages);

// Admin selectors
export const selectAdmins = createSelector(selectUser, (state: UserState) => state.admins);
export const selectAdminsObject = createSelector(selectUser, (state: UserState) => {
  return {
    results: state.admins,
    currentPage: state['adminsMeta']?.['current_page'] | 1,
    total: state['adminsMeta']?.['total'] | 1,
  };
});

export const selectAdminsMeta = createSelector(selectUser, (state: UserState) => state.adminsMeta);

export const selectAdminIsLoading = createSelector(selectUser, (state: UserState) => state.isLoading);

export const selectAdminErrorMessages = createSelector(selectUser, (state: UserState) => state.errorMessages);

//---------2ND LEVEL SELECTORS---------
export const selectGetUsersMoreNextUrl = createSelector(selectUsersMeta, (meta) => meta?.['links']?.[2]?.url);
export const selectGetMoreUsersNextUrlAndCurrentPage = createSelector(selectUsersMeta, (meta) => {
  return { url: meta?.['links']?.find((link) => link.label.includes('Next'))?.url, currentPage: meta?.current_page };
});

export const selectGetUserDetailsIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['getUserDetails']
);
export const selectGetUserDetailsErrorMessage = createSelector(
  selectUserErrorMessages,
  (errorMessage) => errorMessage['getUserDetails']
);

export const selectGetUsersIsLoading = createSelector(selectUserIsLoading, (isLoading) => isLoading['getUsers']);
export const selectGetUsersErrorMessage = createSelector(
  selectUserErrorMessages,
  (errorMessage) => errorMessage['getUsers']
);

export const selectGetUsersMoreIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['getUsersMore']
);
export const selectGetUsersMoreErrorMessage = createSelector(
  selectUserErrorMessages,
  (errorMessage) => errorMessage['getUsersMore']
);

export const selectCreateUserIsLoading = createSelector(selectUserIsLoading, (isLoading) => isLoading['createUser']);
export const selectCreateUserErrorMessage = createSelector(
  selectUserErrorMessages,
  (errorMessage) => errorMessage['createUser']
);

// Admin selectors
export const selectGetAdminDetailsIsLoading = createSelector(
  selectAdminIsLoading,
  (isLoading) => isLoading['getAdminDetails']
);
export const selectGetAdminDetailsErrorMessage = createSelector(
  selectAdminErrorMessages,
  (errorMessage) => errorMessage['getAdminDetails']
);

export const selectGetAdminsIsLoading = createSelector(selectAdminIsLoading, (isLoading) => isLoading['getAdmins']);
export const selectGetAdminsErrorMessage = createSelector(
  selectAdminErrorMessages,
  (errorMessage) => errorMessage['getAdmins']
);

export const selectGetAdminsMoreIsLoading = createSelector(
  selectAdminIsLoading,
  (isLoading) => isLoading['getAdminsMore']
);
export const selectGetAdminsMoreErrorMessage = createSelector(
  selectAdminErrorMessages,
  (errorMessage) => errorMessage['getAdminsMore']
);

export const selectCreateAdminIsLoading = createSelector(selectAdminIsLoading, (isLoading) => isLoading['createAdmin']);
export const selectCreateAdminErrorMessage = createSelector(
  selectAdminErrorMessages,
  (errorMessage) => errorMessage['createAdmin']
);

export const selectEditUserDetailsIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['editUserDetails']
);
export const selectEditUserDetailsErrorMessage = createSelector(
  selectUserErrorMessages,
  (errorMessage) => errorMessage['editUserDetails']
);

export const selectDeleteUserIsLoading = createSelector(selectUserIsLoading, (isLoading) => isLoading['deleteUser']);
export const selectDeleteUserErrorMessage = createSelector(
  selectUserErrorMessages,
  (errorMessage) => errorMessage['deleteUser']
);

export const selectChangeUserStatusIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['changeUserStatus']
);
export const selectChangeUserStatusErrorMessage = createSelector(
  selectUserErrorMessages,
  (errorMessage) => errorMessage['changeUserStatus']
);
