import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormUtilityService } from 'app/modules/shared/services/form-utility.service';
import { updateShipmentStart } from 'app/ngrx/actions';
import { Setting } from 'app/ngrx/interfaces';
import { selectSettings, selectUpdateShipmentIsLoading } from 'app/ngrx/selectors';
import { numericValueOnly } from 'assets/utility/form.validators';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'insure-shipment-dialog-content',
  templateUrl: './insure-shipment-dialog-content.component.html',
  styleUrls: ['./insure-shipment-dialog-content.component.scss'],
})
export class InsureShipmentDialogContentComponent implements OnDestroy {
  insuranceFeePercentage: number;
  form: FormGroup;
  insureShipmentIsLoading$: Observable<boolean>;
  subs$: Subscription = new Subscription();

  constructor(
    public formUtility: FormUtilityService,
    private store: Store,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { id: number; isBox: boolean }
  ) {
    this.insureShipmentIsLoading$ = this.store.select(selectUpdateShipmentIsLoading);

    this.form = new FormGroup({
      shipment_price: new FormControl('', [Validators.required, numericValueOnly]),
      customer_description: new FormControl('', [Validators.required]),
    });

    this.subs$.add(
      store.select(selectSettings).subscribe((settings) => {
        if (settings) {
          this.insuranceFeePercentage = settings.find((setting) => setting.key === 'insurance_fee_percent').value;
        }
      })
    );
  }

  onXorCancelClick(): void {
    this.dialog.closeAll();
  }

  onSubmit(): void {
    let payload = { ...this.form.value };
    payload.shipment_price = Number(payload.shipment_price);
    this.store.dispatch(updateShipmentStart({ payload, id: this.data.id, isBox: !!this.data.isBox }));
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
