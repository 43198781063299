import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bey-button',
  templateUrl: './bey-button.component.html',
  styleUrls: ['./bey-button.component.scss'],
})
export class BeyButtonComponent implements OnInit {
  /***
   *  The content of textual the button
   */
  @Input()
  placeholder: string;

  /***
   *  If the button has type of submit for forms or not
   */
  @Input()
  submit: boolean;

  /***
   *  Button color
   */
  @Input()
  color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' = 'primary';

  /***
   *  Disable the button from use
   */
  @Input()
  disabled: boolean = false;

  /***
   *  Button content will be replaced with spinner, and it gets disabled when an action is initiated, and it's waiting for response
   */
  @Input()
  isLoading?: boolean;

  /****
   * Additional CSS classes can be overloaded
   */
  @Input()
  classList?: string = '';

  /****
   * the name of the icon to be displayed before the content
   */
  @Input()
  icon: string;

  /******
   * Button shadow
   */
  @Input()
  shadow: boolean = false;

  /******
   * determines if the button is outlined or not
   */
  @Input()
  outlined: boolean = false;

  @Input()
  rippleEffect: boolean = false;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  public get getClasses(): string {
    return `btn btn${this.outlined ? `-outline-${this.color}` : `-${this.color}`} ${this.classList}`;
  }
}
