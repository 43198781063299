import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ExpenseService } from './expenses.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getExpensesStart,
  getExpensesSuccess,
  getExpensesFailure,
  getMoreExpensesStart,
  getMoreExpensesFailure,
  getMoreExpensesSuccess,
  createExpenseStart,
  createExpenseSuccess,
  createExpenseFailure,
  deleteExpenseStart,
  deleteExpenseSuccess,
  deleteExpenseFailure,
  updateExpenseStart,
  updateExpenseSuccess,
  updateExpenseFailure,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class ExpenseEffects {
  constructor(
    private expenseService: ExpenseService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  getExpensesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExpensesStart),
      switchMap(() =>
        this.expenseService.getExpenses().pipe(
          mergeMap((data) => [getExpensesSuccess({ payload: data })]),
          catchError((error) => observableOf(getExpensesFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreExpensesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreExpensesStart),
      switchMap(({ payload: { page } }) =>
        this.expenseService.getMoreExpenses(page).pipe(
          mergeMap((data) => [getMoreExpensesSuccess({ payload: data })]),
          catchError((error) => observableOf(getMoreExpensesFailure({ error: error?.message })))
        )
      )
    )
  );

  createExpenseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createExpenseStart),
      switchMap(({ payload }) =>
        this.expenseService.createExpense(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createExpenseSuccess(), getExpensesStart()];
          }),
          catchError((error) => observableOf(createExpenseFailure({ error: error?.message })))
        )
      )
    )
  );

  updateExpenseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateExpenseStart),
      switchMap(({ payload, id }) =>
        this.expenseService.updateExpense(payload, id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [updateExpenseSuccess(), getExpensesStart()];
          }),
          catchError((error) => observableOf(updateExpenseFailure({ error: error?.message })))
        )
      )
    )
  );

  deleteExpenseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteExpenseStart),
      switchMap(({ payload: { id } }) =>
        this.expenseService.deleteExpense(id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [deleteExpenseSuccess(), getExpensesStart()];
          }),
          catchError((error) => observableOf(deleteExpenseFailure({ error: error?.message })))
        )
      )
    )
  );
}
