import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BeySelectMenuOption } from '../../interfaces';

@Component({
  selector: 'bey-sort',
  templateUrl: './bey-sort.component.html',
  styleUrls: ['./bey-sort.component.scss'],
})
export class BeySortComponent implements OnInit {
  /****
   * the options that will be displayed inside the menu
   */
  @Input()
  options: Array<BeySelectMenuOption> = [];

  /****
   * placeholder inside the component (set to 'Sort by:' by default)
   */
  @Input()
  placeholder: string;

  @Output()
  onChange: EventEmitter<BeySelectMenuOption> = new EventEmitter();

  opened: Boolean = false;
  selectedOption: BeySelectMenuOption;

  constructor() {}

  ngOnInit(): void {
    this.selectedOption = this.options?.find((option) => !!option.initial);
  }

  onSelect(option: BeySelectMenuOption) {
    this.selectedOption = option;
    this.onChange.emit(this.selectedOption.value);

    this.opened = false;
  }

  onClick(): void {
    this.opened = !this.opened;
  }

  closeMenu(): void {
    this.opened = false;
  }
}
