import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ShipmentService } from './shipments.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getShipmentsStart,
  getShipmentsSuccess,
  getShipmentsFailure,
  getMoreShipmentsStart,
  getMoreShipmentsFailure,
  getMoreShipmentsSuccess,
  createShipmentStart,
  createShipmentSuccess,
  createShipmentFailure,
  deleteShipmentStart,
  deleteShipmentSuccess,
  deleteShipmentFailure,
  updateShipmentStart,
  updateShipmentSuccess,
  updateShipmentFailure,
  getShipmentDetailsStart,
  getShipmentDetailsSuccess,
  getShipmentDetailsFailure,
  getShipmentCountriesStart,
  getShipmentCountriesSuccess,
  getShipmentCountriesFailure,
  addShipmentPhotoStart,
  addShipmentPhotoSuccess,
  addShipmentPhotoFailure,
  removeShipmentPhotoStart,
  removeShipmentPhotoSuccess,
  removeShipmentPhotoFailure,
  updateShipmentCountriesStart,
  updateShipmentCountriesSuccess,
  updateShipmentCountriesFailure,
  getBoxDetailsStart,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class ShipmentEffects {
  constructor(
    private shipmentService: ShipmentService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  addShipmentPhotoEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addShipmentPhotoStart),
      switchMap(({ payload: { image, shipment_id } }) =>
        this.shipmentService.addShipmentPhoto(shipment_id, image).pipe(
          mergeMap(() => [addShipmentPhotoSuccess(), getShipmentDetailsStart({ payload: { id: shipment_id } })]),
          catchError((error) => observableOf(addShipmentPhotoFailure({ error: error?.message })))
        )
      )
    )
  );

  removeShipmentPhotoEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeShipmentPhotoStart),
      switchMap(({ payload: { image_id, shipment_id } }) =>
        this.shipmentService.removeShipmentPhoto(shipment_id, image_id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [removeShipmentPhotoSuccess(), getShipmentDetailsStart({ payload: { id: shipment_id } })];
          }),
          catchError((error) => observableOf(removeShipmentPhotoFailure({ error: error?.message })))
        )
      )
    )
  );

  getShipmentCountriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getShipmentCountriesStart),
      switchMap(() =>
        this.shipmentService.getShipmentCountries().pipe(
          mergeMap((data) => [getShipmentCountriesSuccess({ payload: data })]),
          catchError((error) => observableOf(getShipmentCountriesFailure({ error: error?.message })))
        )
      )
    )
  );

  updateShipmentCountriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateShipmentCountriesStart),
      switchMap(({ payload: { body, callBack, shipment_country_id } }) =>
        this.shipmentService.updateShipmentCountries(body, shipment_country_id).pipe(
          mergeMap((data) => {
            callBack();
            return [updateShipmentCountriesSuccess(), getShipmentCountriesStart()];
          }),
          catchError((error) => observableOf(updateShipmentCountriesFailure({ error: error?.message })))
        )
      )
    )
  );

  getShipmentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getShipmentsStart),
      switchMap(({ payload }) =>
        this.shipmentService.getShipments(payload).pipe(
          mergeMap((data) => [getShipmentsSuccess({ payload: data })]),
          catchError((error) => observableOf(getShipmentsFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreShipmentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreShipmentsStart),
      switchMap(({ payload }) =>
        this.shipmentService.getMoreShipments(payload).pipe(
          mergeMap((data) => [getMoreShipmentsSuccess({ payload: data, append: !!payload['append'] })]),
          catchError((error) => observableOf(getMoreShipmentsFailure({ error: error?.message })))
        )
      )
    )
  );

  createShipmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createShipmentStart),
      switchMap(({ payload }) =>
        this.shipmentService.createShipment(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createShipmentSuccess(), getShipmentsStart({})];
          }),
          catchError((error) => observableOf(createShipmentFailure({ error: error?.message })))
        )
      )
    )
  );

  updateShipmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateShipmentStart),
      switchMap(({ payload, id, isBox }) =>
        this.shipmentService.updateShipment(payload, id, isBox).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [
              isBox ? getBoxDetailsStart({ payload: { id } }) : getShipmentDetailsStart({ payload: { id } }),
              updateShipmentSuccess(),
            ];
          }),
          catchError((error) => observableOf(updateShipmentFailure({ error: error?.message })))
        )
      )
    )
  );

  deleteShipmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteShipmentStart),
      switchMap(({ payload: { id } }) =>
        this.shipmentService.deleteShipment(id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [deleteShipmentSuccess(), getShipmentsStart({})];
          }),
          catchError((error) => observableOf(deleteShipmentFailure({ error: error?.message })))
        )
      )
    )
  );

  getShipmentDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getShipmentDetailsStart),
      switchMap(({ payload: { id } }) =>
        this.shipmentService.getShipmentDetails(id).pipe(
          mergeMap(({ data }) => [getShipmentDetailsSuccess({ payload: data })]),
          catchError((error) => observableOf(getShipmentDetailsFailure({ error: error?.message })))
        )
      )
    )
  );
}
