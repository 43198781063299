import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/helpers';
import { ErrorComponent } from './modules/auth/pages/error/error.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'prices',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'calculator',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/calculator/calculator.module').then((m) => m.CalculatorModule),
  },
  {
    path: 'shipments',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/shipments/shipments.module').then((m) => m.ShipmentsModule),
  },
  {
    path: 'wallets',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/wallets/wallets.module').then((m) => m.WalletsModule),
  },
  {
    path: 'purchases',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/purchases/purchases.module').then((m) => m.PurchasesModule),
  },
  {
    path: 'addresses',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/addresses/addresses.module').then((m) => m.AddressesModule),
  },
  {
    path: 'deposits',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/deposits/deposits.module').then((m) => m.DepositsModule),
  },
  {
    path: 'withdrawals',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/withdrawals/withdrawals.module').then((m) => m.WithdrawalsModule),
  },
  {
    path: 'about',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'boxes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/boxes/boxes.module').then((m) => m.BoxesModule),
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
