import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoScrollInput]',
})
export class NoScrollInputDirective {
  @HostListener('wheel', ['$event'])
  onWheel(event: Event) {
    if (this._eref.nativeElement.type === 'number') {
      event.preventDefault();
    }
  }

  constructor(private _eref: ElementRef) {}
}
