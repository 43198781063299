import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InsureShipmentDialogContentComponent } from './insure-shipment-dialog-content/insure-shipment-dialog-content.component';

@Component({
  selector: 'insure-shipment-button',
  templateUrl: './insure-shipment-button.component.html',
})
export class InsureShipmentButtonComponent implements OnInit {
  @Input() shipmentId: number;
  @Input() isBox: boolean;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  onClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.dialog.open(InsureShipmentDialogContentComponent, {
      data: { id: this.shipmentId, isBox: this.isBox },
      width: '80vw',
      maxWidth: '350px',
    });
  }
}
