import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import {
  GetShipmentsResponseType,
  CreateShipmentRequestBody,
  UpdateShipmentRequestBody,
  Shipment,
  GetShipmentCountriesResponseType,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  constructor(private http: HttpClient) {}

  getShipmentDetails(id: number) {
    return this.http.get<{ data: Shipment }>(environment.API_URL + 'api/v1/shipments/' + id);
  }

  getShipmentCountries() {
    return this.http.get<GetShipmentCountriesResponseType>(environment.API_URL + 'api/v1/shipment-countries');
  }

  updateShipmentCountries(body, shipment_country_id: number) {
    return this.http.put(environment.API_URL + 'api/v1/shipment-countries/' + shipment_country_id, body);
  }

  getShipments(paramsValues: object) {
    let params = new HttpParams();

    if (paramsValues) {
      Object.keys(paramsValues).forEach((key) => {
        const val = paramsValues[key];
        val && (params = params.append(key, val));
      });
    }

    return this.http.get<GetShipmentsResponseType>(environment.API_URL + 'api/v1/shipments', { params });
  }

  getMoreShipments(paramsValues: object) {
    let params = new HttpParams();

    if (paramsValues) {
      Object.keys(paramsValues).forEach((key) => {
        const val = paramsValues[key];
        val && (params = params.append(key, val));
      });
    }

    return this.http.get<GetShipmentsResponseType>(environment.API_URL + 'api/v1/shipments', { params });
  }

  createShipment(payload: CreateShipmentRequestBody) {
    return this.http.post<any>(environment.API_URL + 'api/v1/shipments', payload);
  }

  updateShipment(payload: UpdateShipmentRequestBody, id: number, isBox?: boolean) {
    return this.http.put<any>(
      environment.API_URL + `api/v1/${isBox ? 'shipment-collections' : 'shipments'}/${id}`,
      payload
    );
  }

  deleteShipment(id: number) {
    return this.http.delete<any>(environment.API_URL + `api/v1/shipments/${id}`);
  }

  addShipmentPhoto(shipment_id: number, image: File) {
    let payload = new FormData();
    payload.append('images[]', image);
    return this.http.post<any>(environment.API_URL + `api/v1/shipments/${shipment_id}/images`, payload);
  }

  removeShipmentPhoto(shipment_id: number, image_id: number) {
    return this.http.post<any>(environment.API_URL + `api/v1/shipments/${shipment_id}/images/${image_id}`, null);
  }
}
