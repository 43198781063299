import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'bey-pagination',
  templateUrl: './bey-pagination.component.html',
  styleUrls: ['./bey-pagination.component.scss'],
})
export class BeyPaginationComponent implements OnInit, OnDestroy {
  subs$: Subscription = new Subscription();
  /****
   * the number of page that the table is currently on
   */
  @Input()
  currentPage: number;

  /****
   * the total number of items to be displayed in the table, we use it to count the number of pages
   */
  @Input()
  totalItems: number;

  /****
   * the total number of items we display each page
   */
  @Input()
  limit: number = 15;

  /****
   * Observable for boolean value indicates if we're getting the content of the table, we use it to disable the pagination component
   */
  @Input()
  contentIsLoading: Observable<boolean>;

  /****
   * The ID of the table where we use this pagination component in so we can use for scrolling
   */
  @Input()
  tableID: string;

  /***
   *  Event output on page change
   */
  @Output()
  pageChanged: EventEmitter<number> = new EventEmitter();

  timeOut: NodeJS.Timeout;

  constructor() {}

  ngOnInit(): void {}

  onPageChange(currentPageNumber: number): void {
    this.pageChanged.emit(currentPageNumber);
    this.timeOut = setTimeout(() => document.getElementById(this.tableID).scrollIntoView(), 0.1);
  }

  get paginationMeta() {
    return {
      start: (this.currentPage - 1) * this.limit + 1,
      end: Math.min(this.currentPage * this.limit, this.totalItems),
    };
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
    clearTimeout(this.timeOut);
  }
}
