import { createAction, props } from '@ngrx/store';
import * as authInterfaces from './auth.interfaces';
import { authTypes } from './auth.types';

//------------GENERATE RESET PASSWORD TOKEN------------
export const generateResetPasswordTokenStart = createAction(
  authTypes.GENERATE_RESET_PASSWORD_TOKEN_START,
  props<{ payload: { email: string }; callback: Function }>()
);
export const generateResetPasswordTokenSuccess = createAction(authTypes.GENERATE_RESET_PASSWORD_TOKEN_SUCCESS);
export const generateResetPasswordTokenFailure = createAction(
  authTypes.GENERATE_RESET_PASSWORD_TOKEN_FAILURE,
  props<authInterfaces.ErrorType>()
);

//------------RESET PASSWORD------------
export const resetPasswordStart = createAction(
  authTypes.RESET_PASSWORD_START,
  props<{
    payload: { old_password?: string; new_password: string; email?: string; token?: string };
    callBack?: Function;
  }>()
);
export const resetPasswordSuccess = createAction(authTypes.RESET_PASSWORD_SUCCESS);
export const resetPasswordFailure = createAction(authTypes.RESET_PASSWORD_FAILURE, props<authInterfaces.ErrorType>());

//------------GET SETTINGS------------
export const getSettingsStart = createAction(authTypes.GET_SETTINGS_START);
export const getSettingsSuccess = createAction(
  authTypes.GET_SETTINGS_SUCCESS,
  props<{ payload: authInterfaces.GetSettingsResponseBody }>()
);
export const getSettingsFailure = createAction(authTypes.GET_SETTINGS_FAILURE, props<authInterfaces.ErrorType>());

//------------UPDATE SETTINGS------------
export const updateSettingsStart = createAction(
  authTypes.UPDATE_SETTINGS_START,
  props<{ payload: authInterfaces.UpdateSettingsRequestBody }>()
);
export const updateSettingsSuccess = createAction(authTypes.UPDATE_SETTINGS_SUCCESS);
export const updateSettingsFailure = createAction(authTypes.UPDATE_SETTINGS_FAILURE, props<authInterfaces.ErrorType>());

//------------LOGIN------------
export const loginStart = createAction(authTypes.LOGIN_START, props<{ payload: authInterfaces.LoginRequestBody }>());
export const loginSuccess = createAction(
  authTypes.LOGIN_SUCCESS,
  props<{ payload: authInterfaces.LoginResponseBody }>()
);
export const loginFailure = createAction(authTypes.LOGIN_FAILURE, props<authInterfaces.ErrorType>());
//------------OTHERS------------
export const empty = createAction(authTypes.EMPTY);

export const logOut = createAction(authTypes.LOG_OUT);

export const updateToken = createAction(authTypes.UPDATE_TOKEN, props<{ payload: string }>());
