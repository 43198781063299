export const errorMessages = {
  required: 'هذه الخانة مطلوبة',
  email: 'صيغة الإيميل خاطئة',
  minlength: 'لا يتماشى مع الطول المطلوب ({actualLength}/{requiredLength})',
  maxlength: 'وصلت الحد الأقصى من الحروف',
  leadingZero: 'يرجى حذف الصفر من أول الرقم',
  phoneNumberError: 'رقم الهاتف غير صحيح',
  mustMatch: 'الرمز غير مطابق',
  numericValueOnly: 'يرجي إدخال رقم صحيح',
};
