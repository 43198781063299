import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { BoxesState } from './boxes.interfaces';

const selectBox = (state: AppState) => {
  return state.boxes;
};

//---------1ST LEVEL SELECTORS---------
export const selectBoxDetails = createSelector(selectBox, (state: BoxesState) => state.boxDetails);
export const selectBoxes = createSelector(selectBox, (state: BoxesState) => state.boxes);
export const selectBoxesObject = createSelector(selectBox, (state: BoxesState) => {
  return {
    results: state.boxes,
    currentPage: state['boxesMeta']?.['current_page'] | 1,
    total: state['boxesMeta']?.['total'] | 1,
  };
});
export const selectBoxShipments = createSelector(selectBox, (state: BoxesState) => state.boxShipments);
export const selectBoxShipmentsObject = createSelector(selectBox, (state: BoxesState) => {
  return {
    results: state.boxShipments,
    currentPage: state['boxShipmentsMeta']?.['current_page'] | 1,
    total: state['boxShipmentsMeta']?.['total'] | 1,
  };
});

export const selectBoxesMeta = createSelector(selectBox, (state: BoxesState) => state.boxesMeta);

export const selectBoxIsLoading = createSelector(selectBox, (state: BoxesState) => state.isLoading);

export const selectBoxErrorMessages = createSelector(selectBox, (state: BoxesState) => state.errorMessages);

//---------2ND LEVEL SELECTORS---------
export const selectGetMoreBoxesNextUrlAndCurrentPage = createSelector(selectBoxesMeta, (meta) => {
  return { url: meta?.['links']?.find((link) => link.label.includes('Next'))?.url, currentPage: meta?.current_page };
});

export const selectGetBoxesIsLoading = createSelector(selectBoxIsLoading, (isLoading) => isLoading['getBoxes']);
export const selectGetBoxesErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['getBoxes']
);

export const selectGetMoreBoxesIsLoading = createSelector(selectBoxIsLoading, (isLoading) => isLoading['getMoreBoxes']);
export const selectGetMoreBoxesErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['getMoreBoxes']
);

export const selectCreateBoxIsLoading = createSelector(selectBoxIsLoading, (isLoading) => isLoading['createBox']);
export const selectCreateBoxErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['createBox']
);

export const selectUpdateBoxIsLoading = createSelector(selectBoxIsLoading, (isLoading) => isLoading['updateBox']);
export const selectUpdateBoxErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['updateBox']
);

export const selectDeleteBoxIsLoading = createSelector(selectBoxIsLoading, (isLoading) => isLoading['deleteBox']);
export const selectDeleteBoxErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['deleteBox']
);

export const selectGetBoxDetailsIsLoading = createSelector(
  selectBoxIsLoading,
  (isLoading) => isLoading['getBoxDetails']
);
export const selectGetBoxDetailsErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['getBoxDetails']
);

export const selectGetBoxShipmentsIsLoading = createSelector(
  selectBoxIsLoading,
  (isLoading) => isLoading['getBoxShipments']
);
export const selectGetBoxShipmentsErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['getBoxShipments']
);

export const selectGetMoreBoxShipmentsIsLoading = createSelector(
  selectBoxIsLoading,
  (isLoading) => isLoading['getMoreBoxShipments']
);
export const selectGetMoreBoxShipmentsErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['getMoreBoxShipments']
);

export const selectAddShipmentToBoxIsLoading = createSelector(
  selectBoxIsLoading,
  (isLoading) => isLoading['addShipmentToBox']
);
export const selectAddShipmentToBoxErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['addShipmentToBox']
);

export const selectRemoveShipmentFromBoxIsLoading = createSelector(
  selectBoxIsLoading,
  (isLoading) => isLoading['removeShipmentFromBox']
);
export const selectRemoveShipmentFromBoxErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['removeShipmentFromBox']
);

export const selectUpdateBoxStatusIsLoading = createSelector(
  selectBoxIsLoading,
  (isLoading) => isLoading['updateBoxStatus']
);
export const selectUpdateBoxStatusErrorMessage = createSelector(
  selectBoxErrorMessages,
  (errorMessage) => errorMessage['updateBoxStatus']
);
