import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { GetExpensesResponseType } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ExpenseService {
  constructor(private http: HttpClient) {}

  getExpenses() {
    return this.http.get<GetExpensesResponseType>(environment.API_URL + 'api/v1/expenses');
  }

  getMoreExpenses(page: number) {
    return this.http.get<GetExpensesResponseType>(environment.API_URL + `api/v1/expenses?page=${page}`);
  }

  createExpense(payload: any) {
    return this.http.post<any>(environment.API_URL + 'api/v1/expenses', payload);
  }

  updateExpense(payload: any, id: number) {
    return this.http.put<any>(environment.API_URL + 'api/v1/expenses/' + id, payload);
  }

  deleteExpense(id: number) {
    return this.http.delete<any>(environment.API_URL + `api/v1/expenses/${id}`);
  }
}
