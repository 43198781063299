import { createAction, props } from '@ngrx/store';
import { UpdateDepositRequestBody, ErrorType, GetDepositsResponseType, CreateDepositRequestBody } from '../interfaces';
import { depositsTypes } from './deposits.types';

//------------CREATE DEPOSIT ------------
export const createDepositStart = createAction(
  depositsTypes.CREATE_DEPOSIT_START,
  props<{ payload: CreateDepositRequestBody }>()
);
export const createDepositSuccess = createAction(depositsTypes.CREATE_DEPOSIT_SUCCESS);
export const createDepositFailure = createAction(depositsTypes.CREATE_DEPOSIT_FAILURE, props<ErrorType>());

//------------UPDATE DEPOSIT ------------
export const updateDepositStart = createAction(
  depositsTypes.UPDATE_DEPOSIT_START,
  props<{ payload: UpdateDepositRequestBody; id: number }>()
);
export const updateDepositSuccess = createAction(depositsTypes.UPDATE_DEPOSIT_SUCCESS);
export const updateDepositFailure = createAction(depositsTypes.UPDATE_DEPOSIT_FAILURE, props<ErrorType>());

//------------DELETE DEPOSIT------------
export const deleteDepositStart = createAction(
  depositsTypes.DELETE_DEPOSIT_START,
  props<{ payload: { id: number } }>()
);
export const deleteDepositSuccess = createAction(depositsTypes.DELETE_DEPOSIT_SUCCESS);
export const deleteDepositFailure = createAction(depositsTypes.DELETE_DEPOSIT_FAILURE, props<ErrorType>());

//------------GET DEPOSITS------------
export const getDepositsStart = createAction(depositsTypes.GET_DEPOSITS_START);
export const getDepositsSuccess = createAction(
  depositsTypes.GET_DEPOSITS_SUCCESS,
  props<{ payload: GetDepositsResponseType }>()
);
export const getDepositsFailure = createAction(depositsTypes.GET_DEPOSITS_FAILURE, props<ErrorType>());

//------------GET MORE DEPOSITS------------
export const getMoreDepositsStart = createAction(
  depositsTypes.GET_MORE_DEPOSITS_START,
  props<{ payload: { page: number } }>()
);
export const getMoreDepositsSuccess = createAction(
  depositsTypes.GET_MORE_DEPOSITS_SUCCESS,
  props<{ payload: GetDepositsResponseType }>()
);
export const getMoreDepositsFailure = createAction(depositsTypes.GET_MORE_DEPOSITS_FAILURE, props<ErrorType>());
