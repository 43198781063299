import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { TransferService } from './transfers.service'; // Import the appropriate transfer service
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getTransfersStart,
  getTransfersSuccess,
  getTransfersFailure,
  getMoreTransfersStart,
  getMoreTransfersFailure,
  getMoreTransfersSuccess,
  createTransferStart,
  createTransferSuccess,
  createTransferFailure,
  deleteTransferStart,
  deleteTransferSuccess,
  deleteTransferFailure,
  updateTransferStart,
  updateTransferSuccess,
  updateTransferFailure,
} from '../actions'; // Adjust the action imports based on your transfer actions
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class TransferEffects {
  constructor(
    private transferService: TransferService, // Inject the appropriate transfer service
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  getTransfersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTransfersStart),
      switchMap(() =>
        this.transferService.getTransfers().pipe(
          mergeMap((data) => [getTransfersSuccess({ payload: data })]),
          catchError((error) => observableOf(getTransfersFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreTransfersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreTransfersStart),
      switchMap(({ payload: { page } }) =>
        this.transferService.getMoreTransfers(page).pipe(
          mergeMap((data) => [getMoreTransfersSuccess({ payload: data })]),
          catchError((error) => observableOf(getMoreTransfersFailure({ error: error?.message })))
        )
      )
    )
  );

  createTransferEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTransferStart),
      switchMap(({ payload }) =>
        this.transferService.createTransfer(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createTransferSuccess(), getTransfersStart()];
          }),
          catchError((error) => observableOf(createTransferFailure({ error: error?.message })))
        )
      )
    )
  );

  updateTransferEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTransferStart),
      switchMap(({ payload, id }) =>
        this.transferService.updateTransfer(payload, id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [updateTransferSuccess(), getTransfersStart()];
          }),
          catchError((error) => observableOf(updateTransferFailure({ error: error?.message })))
        )
      )
    )
  );

  deleteTransferEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTransferStart),
      switchMap(({ payload: { id } }) =>
        this.transferService.deleteTransfer(id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [deleteTransferSuccess(), getTransfersStart()];
          }),
          catchError((error) => observableOf(deleteTransferFailure({ error: error?.message })))
        )
      )
    )
  );
}
