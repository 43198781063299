import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as authActions from './auth.actions';
import { Router } from '@angular/router';
import { getUserDetailsStart } from '../actions';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class AuthEffects {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService
  ) {}

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginStart),
      switchMap(({ payload }) =>
        this.authService.loginUser(payload).pipe(
          map((payload) => {
            return authActions.loginSuccess({
              payload,
            });
          }),
          catchError((error) => {
            return observableOf(authActions.loginFailure({ error: error?.message }));
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginSuccess),
        tap(
          ({
            payload: {
              data: { name },
            },
          }) => {
            this.router.navigate(['/home']);
            this.toast.open(`مرحبا بك ${name}`);
          }
        )
      ),
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginFailure),
        tap(() => {
          this.toast.open('البريد الإلكتروني أو الرمز السري خاطئ، يرجى إعادة المحاولة', 'error');
        })
      ),
    { dispatch: false }
  );

  generateResetPasswordTokenEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.generateResetPasswordTokenStart),
      switchMap(({ payload: { email }, callback }) =>
        this.authService.generateResetPasswordToken(email).pipe(
          mergeMap(() => {
            callback();
            return [authActions.generateResetPasswordTokenSuccess()];
          }),
          catchError((error) => {
            this.toast.open('البريد الإلكتروني غير صحيح، يرجى التأكد وإعادة المحاولة', 'error');
            return observableOf(authActions.generateResetPasswordTokenFailure({ error: error?.message }));
          })
        )
      )
    )
  );

  resetPasswordEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPasswordStart),
      switchMap(({ payload, callBack }) => {
        if (callBack) {
          return this.authService.resetPassword(payload).pipe(
            mergeMap(() => {
              callBack();
              return [authActions.resetPasswordSuccess(), getUserDetailsStart()];
            }),
            catchError((error) => observableOf(authActions.resetPasswordFailure({ error: error?.message })))
          );
        } else {
          return this.authService.resetForgotPassword(payload).pipe(
            mergeMap(() => {
              this.router.navigate(['/']);
              this.toast.open('تم تغيير الرمز السري بنجاح');
              return [authActions.resetPasswordSuccess()];
            }),
            catchError((error) => observableOf(authActions.resetPasswordFailure({ error: error?.message })))
          );
        }
      })
    )
  );

  getSettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getSettingsStart),
      switchMap(() =>
        this.authService.getSettings().pipe(
          mergeMap(({ data }) => [authActions.getSettingsSuccess({ payload: { data } })]),
          catchError((error) => observableOf(authActions.getSettingsFailure({ error: error?.message })))
        )
      )
    )
  );

  updateSettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateSettingsStart),
      switchMap(({ payload: { settings, callBack } }) =>
        this.authService.updateSettings(settings).pipe(
          mergeMap(() => {
            callBack();
            return [authActions.updateSettingsSuccess(), authActions.getSettingsStart()];
          }),
          catchError((error) => observableOf(authActions.updateSettingsFailure({ error: error?.message })))
        )
      )
    )
  );
}
