import { createReducer, on } from '@ngrx/store';
import { BoxesState } from './boxes.interfaces';
import {
  getBoxesStart,
  getBoxesSuccess,
  getBoxesFailure,
  getMoreBoxesStart,
  getMoreBoxesSuccess,
  getMoreBoxesFailure,
  createBoxStart,
  createBoxSuccess,
  createBoxFailure,
  deleteBoxStart,
  deleteBoxSuccess,
  deleteBoxFailure,
  updateBoxStart,
  updateBoxSuccess,
  updateBoxFailure,
  getBoxDetailsStart,
  getBoxDetailsSuccess,
  getBoxDetailsFailure,
  getBoxShipmentsStart,
  getBoxShipmentsSuccess,
  getBoxShipmentsFailure,
  getMoreBoxShipmentsStart,
  getMoreBoxShipmentsSuccess,
  getMoreBoxShipmentsFailure,
  addShipmentToBoxStart,
  addShipmentToBoxSuccess,
  addShipmentToBoxFailure,
  updateBoxStatusStart,
  updateBoxStatusSuccess,
  updateBoxStatusFailure,
  removeShipmentFromBoxStart,
  removeShipmentFromBoxSuccess,
  removeShipmentFromBoxFailure,
} from '../actions';

const initialState: BoxesState = {
  boxDetails: null,
  boxes: null,
  boxesMeta: null,
  boxShipments: null,
  boxShipmentsMeta: null,
  isLoading: {
    getBoxes: false,
    getMoreBoxes: false,
    createBox: false,
    deleteBox: false,
    updateBox: false,
    getBoxDetails: false,
    getBoxShipments: false,
    getMoreBoxShipments: false,
    addShipmentToBox: false,
    removeShipmentFromBox: false,
    updateBoxStatus: false,
  },
  errorMessages: {
    getBoxes: '',
    getMoreBoxes: '',
    createBox: '',
    deleteBox: '',
    updateBox: '',
    getBoxDetails: '',
    getBoxShipments: '',
    getMoreBoxShipments: '',
    addShipmentToBox: '',
    removeShipmentFromBox: '',
    updateBoxStatus: '',
  },
};

export const boxReducer = createReducer(
  initialState,
  //----------START----------
  on(removeShipmentFromBoxStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      removeShipmentFromBox: true,
    },
    errorMessages: {
      ...state.errorMessages,
      removeShipmentFromBox: '',
    },
  })),
  on(addShipmentToBoxStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      addShipmentToBox: true,
    },
    errorMessages: {
      ...state.errorMessages,
      addShipmentToBox: '',
    },
  })),
  on(updateBoxStatusStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateBoxStatus: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateBoxStatus: '',
    },
  })),
  on(getBoxesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getBoxes: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxes: '',
    },
  })),
  on(getBoxDetailsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getBoxDetails: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxDetails: '',
    },
  })),
  on(getMoreBoxesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreBoxes: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreBoxes: '',
    },
  })),
  on(createBoxStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createBox: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createBox: '',
    },
  })),
  on(updateBoxStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateBox: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateBox: '',
    },
  })),
  on(deleteBoxStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteBox: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteBox: '',
    },
  })),
  on(getBoxShipmentsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getBoxShipments: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxShipments: '',
    },
  })),
  on(getMoreBoxShipmentsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreBoxShipments: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreBoxShipments: '',
    },
  })),
  //----------SUCCESS----------
  on(removeShipmentFromBoxSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      removeShipmentFromBox: false,
    },
    errorMessages: {
      ...state.errorMessages,
      removeShipmentFromBox: '',
    },
  })),
  on(addShipmentToBoxSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      addShipmentToBox: false,
    },
    errorMessages: {
      ...state.errorMessages,
      addShipmentToBox: '',
    },
  })),
  on(updateBoxStatusSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateBoxStatus: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateBoxStatus: '',
    },
  })),
  on(getBoxesSuccess, (state, { payload }) => ({
    ...state,
    boxes: payload['data'],
    boxesMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getBoxes: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxes: '',
      getMoreBoxes: '',
    },
  })),
  on(getMoreBoxesSuccess, (state, { payload }) => ({
    ...state,
    boxes: payload['data'],
    boxesMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreBoxes: false,
    },
  })),
  on(getBoxDetailsSuccess, (state, { payload }) => ({
    ...state,
    boxDetails: payload,
    isLoading: {
      ...state.isLoading,
      getBoxDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxDetails: '',
    },
  })),
  on(createBoxSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createBox: false,
    },
  })),
  on(updateBoxSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateBox: false,
    },
  })),
  on(deleteBoxSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteBox: false,
    },
  })),
  on(getBoxShipmentsSuccess, (state, { payload }) => ({
    ...state,
    boxShipments: payload['data'],
    boxShipmentsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getBoxShipments: false,
    },
  })),
  on(getMoreBoxShipmentsSuccess, (state, { payload }) => ({
    ...state,
    boxShipments: payload['data'],
    boxShipmentsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreBoxShipments: false,
    },
  })),
  //----------FAILURE----------
  on(removeShipmentFromBoxFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      removeShipmentFromBox: false,
    },
    errorMessages: {
      ...state.errorMessages,
      removeShipmentFromBox: error,
    },
  })),
  on(addShipmentToBoxFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      addShipmentToBox: false,
    },
    errorMessages: {
      ...state.errorMessages,
      addShipmentToBox: error,
    },
  })),
  on(updateBoxStatusFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateBoxStatus: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateBoxStatus: error,
    },
  })),
  on(getBoxesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getBoxes: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxes: error,
    },
  })),
  on(getMoreBoxesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreBoxes: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreBoxes: error,
    },
  })),
  on(createBoxFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createBox: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createBox: error,
    },
  })),
  on(updateBoxFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateBox: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateBox: error,
    },
  })),
  on(deleteBoxFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteBox: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteBox: error,
    },
  })),
  on(getBoxDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getBoxDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxDetails: error,
    },
  })),
  on(getBoxShipmentsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getBoxShipments: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getBoxShipments: error,
    },
  })),
  on(getMoreBoxShipmentsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreBoxShipments: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreBoxShipments: error,
    },
  }))
);
