import { Component, ContentChildren, Input, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { selectedColumn, tableCol, tableContent, tablePagination } from './bey-table.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BeyFilterConfig,
  // BeyMonthsSelectorDateDuration,
  BeySelectMenuOption,
} from '../../interfaces/';
// import { getMonthLastDay, returnBeyMonthsSelectorValues, returnNumberInTwoDigitsFormat } from 'assets/utility';
// import { MONTHS } from 'assets/utility/consts';
import qs from 'qs';
import dayjs from 'dayjs/esm';

@Component({
  selector: 'bey-table',
  templateUrl: './bey-table.component.html',
  styleUrls: ['./bey-table.component.scss'],
})
export class BeyTableComponent implements OnInit, OnDestroy {
  /****
   * unique identifier for the table component
   */
  @Input()
  id: string;

  /****
   * determines if the main content for table is being fetched or not
   */
  @Input()
  isLoading: Observable<boolean>;

  /****
   * pagination component properties
   */
  @Input()
  pagination: tablePagination;

  /****
   * the given array to be displayed in the table in Observable form
   */
  @Input()
  tableContent: Observable<tableContent>;

  /****
   * table columns properties
   */
  @Input()
  columns: Array<tableCol>;

  /****
   * table title
   */
  @Input()
  title: string;

  /****
   * search field component property
   */
  @Input()
  search: Array<{ key: string; placeholder: string; minWidth?: number; label: string }>;

  /****
   * select component property for filtering
   */
  @Input()
  filters: BeyFilterConfig;

  /****
   * sort component property for sorting
   */
  @Input()
  sort: { options: Array<BeySelectMenuOption>; placeholder: string };

  /****
   * boolean to include filtering by months functionality
   */
  // @Input()
  // filteringByMonths: boolean;

  @ContentChildren('row')
  rows: QueryList<any>;

  public destroyed: boolean = false;

  public searchFields: { [key: string]: string };
  public filteringStatus: string = '';
  public sortingOption: string = '';
  public monthsDuration: string = '';

  public currentPageSubject: BehaviorSubject<number> = new BehaviorSubject(1);
  public currentPage: number = 1;

  public selectedColumnSubject: BehaviorSubject<selectedColumn> = new BehaviorSubject({
    name: null,
    type: null,
    icon: 'code',
  });
  public selectedColumn: selectedColumn;

  private subs$ = new Subscription();

  public limit: number = 10;
  private nextOrPrevUrl: string;

  public pageInitiated: boolean = false;
  filter: Map<string, any> = new Map();

  constructor(private store$: Store, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    // no need to prefill the search field from the query right? it's redundant
    if (this.search) {
      this.search.forEach((search) => (this.searchFields = { ...this.searchFields, [search.key]: '' }));
    }

    // this.subs$.add(
    //   this.tableContent.subscribe((content) => {
    //     if (content) {
    //       this.nextOrPrevUrl = content.next ? content.next : content.previous;
    //       if (this.nextOrPrevUrl) {
    //         this.limit = parseInt(
    //           this.nextOrPrevUrl.slice(this.nextOrPrevUrl.indexOf('limit=') + 6, this.nextOrPrevUrl.indexOf('&offset'))
    //         );
    //       }
    //     }
    //   })
    // );

    // set initial date duration
    if (this.route.snapshot.queryParams[`${this.id}_months_duration`]) {
      this.monthsDuration = this.route.snapshot.queryParams[`${this.id}_months_duration`];
    }

    // set initial sorting options
    let sortingOptionFromQuery: string = this.route.snapshot.queryParams[`${this.id}_sort`];
    if (sortingOptionFromQuery) {
      this.sort.options = this.sort?.options.map((option) => {
        return { ...option, initial: sortingOptionFromQuery === option.value };
      });
    }
    let initialSortingOption = this.sort?.options.find((option) => !!option.initial)?.value;
    if (initialSortingOption) {
      this.sortingOption = initialSortingOption;
    }

    // set initial values to be the one in the query params else update the query params
    let queryParams = this.route.snapshot.queryParams?.[`${this.id}_s`];

    const queryParamsObj = {};
    const parsedParamsObj = qs.parse(queryParams)?.filter;
    let parsedParams = {};

    if (parsedParamsObj) {
      Object.keys(parsedParamsObj).forEach(
        (key) => (parsedParams = { ...parsedParams, [`filter[${key}]`]: parsedParamsObj[key] })
      );
    }

    this.filters?.options.forEach((opt) => {
      const initiallySelected = opt.values.filter((itm) => itm.initial);
      queryParamsObj[opt.name] = [...initiallySelected.map((itm) => itm.value)].join(',');

      //  add others that gathered from query params
      // if the parsed query params include one of the filter groups we need to include that
      if (parsedParams[opt.name]) {
        queryParamsObj[opt.name] += queryParamsObj[opt.name] ? `,${parsedParams[opt.name]}` : parsedParams[opt.name];
        //   mark option value as initial in order to show as selected on page refresh
        let selectedValues = (parsedParams[opt.name] as string)?.split(',');
        opt.values = opt.values.map((i) => {
          if (selectedValues.includes(i.value.toString())) {
            return {
              ...i,
              initial: true,
            };
          }
          return {
            ...i,
            initial: false,
          };
        });
      }

      this.filteringStatus = new URLSearchParams(queryParamsObj).toString();
    });

    this.subs$.add(
      this.isLoading.subscribe((booleanValue) => {
        const params = this.route.snapshot.queryParams;
        if (params) {
          // to be used to prevent table from calling API twice
          let relatedParam: boolean = !!Object.keys(params).find((param) => param.includes(this.id));
          if (!booleanValue && !this.pageInitiated && relatedParam) {
            this.pageInitiated = true;

            const validPageNum = Number(params[`${this.id}_current_page`]);

            if (params[`${this.id}_s`]) {
              this.filteringStatus = params[`${this.id}_s`];
            }

            if (params[`${this.id}_sort`]) {
              this.sortingOption = params[`${this.id}_sort`];
            }

            let searchRelatedParams = Object.keys(params).filter((key) => key.includes('_q_'));
            if (!!searchRelatedParams.length) {
              searchRelatedParams.forEach((key) => {
                key = key.split('_q_')[1];
                this.searchFields = {
                  ...this.searchFields,
                  [key]: params[`${this.id}_q_${key}`],
                };
              });
            }

            const queryObj = qs.parse(this.filteringStatus)?.filter;
            let filters = {};
            if (queryObj) {
              Object.keys(queryObj).forEach((key) => (filters = { ...filters, [`filter[${key}]`]: queryObj[key] }));
            }
            const dateDuration = this.retrieveDateDurationValuesInIsoFormat();

            this.store$.dispatch(
              this.pagination.getPageContent({
                payload: {
                  ...filters,
                  ...dateDuration,
                  page: validPageNum,
                  ordering: this.sortingOption,
                  ...this.pagination.extraParams,
                  ...this.searchFields,
                },
              })
            );
            if (validPageNum) {
              this.currentPage = validPageNum;
            }
          }
        }
      })
    );
  }

  onPaginationPageChange(currentPage): void {
    this.currentPage = currentPage;
    const queryObj = qs.parse(this.filteringStatus);
    const dateDuration = this.retrieveDateDurationValuesInIsoFormat();

    this.store$.dispatch(
      this.pagination.getPageContent({
        payload: {
          ...queryObj,
          ...dateDuration,
          page: currentPage,
          ordering: this.sortingOption,
          ...this.pagination.extraParams,
          ...this.searchFields,
        },
      })
    );

    this.updateQueryParams();
  }

  // TO BE USED IN FUTURE
  onColumnClicked(col): void {
    //   if (col.isSortable) {
    //     this.subs$.add(this.selectedColumnSubject.subscribe((col) => (this.selectedColumn = col)));
    //     if (this.selectedColumn?.name === col.property) {
    //       let type: 'ascending' | 'descending';
    //       let icon: 'chevron-up' | 'chevron-down' | 'code';
    //       switch (this.selectedColumn.type) {
    //         case 'descending':
    //           type = 'ascending';
    //           icon = 'chevron-up';
    //           break;
    //         case 'ascending':
    //           type = null;
    //           icon = 'code';
    //           break;
    //         default:
    //           type = 'descending';
    //           icon = 'chevron-down';
    //       }
    //       this.selectedColumnSubject.next({ name: col.property, type, icon });
    //       this.store$.dispatch(
    //         this.pagination.getPageContent({
    //           payload: {
    //             status: this.filteringStatus,
    //             offset: this.currentPage * this.limit - this.limit,
    //             ordering: type ? `${type === 'descending' ? '-' : ''}${col.property}` : '',
    //             ...this.pagination.extraParams,
    //             ...this.searchFields,
    //           },
    //         })
    //       );
    //     } else {
    //       this.selectedColumnSubject.next({ name: col.property, type: 'descending', icon: 'chevron-down' });
    //       this.store$.dispatch(
    //         this.pagination.getPageContent({
    //           payload: {
    //             status: this.filteringStatus,
    //             offset: this.currentPage * this.limit - this.limit,
    //             ordering: '-' + col.property,
    //             ...this.pagination.extraParams,
    //             ...this.searchFields,
    //           },
    //         })
    //       );
    //     }
    //   }
  }

  onSearchFieldEnterClick(afterClearing: boolean = false): void {
    if (afterClearing || Object.keys(this.searchFields).length) {
      const queryObj = qs.parse(this.filteringStatus);
      const dateDuration = this.retrieveDateDurationValuesInIsoFormat();

      this.store$.dispatch(
        this.pagination.getPageContent({
          payload: {
            ...queryObj,
            ...dateDuration,
            page: 1,
            ordering: this.sortingOption,
            ...this.pagination.extraParams,
            ...this.searchFields,
          },
        })
      );
      this.currentPageSubject.next(1);
      this.currentPage = 1;
      this.updateQueryParams();
    }
  }

  onSelectingFilter(values: Map<string, Set<string>>): void {
    //  add existing filters from filter status
    const queryParamsObj = {};

    Array.from(values.keys()).forEach((groupKey) => {
      if (values.get(groupKey).size) {
        queryParamsObj[groupKey] = [...values.get(groupKey).values()].join(',');
      }
    });

    this.filteringStatus = new URLSearchParams(queryParamsObj).toString();
    const dateDuration = this.retrieveDateDurationValuesInIsoFormat();

    this.store$.dispatch(
      this.pagination.getPageContent({
        payload: {
          ...queryParamsObj,
          ...dateDuration,
          page: 1,
          ordering: this.sortingOption,
          ...this.pagination.extraParams,
          ...this.searchFields,
        },
      })
    );

    this.currentPageSubject.next(1);
    this.currentPage = 1;
    this.updateQueryParams();
  }

  onSearchFieldClear(): void {
    Object.keys(this.searchFields).forEach((key) => (this.searchFields[key] = ''));
    this.onSearchFieldEnterClick(true);
    this.updateQueryParams();
  }

  onSortOptionSelect(value: string) {
    this.sortingOption = value;

    const queryObj = qs.parse(this.filteringStatus);
    const dateDuration = this.retrieveDateDurationValuesInIsoFormat();

    this.store$.dispatch(
      this.pagination.getPageContent({
        payload: {
          ...queryObj,
          ...dateDuration,
          page: 1,
          ordering: this.sortingOption,
          ...this.pagination.extraParams,
          ...this.searchFields,
        },
      })
    );
    this.currentPageSubject.next(1);
    this.currentPage = 1;
    this.updateQueryParams();
  }

  // TO BE USED IN FUTURE
  // retrieveOrderingParamValue(): string {
  //   return this.selectedColumn?.type
  //     ? `${this.selectedColumn.type === 'descending' ? '-' : ''}${this.selectedColumn?.name}`
  //     : '';
  // }

  // onMonthsDurationSelect(duration: BeyMonthsSelectorDateDuration) {
  //   let params: {};
  //   if (duration) {
  //     let { from, to } = duration;
  //     let start: string =
  //       from['year'] + '-' + returnNumberInTwoDigitsFormat(MONTHS.indexOf(from['month']) + 1) + '-' + '01';
  //     let end: string =
  //       to['year'] +
  //       '-' +
  //       returnNumberInTwoDigitsFormat(MONTHS.indexOf(to['month']) + 1) +
  //       '-' +
  //       getMonthLastDay(to['month']);
  //     this.monthsDuration = start + '_' + end;

  //     let subscription_start_after: string = dayjs(start).toISOString();
  //     let subscription_start_before: string = dayjs(end).toISOString();
  //     params = { subscription_start_after, subscription_start_before };
  //   } else {
  //     this.monthsDuration = '';
  //   }
  //   const queryObj = qs.parse(this.filteringStatus);

  //   this.store$.dispatch(
  //     this.pagination.getPageContent({
  //       payload: {
  //         ...queryObj,
  //         ...params,
  //         limit: this.limit,
  //         offset: 0,
  //         ordering: this.sortingOption,
  //         ...this.pagination.extraParams,
  //         ...this.searchFields,
  //       },
  //     })
  //   );
  //   this.currentPageSubject.next(1);
  //   this.currentPage = 1;
  //   this.updateQueryParams();
  // }

  updateQueryParams(): void {
    const params = {
      ...this.route.snapshot.queryParams,
    };

    this.currentPage && (params[`${this.id}_current_page`] = this.currentPage);

    this.filteringStatus ? (params[`${this.id}_s`] = this.filteringStatus) : delete params[`${this.id}_s`];

    this.sortingOption ? (params[`${this.id}_sort`] = this.sortingOption) : delete params[`${this.id}_sort`];

    this.monthsDuration
      ? (params[`${this.id}_months_duration`] = this.monthsDuration)
      : delete params[`${this.id}_months_duration`];

    if (this.search) {
      // todo refactor and optimize
      if (Object.keys(this.searchFields).length) {
        Object.keys(this.searchFields).forEach((key) => {
          if (this.searchFields[key]) {
            params[`${this.id}_q_${key}`] = this.searchFields[key];
          } else {
            delete params[`${this.id}_q_${key}`];
          }
        });
      }
    }

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        ...params,
      },
    });
  }

  retrieveDateDurationValuesInIsoFormat():
    | { subscription_start_after: string; subscription_start_before: string }
    | {} {
    if (this.monthsDuration) {
      let [subscription_start_after, subscription_start_before] = this.monthsDuration.split('_');
      subscription_start_after = dayjs(subscription_start_after).toISOString();
      subscription_start_before = dayjs(subscription_start_before).toISOString();
      return { subscription_start_after, subscription_start_before };
    } else return {};
  }

  // get beyMonthsSelectorInitialValues() {
  //   return returnBeyMonthsSelectorValues(this.monthsDuration);
  // }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
