import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BeySelectMenuOption } from '../../interfaces';

@Component({
  selector: 'bey-filter',
  templateUrl: './bey-filter.component.html',
  styleUrls: ['./bey-filter.component.scss'],
})
export class BeyFilterComponent implements OnInit {
  /****
   * the options that will be displayed inside the menu
   */
  @Input()
  options: Array<{ name: string; values: Array<BeySelectMenuOption> }>;

  /****
   * indicates if multiple options selection is available
   */
  @Input()
  multipleSelection: boolean = false;

  /****
   * placeholder inside the component (set to 'Filter' by default)
   */
  @Input()
  placeholder: string;

  /****
   * table id
   */
  @Input()
  id: string;
  timeOut: NodeJS.Timeout;

  @Output()
  onChange: EventEmitter<Map<string, Set<string>>> = new EventEmitter();

  @HostListener('document:click', ['$event']) clickout(event) {
    // close the menu if the user clicked outside the component
    if (!this._eref.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }

  opened: Boolean = false;
  selectedOptions: Map<string, BeySelectMenuOption> = new Map();
  filterState: Map<string, Set<any>> = new Map();

  constructor(private _eref: ElementRef) {}

  ngOnInit(): void {
    this.options.forEach((opt) => {
      opt.values.forEach((itm) => {
        if (itm.initial) {
          this.onSelect(itm, opt.name, true);
        }
      });
    });
  }

  onClick(): void {
    this.opened = !this.opened;
  }

  onSelect(option: BeySelectMenuOption, groupLabel: string, initial: boolean = false): void {
    this.timeOut = setTimeout(() => document.getElementById(this.id)?.scrollIntoView(), 0.1);
    const prev = this.filterState.get(groupLabel);

    if (prev) {
      const ex = prev.has(option.value);

      if (ex) {
        const set = new Set(prev);

        set.delete(option.value);
        this.filterState.set(groupLabel, set);
      } else {
        const set = new Set(prev);

        if (!this.multipleSelection) {
          set.clear();
        }

        set.add(option.value);

        this.filterState.set(groupLabel, set);
      }
    } else {
      const mySet = new Set();

      mySet.add(option.value);
      this.filterState.set(groupLabel, mySet);
    }

    const exist = this.selectedOptions.get(option.label);

    if (exist) {
      this.selectedOptions.delete(option.label);
    } else {
      if (!this.multipleSelection) {
        this.selectedOptions.clear();
      }

      this.selectedOptions.set(option.label, option);
    }

    if (!initial) {
      this.onChange.emit(this.filterState);
    }
  }
}
