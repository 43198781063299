import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from 'environments/environment';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { ENV_NAMES } from './consts';

export function confirmPinValidator(form: UntypedFormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value != form?.controls['new_password']?.value) {
      return { mustMatch: true };
    } else return null;
  };
}

export function numericValueOnly(control: UntypedFormControl): {
  [key: string]: boolean;
} {
  const val = control.value;
  const exp = new RegExp(/^[0-9.]*$/);

  return exp.test(val) ? null : { numericValueOnly: true };
}

export function phoneNumberValidator(code?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!!!code) {
      return { phoneNumberError: true };
    }
    if (control.value?.toString().padStart(1, '0')[0] === '0') {
      return { leadingZero: true };
    } else if (isValidPhoneNumber(`${code}${control.value}`)) {
      return null;
    } else {
      return { phoneNumberError: true };
    }
  };
}

export function phoneNumberValidatorIncludesTesting(isGroup: boolean = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    /*
      This is for field where the country code is separated from the mobile phone
      @@@ params
      code : String  e.g. :+218
      phone_number : String e.g. : 0925xxxxxxx
     */
    const expression = new RegExp(/(^800)(\d{8})$/);
    if (!isGroup) {
      let { code, phone_number } = control?.value;
      code = typeof code === 'object' ? code?.countryCode : code; // this to accommodate the values provided by the country code selector in case no binding value was provided

      if (environment.name !== ENV_NAMES.PROD && expression.test(`${code?.slice(1)}${phone_number}`)) {
        return null; // toll-free number is valid only on non production env
      } else if (phone_number?.toString().padStart(3, '0')[0] === '0') {
        return { leadingZero: true };
      } else if (isValidPhoneNumber(`${code}${phone_number}`)) {
        return null;
      } else {
        return { phoneNumberError: true };
      }
    } else {
      //      only test the toll free if the environment is DEV
      const num = String(control.value);
      if (environment.name !== ENV_NAMES.PROD && expression.test(num.slice(1))) {
        return null; // toll free number is valid only on non production env
      } else if (isValidPhoneNumber(`${num}`)) {
        return null;
      } else {
        return { phoneNumberError: true };
      }
    }
  };
}
