import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { AuthState } from './auth.interfaces';

const selectAuth = (state: AppState) => {
  return state.auth;
};

//---------1ST LEVEL SELECTORS---------
export const selectCurrentUser = createSelector(selectAuth, (state: AuthState) => state.currentUser);

export const selectToken = createSelector(selectAuth, (state: AuthState) => state.token);

export const selectSettings = createSelector(selectAuth, (state: AuthState) => state.settings);

export const selectAuthIsLoading = createSelector(selectAuth, (state: AuthState) => state.isLoading);

export const selectAuthErrorMessages = createSelector(selectAuth, (state: AuthState) => state.errorMessages);
//---------2ND LEVEL SELECTORS---------
export const selectLoginIsLoading = createSelector(selectAuthIsLoading, (isLoading) => isLoading['login']);
export const selectLoginErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['login']
);

export const selectGetSettingsIsLoading = createSelector(selectAuthIsLoading, (isLoading) => isLoading['getSettings']);
export const selectGetSettingsErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['getSettings']
);

export const selectUpdateSettingsIsLoading = createSelector(
  selectAuthIsLoading,
  (isLoading) => isLoading['updateSettings']
);
export const selectUpdateSettingsErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['updateSettings']
);

export const selectGenerateResetPasswordTokenIsLoading = createSelector(
  selectAuthIsLoading,
  (isLoading) => isLoading['generateResetPasswordToken']
);
export const selectGenerateResetPasswordTokenErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['generateResetPasswordToken']
);

export const selectResetPasswordIsLoading = createSelector(
  selectAuthIsLoading,
  (isLoading) => isLoading['resetPassword']
);
export const selectResetPasswordErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['resetPassword']
);
