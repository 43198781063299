import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AuthService } from '../../../ngrx/auth/auth.service';
import { selectToken } from '../../../ngrx/selectors';
import { logOut } from 'app/ngrx/actions';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private token$: Observable<string>;
  subs = new Subscription();

  constructor(private store$: Store, private authService: AuthService, private router: Router) {
    this.token$ = this.store$.select(selectToken);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    let token: string;
    const skipInterceptor = req.headers.get('skip');

    if (skipInterceptor) {
      req = req.clone({
        headers: req.headers.delete('skip'),
      });
      return next.handle(req);
    }

    this.subs.add(this.token$.subscribe((storeToken) => (token = storeToken)));

    if (token) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if ([403, 401].includes(error.status)) {
            if (error.status === 401) {
              this.store$.dispatch(logOut());
              location.reload();
            }
            return throwError(() => error);
          }
        }
        return throwError(() => error);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + accessToken),
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
