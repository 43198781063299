<div class="ease-in-out duration-500 text-grey-400 focus-within:text-bey-black">
  <label
    *ngIf="label"
    class="block text-grey-600 lg:leading-6 text-sm lg:text-base font-bold"
    [ngClass]="classList?.['label']"
    >{{ label }}</label
  >
  <div
    class="w-full flex border rounded focus-within:border-grey-500 transition ease-in-out duration-500 relative"
    [ngClass]="{
      'bg-gray-100': readonly,
      'border-[#C4C4C4]': !displayError,
      'border-bey-red': displayError
    }">
    <div class="fixed h-full w-full top-0 inset-0" (click)="toggleFocus(true)" *ngIf="focused"></div>
    <div class="flex w-full">
      <div class="flex w-[25%] border-after relative">
        <div class="flex w-full">
          <div class="w-32 flex justify-center items-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="!value?.country?.isoCode; else selectedCountry">
              <path
                d="M6.31162 10.2399C8.79911 10.2399 10.8156 8.22342 10.8156 5.73593C10.8156 3.24844 8.79911 1.23193 6.31162 1.23193C3.82413 1.23193 1.80762 3.24844 1.80762 5.73593C1.80762 8.22342 3.82413 10.2399 6.31162 10.2399Z"
                stroke="#80807D"
                stroke-width="1.2" />
              <path d="M9.80762 9.51221L14.0076 14.0162" stroke="#80807D" stroke-width="1.2" stroke-linecap="round" />
            </svg>

            <ng-template #selectedCountry>
              <img
                width="24"
                height="24"
                class="flag"
                [src]="'https://flagcdn.com/' + value?.country?.isoCode + '.svg'"
                [alt]="value?.country?.label"
                loading="lazy" />
            </ng-template>
          </div>

          <div class="w-auto">
            <input
              tabindex="1"
              type="text"
              name="password"
              autocomplete="off"
              class="w-full focus:outline-none text-grey-800 text-sm py-2 bg-transparent"
              [readOnly]="readonly"
              [ngClass]="readonly ? 'bg-gray-100' : ''"
              [value]="inputFieldValue"
              (input)="updateInputVal($event)"
              (focusin)="toggleFocus()"
              (keydown)="jumpToList($event)"
              #codeInput />
          </div>
        </div>
      </div>
      <div class="w-8/12">
        <input
          type="tel"
          (input)="phoneInputHandler($event)"
          [disabled]="disabled"
          (blur)="onBlur()"
          [readOnly]="readonly"
          [ngClass]="{
            'bg-gray-100': readonly,
            'bg-transparent': transparent
          }"
          min="0"
          NumberInputOnly />
      </div>
    </div>
    <!-- <div
      class="absolute top-8 left-0 bg-white w-[236px] transition-all ease-in-out duration-300 rounded-md menu"
      [ngClass]="focused && !readonly ? 'opacity-1 z-100' : '-z-1 opacity-0 '">
      <ul class="unstyled">
        <li
          class="flex px-2.5 py-1.5 rounded items-center transition ease-in-out duration-500 hover:bg-blue-100 cursor-pointer focus:outline-1 h-12"
          *cdkVirtualFor="let country of options | countryFilterPipe : inputFieldValue; let i = index"
          (click)="selectCountry(country)"
          (keydown)="handleKey($event, country)"
          #countryOption
          [attr.tabIndex]="i"
          [ngClass]="value?.value === country.value ? 'bg-[#CCE4F1]' : ''"
          [id]="uniqueID.getElementId('country-option ' + country.isoCode, 'li')">
          <img
            width="24"
            height="24"
            class="flag"
            [src]="
              country.isoCode ? 'https://flagcdn.com/' + country.isoCode + '.svg' : '/assets/images/white-flag.png'
            "
            [alt]="country.label"
            loading="lazy" />
          <span class="font text-grey-600 text-sm leading-[14px]">{{ country.label }} ({{ country.value }})</span>
        </li>

        <li
          class="flex py-1.5 pt-3 rounded items-center transition ease-in-out duration-500 hover:bg-blue-100 cursor-pointer"
          (click)="clear()">
          <img src="assets/logos/cross-modal.svg" width="12" height="12" alt="clear" class="mx-4" />
          <span class="font text-grey-600 text-sm leading-[14px]">Clear</span>
        </li>
        <li class="pb-[15px]"></li>
      </ul>
    </div> -->
  </div>
  <bey-field-error-message *ngIf="!hideValidationFeedback" [displayError]="displayError" [errorMessage]="errorMessage">
  </bey-field-error-message>
</div>
