import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import _find from 'lodash/find';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { logOut } from '../../../ngrx/actions';
import { selectCurrentUser } from '../../../ngrx/selectors';
import { CSAdmin, User } from '../../../ngrx/interfaces';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser$: Observable<CSAdmin>;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private subs$ = new Subscription();

  constructor(
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    private store$: Store,
    public _translateService: TranslateService
  ) {
    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us',
      },
      fr: {
        title: 'French',
        flag: 'fr',
      },
      de: {
        title: 'German',
        flag: 'de',
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt',
      },
    };
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  get environment() {
    return environment;
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this.subs$.add(
      this._coreConfigService.getConfig().subscribe((config) => {
        this.currentSkin = config.layout.skin;
      })
    );

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    this.store$.dispatch(logOut());
    this._router.navigate(['/login']);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // get the currentUser details from localStorage
    this.currentUser$ = this.store$.select(selectCurrentUser);

    // Subscribe to the config changes
    this.subs$.add(
      this._coreConfigService.config.subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === 'horizontal';
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === 'vertical') {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === 'fixed-top') {
              this.isFixed = true;
            }
          }, 0);
        }
      })
    );

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this.subs$.add(
        this._coreMediaService.onMediaUpdate.subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        })
      );
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _find(this.languageOptions, {
      id: this._translateService.currentLang,
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
