import { createAction, props } from '@ngrx/store';
import {
  Box,
  Shipment,
  ErrorType,
  GetBoxesResponseType,
  CreateBoxRequestBody,
  UpdateBoxRequestBody,
  GetBoxShipmentsResponseType,
  UpdateBoxStatusRequestBody,
  AddShipmentToBoxRequestBody,
  RemoveShipmentFromBoxRequestBody,
} from '../interfaces';
import { boxesTypes } from './boxes.types';

//------------GET BOX DETAILS------------
export const getBoxDetailsStart = createAction(boxesTypes.GET_BOX_DETAILS_START, props<{ payload: { id: number } }>());

export const getBoxDetailsSuccess = createAction(boxesTypes.GET_BOX_DETAILS_SUCCESS, props<{ payload: Box }>());

export const getBoxDetailsFailure = createAction(boxesTypes.GET_BOX_DETAILS_FAILURE, props<ErrorType>());

//------------CREATE BOX ------------
export const createBoxStart = createAction(boxesTypes.CREATE_BOX_START, props<{ payload: CreateBoxRequestBody }>());
export const createBoxSuccess = createAction(boxesTypes.CREATE_BOX_SUCCESS);
export const createBoxFailure = createAction(boxesTypes.CREATE_BOX_FAILURE, props<ErrorType>());

//------------EDIT BOX ------------
export const updateBoxStart = createAction(
  boxesTypes.EDIT_BOX_START,
  props<{ payload: UpdateBoxRequestBody; id: number; callBack: Function }>()
);
export const updateBoxSuccess = createAction(boxesTypes.EDIT_BOX_SUCCESS);
export const updateBoxFailure = createAction(boxesTypes.EDIT_BOX_FAILURE, props<ErrorType>());

//------------DELETE BOX------------
export const deleteBoxStart = createAction(boxesTypes.DELETE_BOX_START, props<{ payload: { id: number } }>());
export const deleteBoxSuccess = createAction(boxesTypes.DELETE_BOX_SUCCESS);
export const deleteBoxFailure = createAction(boxesTypes.DELETE_BOX_FAILURE, props<ErrorType>());

//------------GET BOXES------------
export const getBoxesStart = createAction(boxesTypes.GET_BOXES_START, props<{ payload?: object }>());
export const getBoxesSuccess = createAction(boxesTypes.GET_BOXES_SUCCESS, props<{ payload: GetBoxesResponseType }>());
export const getBoxesFailure = createAction(boxesTypes.GET_BOXES_FAILURE, props<ErrorType>());

//------------GET MORE BOXES------------
export const getMoreBoxesStart = createAction(boxesTypes.GET_MORE_BOXES_START, props<{ payload: object }>());
export const getMoreBoxesSuccess = createAction(
  boxesTypes.GET_MORE_BOXES_SUCCESS,
  props<{ payload: GetBoxesResponseType }>()
);
export const getMoreBoxesFailure = createAction(boxesTypes.GET_MORE_BOXES_FAILURE, props<ErrorType>());

//------------GET BOX SHIPMENTS------------
export const getBoxShipmentsStart = createAction(
  boxesTypes.GET_BOX_SHIPMENTS_START,
  props<{ payload: { boxId: number } }>()
);
export const getBoxShipmentsSuccess = createAction(
  boxesTypes.GET_BOX_SHIPMENTS_SUCCESS,
  props<{ payload: GetBoxShipmentsResponseType }>()
);
export const getBoxShipmentsFailure = createAction(boxesTypes.GET_BOX_SHIPMENTS_FAILURE, props<ErrorType>());

//------------GET MORE BOX SHIPMENTS------------
export const getMoreBoxShipmentsStart = createAction(
  boxesTypes.GET_MORE_BOX_SHIPMENTS_START,
  props<{ payload: { boxId: number; page: number } }>()
);
export const getMoreBoxShipmentsSuccess = createAction(
  boxesTypes.GET_MORE_BOX_SHIPMENTS_SUCCESS,
  props<{ payload: GetBoxShipmentsResponseType }>()
);
export const getMoreBoxShipmentsFailure = createAction(boxesTypes.GET_MORE_BOX_SHIPMENTS_FAILURE, props<ErrorType>());

//------------ADD SHIPMENT TO BOX------------
export const addShipmentToBoxStart = createAction(
  boxesTypes.ADD_SHIPMENT_TO_BOX_START,
  props<{ payload: AddShipmentToBoxRequestBody }>()
);
export const addShipmentToBoxSuccess = createAction(boxesTypes.ADD_SHIPMENT_TO_BOX_SUCCESS);
export const addShipmentToBoxFailure = createAction(boxesTypes.ADD_SHIPMENT_TO_BOX_FAILURE, props<ErrorType>());

//------------REMOVE SHIPMENT FROM BOX------------
export const removeShipmentFromBoxStart = createAction(
  boxesTypes.REMOVE_SHIPMENT_FROM_BOX_START,
  props<{ payload: RemoveShipmentFromBoxRequestBody }>()
);
export const removeShipmentFromBoxSuccess = createAction(boxesTypes.REMOVE_SHIPMENT_FROM_BOX_SUCCESS);
export const removeShipmentFromBoxFailure = createAction(
  boxesTypes.REMOVE_SHIPMENT_FROM_BOX_FAILURE,
  props<ErrorType>()
);

//------------UPDATE SHIPMENT STATUS------------
export const updateBoxStatusStart = createAction(
  boxesTypes.UPDATE_BOX_STATUS_START,
  props<{ payload: UpdateBoxStatusRequestBody }>()
);
export const updateBoxStatusSuccess = createAction(boxesTypes.UPDATE_BOX_STATUS_SUCCESS);
export const updateBoxStatusFailure = createAction(boxesTypes.UPDATE_BOX_STATUS_FAILURE, props<ErrorType>());
