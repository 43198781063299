import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'bey-with-spinner',
  templateUrl: './bey-with-spinner.component.html',
  styleUrls: ['./bey-with-spinner.component.scss'],
})
export class BeyWithSpinnerComponent implements OnInit {
  /****
   * if to show card
   */
  @Input()
  card: Observable<boolean>;

  /****
   * if it's true then the spinner should be displayed
   */
  @Input()
  isLoading: Observable<boolean>;

  /****
   * if it has content then an error occurred while sending the http request
   */
  @Input()
  errorMessage: boolean;

  @Output()
  onTryAgain: EventEmitter<boolean> = new EventEmitter();

  @ContentChildren('component')
  component: QueryList<any>;

  constructor() {}

  ngOnInit(): void {}

  onTryAgainClick(): void {
    this.onTryAgain.emit(true);
  }
}
