<button
  (click)="onClick.emit($event)"
  [disabled]="disabled || isLoading"
  [ngClass]="getClasses"
  [type]="submit ? 'submit' : 'button'">
  <span *ngIf="isLoading; else content">... يرجى الإنتظار</span>
  <ng-template #content
    ><span *ngIf="!!icon" [data-feather]="icon"></span
    ><span *ngIf="!!placeholder" style="margin-right: 5px">{{ placeholder }}</span></ng-template
  >
</button>
