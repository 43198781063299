import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import {
  GetPurchasesResponseType,
  CreatePurchaseRequestBody,
  UpdatePurchaseRequestBody,
  Purchase,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  constructor(private http: HttpClient) {}

  getPurchaseDetails(id: number) {
    return this.http.get<{ data: Purchase }>(environment.API_URL + 'api/v1/purchases/' + id);
  }

  getPurchases(paramsValues) {
    let params = new HttpParams();

    if (paramsValues) {
      Object.keys(paramsValues).forEach((key) => {
        const val = paramsValues[key];
        val && (params = params.append(key, val));
      });
    }
    return this.http.get<GetPurchasesResponseType>(environment.API_URL + 'api/v1/purchases', { params });
  }

  getMorePurchases(paramsValues) {
    let params = new HttpParams();

    if (paramsValues) {
      Object.keys(paramsValues).forEach((key) => {
        const val = paramsValues[key];
        val && (params = params.append(key, val));
      });
    }
    return this.http.get<GetPurchasesResponseType>(environment.API_URL + 'api/v1/purchases', { params });
  }

  createPurchase(payload: CreatePurchaseRequestBody) {
    return this.http.post<any>(environment.API_URL + 'api/v1/purchases', payload);
  }

  updatePurchase(payload: UpdatePurchaseRequestBody, id: number) {
    return this.http.patch<any>(environment.API_URL + 'api/v1/purchases/' + id, payload);
  }

  deletePurchase(id: number) {
    return this.http.delete<any>(environment.API_URL + `api/v1/purchases/${id}`);
  }
}
