<div *ngIf="isLoading | async; else notLoading" class="p-2 d-flex" [ngClass]="{ card }">
  <div class="spinner-border text-primary mx-auto" role="status"></div>
</div>
<ng-template #notLoading>
  <div *ngIf="errorMessage; else noErrorMessage" class="p-2 d-flex align-items-center justify-content-center">
    <bey-button
      (onClick)="onTryAgainClick()"
      color="danger"
      placeholder="إعادة المحاولة"
      icon="refresh-ccw"></bey-button>
  </div>
  <ng-template #noErrorMessage>
    <ng-content *ngFor="let content of component?.toArray()"></ng-content>
  </ng-template>
</ng-template>
