import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { GetDepositsResponseType, CreateDepositRequestBody, UpdateDepositRequestBody } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class DepositService {
  constructor(private http: HttpClient) {}

  getDeposits() {
    return this.http.get<GetDepositsResponseType>(environment.API_URL + 'api/v1/deposits');
  }

  getMoreDeposits(page: number) {
    return this.http.get<GetDepositsResponseType>(environment.API_URL + `api/v1/deposits?page=${page}`);
  }

  createDeposit(payload: CreateDepositRequestBody) {
    return this.http.post<any>(environment.API_URL + 'api/v1/deposits', payload);
  }

  updateDeposit(payload: UpdateDepositRequestBody, id: number) {
    return this.http.put<any>(environment.API_URL + 'api/v1/deposits/' + id, payload);
  }

  deleteDeposit(id: number) {
    return this.http.delete<any>(environment.API_URL + `api/v1/deposits/${id}`);
  }
}
