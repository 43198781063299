import { createReducer, on } from '@ngrx/store';
import { WalletsState } from './wallets.interfaces';
import {
  getUserWalletsStart,
  getUserWalletsSuccess,
  getUserWalletsFailure,
  getUserWalletOperationsStart,
  getUserWalletOperationsSuccess,
  getUserWalletOperationsFailure,
  getMoreUserWalletOperationsStart,
  getMoreUserWalletOperationsSuccess,
  getMoreUserWalletOperationsFailure,
} from './wallets.actions';

const initialState: WalletsState = {
  userWallets: null,
  userWalletOperations: [],
  userWalletOperationsMeta: null,
  isLoading: {
    getUserWallets: false,
    getUserWalletOperations: false,
    getMoreUserWalletOperations: false,
  },
  errorMessages: {
    getUserWallets: '',
    getUserWalletOperations: '',
    getMoreUserWalletOperations: '',
  },
};

export const walletsReducer = createReducer(
  initialState,
  //----------START----------
  on(getUserWalletsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUserWallets: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getUserWallets: '',
    },
  })),
  on(getUserWalletOperationsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUserWalletOperations: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getUserWalletOperations: '',
    },
  })),
  on(getMoreUserWalletOperationsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreUserWalletOperations: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreUserWalletOperations: '',
    },
  })),
  //----------SUCCESS----------
  on(getUserWalletsSuccess, (state, { payload }) => ({
    ...state,
    userWallets: payload['data'],
    isLoading: {
      ...state.isLoading,
      getUserWallets: false,
    },
  })),
  on(getUserWalletOperationsSuccess, (state, { payload }) => ({
    ...state,
    userWalletOperations: payload['data'],
    isLoading: {
      ...state.isLoading,
      getUserWalletOperations: false,
    },
  })),
  on(getMoreUserWalletOperationsSuccess, (state, { payload }) => ({
    ...state,
    userWalletOperations: payload['data'],
    userWalletOperationsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreUserWalletOperations: false,
    },
  })),
  //----------FAILURE----------
  on(getUserWalletsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUserWallets: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getUserWallets: error,
    },
  })),
  on(getUserWalletOperationsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUserWalletOperations: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getUserWalletOperations: error,
    },
  })),
  on(getMoreUserWalletOperationsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreUserWalletOperations: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreUserWalletOperations: error,
    },
  }))
);
