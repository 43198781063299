import { createReducer, on } from '@ngrx/store';
import * as authActions from './auth.actions';
import { getUserDetailsSuccess } from '../actions';
import { AuthState } from './auth.interfaces';

const initialState: AuthState = {
  currentUser: null,
  settings: null,
  token: null,
  isLoading: {
    login: false,
    getSettings: false,
    updateSettings: false,
    generateResetPasswordToken: false,
    resetPassword: false,
  },
  errorMessages: {
    login: '',
    getSettings: '',
    updateSettings: '',
    generateResetPasswordToken: '',
    resetPassword: '',
  },
};

export const authReducer = createReducer(
  initialState,
  //----------START----------
  on(authActions.loginStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      login: true,
    },
    errorMessages: {
      ...state.errorMessages,
      login: '',
    },
  })),
  on(authActions.getSettingsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getSettings: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getSettings: '',
    },
  })),
  on(authActions.updateSettingsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateSettings: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateSettings: '',
    },
  })),
  on(authActions.generateResetPasswordTokenStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      generateResetPasswordToken: true,
    },
    errorMessages: {
      ...state.errorMessages,
      generateResetPasswordToken: '',
    },
  })),
  on(authActions.resetPasswordStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      resetPassword: true,
    },
    errorMessages: {
      ...state.errorMessages,
      resetPassword: '',
    },
  })),
  //----------SUCCESS----------
  on(authActions.loginSuccess, (state, { payload: { data, token } }) => ({
    ...state,
    token,
    currentUser: data,
    isLoading: {
      ...state.isLoading,
      login: false,
    },
    errorMessages: {
      ...state.errorMessages,
      login: '',
    },
  })),
  on(authActions.getSettingsSuccess, (state, { payload: { data } }) => ({
    ...state,
    settings: data,
    isLoading: {
      ...state.isLoading,
      getSettings: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getSettings: '',
    },
  })),
  on(authActions.updateSettingsSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateSettings: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateSettings: '',
    },
  })),
  on(authActions.generateResetPasswordTokenSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      generateResetPasswordToken: false,
    },
    errorMessages: {
      ...state.errorMessages,
      generateResetPasswordToken: '',
    },
  })),
  on(authActions.resetPasswordSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      resetPassword: false,
    },
    errorMessages: {
      ...state.errorMessages,
      resetPassword: '',
    },
  })),
  //----------FAILURE----------
  on(authActions.loginFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      login: false,
    },
    errorMessages: {
      ...state.errorMessages,
      login: error,
    },
  })),
  on(authActions.getSettingsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getSettings: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getSettings: error,
    },
  })),
  on(authActions.updateSettingsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateSettings: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateSettings: error,
    },
  })),
  on(authActions.generateResetPasswordTokenFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      generateResetPasswordToken: false,
    },
    errorMessages: {
      ...state.errorMessages,
      generateResetPasswordToken: error,
    },
  })),
  on(authActions.resetPasswordFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      resetPassword: false,
    },
    errorMessages: {
      ...state.errorMessages,
      resetPassword: error,
    },
  })),
  //----------OTHER----------
  on(authActions.logOut, (state) => ({
    ...state,
    tokens: null,
    currentUser: null,
  })),
  on(getUserDetailsSuccess, (state, { payload }) => ({
    ...state,
    currentUser: { ...state.currentUser, name: payload.name },
  }))
);
