export const userTypes = {
  CREATE_USER_START: 'CREATE_USER_START',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  GET_USER_DETAILS_START: 'GET_USER_DETAILS_START',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
  GET_USERS_START: 'GET_USERS_START',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  GET_USERS_MORE_START: 'GET_USERS_MORE_START',
  GET_USERS_MORE_SUCCESS: 'GET_USERS_MORE_SUCCESS',
  GET_USERS_MORE_FAILURE: 'GET_USERS_MORE_FAILURE',
  EDIT_USER_DETAILS_START: 'EDIT_USER_DETAILS_START',
  EDIT_USER_DETAILS_SUCCESS: 'EDIT_USER_DETAILS_SUCCESS',
  EDIT_USER_DETAILS_FAILURE: 'EDIT_USER_DETAILS_FAILURE',
  DELETE_USER_START: 'DELETE_USER_START',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  CHANGE_USER_STATUS_START: 'CHANGE_USER_STATUS_START',
  CHANGE_USER_STATUS_SUCCESS: 'CHANGE_USER_STATUS_SUCCESS',
  CHANGE_USER_STATUS_FAILURE: 'CHANGE_USER_STATUS_FAILURE',
  CREATE_ADMIN_START: 'CREATE_ADMIN_START',
  CREATE_ADMIN_SUCCESS: 'CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_FAILURE: 'CREATE_ADMIN_FAILURE',
  GET_ADMINS_START: 'GET_ADMINS_START',
  GET_ADMINS_SUCCESS: 'GET_ADMINS_SUCCESS',
  GET_ADMINS_FAILURE: 'GET_ADMINS_FAILURE',
  GET_ADMINS_MORE_START: 'GET_ADMINS_MORE_START',
  GET_ADMINS_MORE_SUCCESS: 'GET_ADMINS_MORE_SUCCESS',
  GET_ADMINS_MORE_FAILURE: 'GET_ADMINS_MORE_FAILURE',
};
