import { Box, Shipment } from 'app/ngrx/interfaces';
import { environment } from '../../environments/environment';

export const getFormattedNumber = (number: number, removeDecimals: boolean = false): string => {
  return new Intl.NumberFormat().format(removeDecimals ? getTruncatedNumber(number) : number);
};

export const getTruncatedNumber = (number: number): number => {
  // removes number's decimals
  return Math.trunc(number);
};

/******
 * Load the recaptcha script from Google using proper environment key
 */
export const loadRecaptchaScript = (): void => {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.RECAPTCHA_V3_SITE_KEY}`;
  document.head.appendChild(script);
};

/*****
 * Generate and return Recaptcha token
 */
export const initRecaptcha = (action: 'user_register' | 'user_login'): Promise<string> => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(() =>
      window.grecaptcha.enterprise.execute(environment.RECAPTCHA_V3_SITE_KEY, { action }).then((token) => {
        if (token) {
          return resolve(token);
        }
        reject('Error generating recaptcha token.');
      })
    );
  });
};

export const getReferralLink = (referralCode: string): string => {
  return environment.HOST_URL + 'signup/' + referralCode;
};

export const retrieveLimitAndOffsetValues = (next: string): { limit: string; offset: string } => {
  return {
    limit: next?.slice(next.indexOf('limit=') + 6, next.indexOf('&offset')),
    offset: next?.slice(next.indexOf('offset=') + 7, next.indexOf('&ordering')),
  };
};

export const getCountryName = (id: number): string => {
  switch (id) {
    case 1:
      return 'الولايات المتحدة الأمريكية';
    case 2:
      return 'الإمارات العربية المتحدة';
    case 3:
      return 'بريطانيا';
    default:
      return '';
  }
};

export const getBeyBadgeParamsForShipment = (
  status: Shipment['current_status']
): { color: string; placeholder: string } => {
  switch (status) {
    case 'in_warehouse':
      return { color: 'dark', placeholder: 'في المخزن' };
    case 'delivering':
      return { color: 'warning', placeholder: 'في الطريق' };
    case 'ready':
      return { color: 'primary', placeholder: 'وصلت ليبيا' };
    case 'done':
      return { color: 'success', placeholder: 'تم تسليمها' };
  }
};

export const getBeyBadgeParamsForBoxType = (status: Box['type']): { color: string; placeholder: string } => {
  switch (status) {
    case 'multi_user':
      return { color: 'primary', placeholder: 'عادي' };
    case 'single_user':
      return { color: 'success', placeholder: 'تجميعة' };
  }
};

/*****
 * Return numbers in two digits format
 */
export const returnNumberInTwoDigitsFormat = (number: number): string => {
  return number.toLocaleString(undefined, { minimumIntegerDigits: 2 });
};
