import { Component, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public coreConfig: any;
  private subs$ = new Subscription();

  constructor(private _coreConfigService: CoreConfigService, private _router: Router) {
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  onButtonClick(): void {
    this._router.navigate(['/']);
  }

  ngOnInit(): void {
    this.subs$.add(this._coreConfigService.config.subscribe(config => {
      this.coreConfig = config;
    }))
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
