import { createAction, props } from '@ngrx/store';
import {
  Purchase,
  ErrorType,
  GetPurchasesResponseType,
  CreatePurchaseRequestBody,
  UpdatePurchaseRequestBody,
} from '../interfaces';
import { purchasesTypes } from './purchases.types';

//------------GET PURCHASE DETAILS PURCHASE ------------
export const getPurchaseDetailsStart = createAction(
  purchasesTypes.GET_PURCHASE_DETAILS_START,
  props<{ payload: { id: number } }>()
);
export const getPurchaseDetailsSuccess = createAction(
  purchasesTypes.GET_PURCHASE_DETAILS_SUCCESS,
  props<{ payload: { purchase: Purchase } }>()
);
export const getPurchaseDetailsFailure = createAction(purchasesTypes.GET_PURCHASE_DETAILS_FAILURE, props<ErrorType>());

//------------CREATE PURCHASE ------------
export const createPurchaseStart = createAction(
  purchasesTypes.CREATE_PURCHASE_START,
  props<{ payload: CreatePurchaseRequestBody }>()
);
export const createPurchaseSuccess = createAction(purchasesTypes.CREATE_PURCHASE_SUCCESS);
export const createPurchaseFailure = createAction(purchasesTypes.CREATE_PURCHASE_FAILURE, props<ErrorType>());

//------------EDIT PURCHASE ------------
export const updatePurchaseStart = createAction(
  purchasesTypes.EDIT_PURCHASE_START,
  props<{ payload: UpdatePurchaseRequestBody; id: number }>()
);
export const updatePurchaseSuccess = createAction(purchasesTypes.EDIT_PURCHASE_SUCCESS);
export const updatePurchaseFailure = createAction(purchasesTypes.EDIT_PURCHASE_FAILURE, props<ErrorType>());

//------------DELETE PURCHASE------------
export const deletePurchaseStart = createAction(
  purchasesTypes.DELETE_PURCHASE_START,
  props<{ payload: { id: number } }>()
);
export const deletePurchaseSuccess = createAction(purchasesTypes.DELETE_PURCHASE_SUCCESS);
export const deletePurchaseFailure = createAction(purchasesTypes.DELETE_PURCHASE_FAILURE, props<ErrorType>());

//------------GET PURCHASES------------
export const getPurchasesStart = createAction(purchasesTypes.GET_PURCHASES_START, props<{ payload?: object }>());
export const getPurchasesSuccess = createAction(
  purchasesTypes.GET_PURCHASES_SUCCESS,
  props<{ payload: GetPurchasesResponseType }>()
);
export const getPurchasesFailure = createAction(purchasesTypes.GET_PURCHASES_FAILURE, props<ErrorType>());

//------------GET MORE PURCHASES------------
export const getMorePurchasesStart = createAction(
  purchasesTypes.GET_MORE_PURCHASES_START,
  props<{ payload: { page: number } }>()
);
export const getMorePurchasesSuccess = createAction(
  purchasesTypes.GET_MORE_PURCHASES_SUCCESS,
  props<{ payload: GetPurchasesResponseType; append?: boolean }>()
);
export const getMorePurchasesFailure = createAction(purchasesTypes.GET_MORE_PURCHASES_FAILURE, props<ErrorType>());
