import { createReducer, on } from '@ngrx/store';
import { MiscState } from './misc.interface';
import { setScrollingPosition } from './misc.actions';

const initState: MiscState = {
  scrollingPosition: null,
};

export const MiscReducer = createReducer(
  initState,
  on(setScrollingPosition, (state, { payload }) => {
    return {
      ...state,
      scrollingPosition: payload,
    };
  })
);
