import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoginRequestBody, LoginResponseBody, GetSettingsResponseBody } from './auth.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  loginUser({ email, pin }: LoginRequestBody) {
    return this.http.post<LoginResponseBody>(environment.API_URL + 'api/v1/login', {
      email,
      password: pin,
    });
  }

  getSettings() {
    return this.http.get<GetSettingsResponseBody>(environment.API_URL + 'api/v1/settings');
  }

  checkResetPasswordToken(token: string, email: string) {
    return this.http.post(environment.API_URL + 'api/v1/forget-password/check', { email, token });
  }

  resetPassword(payload) {
    return this.http.put(environment.API_URL + 'api/v1/me/password', { ...payload });
  }

  resetForgotPassword(payload) {
    return this.http.post(environment.API_URL + 'api/v1/forget-password/reset', { ...payload });
  }

  generateResetPasswordToken(email: string) {
    return this.http.post(environment.API_URL + 'api/v1/forget-password/mail', { email });
  }

  updateSettings(settings) {
    return this.http.put(environment.API_URL + 'api/v1/settings/all', { settings });
  }
}
