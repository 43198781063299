import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bey-file',
  templateUrl: './bey-file.component.html',
  styleUrls: ['./bey-file.component.scss'],
})
export class BeyFileComponent implements OnInit {
  /****
   * the link of the file to be displayed
   */
  @Input() link: string;

  /****
   * the name of the file to be displayed
   */
  @Input() name: string;

  /****
   * the parameters of the bey-badge component
   */
  @Input() badgeParams: { placeholder: string; color: string; light: boolean; pill: boolean };

  @Output() onClick: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  fileClicked(): void {
    this.onClick.emit(true);
  }
}
