import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { WithdrawalsState } from './withdrawals.interfaces';

const selectWithdrawal = (state: AppState) => {
  return state.withdrawals;
};

//---------1ST LEVEL SELECTORS---------
export const selectWithdrawals = createSelector(selectWithdrawal, (state: WithdrawalsState) => state.withdrawals);

export const selectWithdrawalsObject = createSelector(selectWithdrawal, (state: WithdrawalsState) => {
  return {
    results: state.withdrawals,
    currentPage: state['withdrawalsMeta']?.['current_page'] | 1,
    total: state['withdrawalsMeta']?.['total'] | 1,
  };
});

export const selectWithdrawalsMeta = createSelector(
  selectWithdrawal,
  (state: WithdrawalsState) => state.withdrawalsMeta
);

export const selectWithdrawalIsLoading = createSelector(selectWithdrawal, (state: WithdrawalsState) => state.isLoading);

export const selectWithdrawalErrorMessages = createSelector(
  selectWithdrawal,
  (state: WithdrawalsState) => state.errorMessages
);

//---------2ND LEVEL SELECTORS---------
export const selectGetWithdrawalsIsLoading = createSelector(
  selectWithdrawalIsLoading,
  (isLoading) => isLoading['getWithdrawals']
);
export const selectGetWithdrawalsErrorMessage = createSelector(
  selectWithdrawalErrorMessages,
  (errorMessage) => errorMessage['getWithdrawals']
);

export const selectGetMoreWithdrawalsIsLoading = createSelector(
  selectWithdrawalIsLoading,
  (isLoading) => isLoading['getMoreWithdrawals']
);
export const selectGetMoreWithdrawalsErrorMessage = createSelector(
  selectWithdrawalErrorMessages,
  (errorMessage) => errorMessage['getMoreWithdrawals']
);

export const selectCreateWithdrawalIsLoading = createSelector(
  selectWithdrawalIsLoading,
  (isLoading) => isLoading['createWithdrawal']
);
export const selectCreateWithdrawalErrorMessage = createSelector(
  selectWithdrawalErrorMessages,
  (errorMessage) => errorMessage['createWithdrawal']
);

export const selectDeleteWithdrawalIsLoading = createSelector(
  selectWithdrawalIsLoading,
  (isLoading) => isLoading['deleteWithdrawal']
);
export const selectDeleteWithdrawalErrorMessage = createSelector(
  selectWithdrawalErrorMessages,
  (errorMessage) => errorMessage['deleteWithdrawal']
);

export const selectUpdateWithdrawalIsLoading = createSelector(
  selectWithdrawalIsLoading,
  (isLoading) => isLoading['updateWithdrawal']
);
export const selectUpdateWithdrawalErrorMessage = createSelector(
  selectWithdrawalErrorMessages,
  (errorMessage) => errorMessage['updateWithdrawal']
);
