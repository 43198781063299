<div class="bey-sort" outsideClickListener (outsideClicked)="closeMenu()" [ngClass]="{ opened: !!opened }">
  <div class="bey-sort-content" (click)="onClick()">
    <span class="placeholder">{{ placeholder || 'Sort by:' }}</span>
    <img class="icon" src="assets/images/icons/chevron-up-blue.svg" />
  </div>

  <bey-menu
    [opened]="opened"
    [options]="options"
    [multipleSelection]="false"
    [selectedOptions]="selectedOption ? [selectedOption] : []"
    (itemSelected)="onSelect($event)"></bey-menu>
</div>
