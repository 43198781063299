import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { GetUserWalletsResponseType, GetUserWalletOperationsResponseType } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class WalletsService {
  constructor(private http: HttpClient) {}

  getUserWallets() {
    return this.http.get<GetUserWalletsResponseType>(environment.API_URL + 'api/v1/wallets');
  }

  getUserWalletOperations(wallet_id: number) {
    return this.http.get<GetUserWalletOperationsResponseType>(environment.API_URL + `api/v1/wallets-operations`, {
      params: { 'filter[wallet_id]': wallet_id },
    });
  }

  getMoreUserWalletOperations(wallet_id: number, page: number) {
    return this.http.get<GetUserWalletOperationsResponseType>(environment.API_URL + 'api/v1/wallets-operations', {
      params: { page: page.toString(), 'filter[wallet_id]': wallet_id },
    });
  }
}
