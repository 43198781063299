import { createAction, props } from '@ngrx/store';
import { UpdateExpenseRequestBody, ErrorType, GetExpensesResponseType, CreateExpenseRequestBody } from '../interfaces';
import { expenseTypes } from './expenses.types';

//------------CREATE EXPENSE ------------
export const createExpenseStart = createAction(
  expenseTypes.CREATE_EXPENSE_START,
  props<{ payload: CreateExpenseRequestBody }>()
);
export const createExpenseSuccess = createAction(expenseTypes.CREATE_EXPENSE_SUCCESS);
export const createExpenseFailure = createAction(expenseTypes.CREATE_EXPENSE_FAILURE, props<ErrorType>());

//------------UPDATE EXPENSE ------------
export const updateExpenseStart = createAction(
  expenseTypes.UPDATE_EXPENSE_START,
  props<{ payload: UpdateExpenseRequestBody; id: number }>()
);
export const updateExpenseSuccess = createAction(expenseTypes.UPDATE_EXPENSE_SUCCESS);
export const updateExpenseFailure = createAction(expenseTypes.UPDATE_EXPENSE_FAILURE, props<ErrorType>());

//------------DELETE EXPENSE------------
export const deleteExpenseStart = createAction(expenseTypes.DELETE_EXPENSE_START, props<{ payload: { id: number } }>());
export const deleteExpenseSuccess = createAction(expenseTypes.DELETE_EXPENSE_SUCCESS);
export const deleteExpenseFailure = createAction(expenseTypes.DELETE_EXPENSE_FAILURE, props<ErrorType>());

//------------GET EXPENSES------------
export const getExpensesStart = createAction(expenseTypes.GET_EXPENSES_START);
export const getExpensesSuccess = createAction(
  expenseTypes.GET_EXPENSES_SUCCESS,
  props<{ payload: GetExpensesResponseType }>()
);
export const getExpensesFailure = createAction(expenseTypes.GET_EXPENSES_FAILURE, props<ErrorType>());

//------------GET MORE EXPENSES------------
export const getMoreExpensesStart = createAction(
  expenseTypes.GET_MORE_EXPENSES_START,
  props<{ payload: { page: number } }>()
);
export const getMoreExpensesSuccess = createAction(
  expenseTypes.GET_MORE_EXPENSES_SUCCESS,
  props<{ payload: GetExpensesResponseType }>()
);
export const getMoreExpensesFailure = createAction(expenseTypes.GET_MORE_EXPENSES_FAILURE, props<ErrorType>());
