<div
  *ngIf="link"
  class="bey-file d-flex flex-column rounded mx-1 mb-1 position-relative"
  style="width: 230px; height: 180px; border: 1px solid #ebe9f1"
  (click)="fileClicked()">
  <bey-badge
    *ngIf="!!badgeParams"
    class="position-absolute"
    style="top: 5px; right: 5px; z-index: 5"
    [placeholder]="badgeParams.placeholder"
    [color]="badgeParams?.color"
    [pill]="badgeParams.pill"
    [light]="badgeParams.light"></bey-badge>
  <div class="w-100 d-flex align-items-center justify-content-center" style="min-height: 70%">
    <img class="w-100 h-100" style="object-fit: cover; filter: brightness(0.5)" [src]="link" />
  </div>
  <div class="p-1 font-weight-bold bg-white" style="height: 80px">
    <span class="b-clamp-2">
      {{ name }}
    </span>
  </div>
</div>
