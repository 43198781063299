import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// Breadcrumb component interface
export interface Breadcrumb {
  type?: string;
  alignment?: string;
  links?: Array<{
    name: string;
    isLink: boolean;
    link?: string;
    params?: { [key: string]: string | number };
  }>;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  // input variable
  @Input() breadcrumb: Breadcrumb;

  constructor(private router: Router) {}

  ngOnInit() {
    // concatenate default properties with passed properties
    this.breadcrumb = this.breadcrumb;
  }

  onLinkClick(link: Breadcrumb['links'][0]): void {
    this.router.navigate([link.link], link?.params ? { queryParams: link.params } : {});
  }
}
