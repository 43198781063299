import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { GetTransfersResponseType } from '../interfaces'; // Adjust the import based on your actual transfer interfaces

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  constructor(private http: HttpClient) {}

  getTransfers() {
    return this.http.get<GetTransfersResponseType>(environment.API_URL + 'api/v1/transfers');
  }

  getMoreTransfers(page: number) {
    return this.http.get<GetTransfersResponseType>(environment.API_URL + `api/v1/transfers?page=${page}`);
  }

  createTransfer(payload: any) {
    return this.http.post<any>(environment.API_URL + 'api/v1/transfers', payload);
  }

  updateTransfer(payload: any, id: number) {
    return this.http.put<any>(environment.API_URL + 'api/v1/transfers/' + id, payload);
  }

  deleteTransfer(id: number) {
    return this.http.delete<any>(environment.API_URL + `api/v1/transfers/${id}`);
  }
}
