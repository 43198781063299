import { Pipe, PipeTransform } from '@angular/core';
import { FormUtilityService } from '../services/form-utility.service';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  constructor(private formUtility: FormUtilityService) {}

  transform(value: Date | string, customFormat: string = 'YYYY/MM/DD', ...args: unknown[]): unknown {
    return this.formUtility.formatDate(value, customFormat);
  }
}
