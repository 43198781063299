import { createReducer, on } from '@ngrx/store';
import { TransfersState } from './transfers.interfaces';
import {
  getTransfersStart,
  getTransfersSuccess,
  getTransfersFailure,
  getMoreTransfersStart,
  getMoreTransfersSuccess,
  getMoreTransfersFailure,
  createTransferStart,
  createTransferSuccess,
  createTransferFailure,
  deleteTransferStart,
  deleteTransferSuccess,
  deleteTransferFailure,
  updateTransferStart,
  updateTransferSuccess,
  updateTransferFailure,
} from '../actions';

const initialState: TransfersState = {
  transfers: null,
  transfersMeta: null,
  isLoading: {
    getTransfers: false,
    getMoreTransfers: false,
    createTransfer: false,
    deleteTransfer: false,
    updateTransfer: false,
  },
  errorMessages: {
    getTransfers: '',
    getMoreTransfers: '',
    createTransfer: '',
    deleteTransfer: '',
    updateTransfer: '',
  },
};

export const transferReducer = createReducer(
  initialState,
  //----------START----------
  on(getTransfersStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getTransfers: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getTransfers: '',
    },
  })),
  on(getMoreTransfersStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreTransfers: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreTransfers: '',
    },
  })),
  on(createTransferStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createTransfer: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createTransfer: '',
    },
  })),
  on(updateTransferStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateTransfer: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateTransfer: '',
    },
  })),
  on(deleteTransferStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteTransfer: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteTransfer: '',
    },
  })),
  //----------SUCCESS----------
  on(getTransfersSuccess, (state, { payload }) => ({
    ...state,
    transfers: payload['data'],
    transfersMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getTransfers: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getTransfers: '',
      getMoreTransfers: '',
    },
  })),
  on(getMoreTransfersSuccess, (state, { payload }) => ({
    ...state,
    transfers: payload['data'],
    transfersMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreTransfers: false,
    },
  })),
  on(createTransferSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createTransfer: false,
    },
  })),
  on(updateTransferSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateTransfer: false,
    },
  })),
  on(deleteTransferSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteTransfer: false,
    },
  })),
  //----------FAILURE----------
  on(getTransfersFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getTransfers: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getTransfers: error,
    },
  })),
  on(getMoreTransfersFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreTransfers: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreTransfers: error,
    },
  })),
  on(createTransferFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createTransfer: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createTransfer: error,
    },
  })),
  on(updateTransferFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateTransfer: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateTransfer: error,
    },
  })),
  on(deleteTransferFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteTransfer: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteTransfer: error,
    },
  }))
);
