import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';
import { effects, reducers } from './reducers-and-effects';

import {
  UserState,
  AuthState,
  WalletsState,
  ExpensesState,
  TransfersState,
  WithdrawalsState,
  DepositsState,
  PurchasesState,
  ShipmentsState,
  BoxesState,
  MiscState,
} from './interfaces';

export interface AppState {
  auth: AuthState;
  user: UserState;
  expenses: ExpensesState;
  wallets: WalletsState;
  transfers: TransfersState;
  withdrawals: WithdrawalsState;
  deposits: DepositsState;
  purchases: PurchasesState;
  shipments: ShipmentsState;
  boxes: BoxesState;
  misc: MiscState;
}

function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({
    keys: [{ auth: ['token', 'currentUser'] }, { user: ['userDetails'] }],
    rehydrate: true,
    storageKeySerializer: (key) => `bey_cs_${key}`,
  })(reducer);
}

const metaReducers: Array<MetaReducer<AppState, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers: metaReducers }),
    EffectsModule.forRoot(effects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  declarations: [],
})
export class RootStoreModule {}
