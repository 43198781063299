import {
  AfterContentInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BeySelectMenuOption } from '../../interfaces';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeySelectComponent),
  multi: true,
};

export interface LoadOnScroll {
  triggeredAction: ({ payload: any }) => Action;
  isLoading: Observable<boolean>;
  next: string;
  currentPage: number;
  extraParams: object;
}

@Component({
  selector: 'bey-select',
  templateUrl: './bey-select.component.html',
  styleUrls: ['./bey-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class BeySelectComponent implements ControlValueAccessor, OnChanges {
  /****
   * Indicator for sync search
   */
  @Input()
  syncSearch: boolean;

  /****
   * if component is multi or not
   */
  @Input()
  isMulti: boolean;

  /****
   * the options that will be displayed inside the menu
   */
  @Input()
  options: Array<BeySelectMenuOption> = [];

  /****
   * placeholder inside the component
   */
  @Input()
  placeholder: string;

  /***
   *  Input field name attribute
   */
  @Input()
  name: string = '';

  /***
   *  Label field name attribute
   */
  @Input()
  label: string = '';

  /***
   *  Disabled attribute
   */
  @Input()
  disabled: boolean = false;

  /***
   *  To display loading state for the selector
   */
  @Input()
  isLoading: Observable<boolean>;

  /***
   *  Search bar properties if the select component supports it
   */
  @Input()
  search: {
    placeholder: string;
    attributeName: string;
    triggeredAction: ({ payload: any }) => Action;
    isLoading: Observable<boolean>;
  };

  /***
   *  Load on scroll feature attributes if the select component supports it
   */
  @Input()
  loadOnScroll: LoadOnScroll;

  @Output()
  onChanged: EventEmitter<BeySelectMenuOption | Array<BeySelectMenuOption>> = new EventEmitter();

  opened: Boolean = false;
  selectedOption: BeySelectMenuOption;
  selectedOptions: Array<BeySelectMenuOption> = [];
  searchBar: string;
  syncSearchBar: string;
  filteredOptions: Array<BeySelectMenuOption> = [];

  constructor(private store$: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.options?.forEach((option) => {
        if (option.initial) {
          this.onSelect(option);
        }
      });
    }
  }

  onClick(): void {
    this.opened = !this.opened;
  }

  onSelect(option: BeySelectMenuOption): void {
    if (!this.isMulti) {
      this.selectedOption = option;

      this.onChanged.emit(this.selectedOption);
      this.opened = false;

      this.value = option['value'];
      this.onChangeCallback(option['value']);
    } else {
      if (!!this.selectedOptions?.find((item) => item.value === option.value)) {
        this.selectedOptions?.splice(
          this.selectedOptions?.findIndex((item) => item.value === option.value),
          1
        );
      } else {
        this.selectedOptions?.push(option);
      }

      this.onChanged.emit(this.selectedOptions);

      let optionsValues = this.selectedOptions?.map((option) => option.value);
      this.value = optionsValues;
      this.onChangeCallback(optionsValues);
    }
  }

  onSearchFieldEnterClick() {
    this.store$.dispatch(
      this.search.triggeredAction({
        payload: {
          [this.search.attributeName]: this.searchBar,
          page: 1,
          ...(this.loadOnScroll.extraParams || {}),
        },
      })
    );
  }

  onSearchBarChange(value: string) {
    if (!!!value) {
      if (this.search) {
        this.store$.dispatch(
          this.search.triggeredAction({ payload: { ...(this.loadOnScroll.extraParams || {}), page: 1 } })
        );
      }
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options.filter(
        (option) => option.value.includes(value) || option.label.includes(value)
      );
    }
  }

  onBottomSeen() {
    if (!!this.loadOnScroll?.next) {
      let params = {};

      if (this.searchBar) {
        params = { [this.search.attributeName]: this.searchBar };
      }
      if (this.loadOnScroll?.extraParams) {
        params = { ...params, ...this.loadOnScroll.extraParams };
      }

      this.store$.dispatch(
        this.loadOnScroll.triggeredAction({
          payload: { ...params, append: true, page: this.loadOnScroll.currentPage + 1 },
        })
      );
    }
  }

  closeMenu(): void {
    this.opened = false;
  }

  //-----From ControlValueAccessor interface-----
  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  get value(): any {
    if (!this.isMulti) {
      return this.selectedOption?.['value'];
    } else {
      return this.selectedOptions?.map((option) => option.value);
    }
  }

  set value(v: any) {
    this.onChangeCallback(v);
  }

  writeValue(value: any) {
    if (!this.isMulti) {
      if (!!value) {
        this.value = value;
      }
    } else {
      this.selectedOptions = value || [];
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  //---------------------------------------------
}
