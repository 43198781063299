export const expenseTypes = {
  CREATE_EXPENSE_START: 'CREATE_EXPENSE_START',
  CREATE_EXPENSE_SUCCESS: 'CREATE_EXPENSE_SUCCESS',
  CREATE_EXPENSE_FAILURE: 'CREATE_EXPENSE_FAILURE',
  UPDATE_EXPENSE_START: 'UPDATE_EXPENSE_START',
  UPDATE_EXPENSE_SUCCESS: 'UPDATE_EXPENSE_SUCCESS',
  UPDATE_EXPENSE_FAILURE: 'UPDATE_EXPENSE_FAILURE',
  DELETE_EXPENSE_START: 'DELETE_EXPENSE_START',
  DELETE_EXPENSE_SUCCESS: 'DELETE_EXPENSE_SUCCESS',
  DELETE_EXPENSE_FAILURE: 'DELETE_EXPENSE_FAILURE',
  GET_EXPENSES_START: 'GET_EXPENSES_START',
  GET_EXPENSES_SUCCESS: 'GET_EXPENSES_SUCCESS',
  GET_EXPENSES_FAILURE: 'GET_EXPENSES_FAILURE',
  GET_MORE_EXPENSES_START: 'GET_MORE_EXPENSES_START',
  GET_MORE_EXPENSES_SUCCESS: 'GET_MORE_EXPENSES_SUCCESS',
  GET_MORE_EXPENSES_FAILURE: 'GET_MORE_EXPENSES_FAILURE',
};
