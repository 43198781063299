<div class="d-flex justify-content-between">
  <div>عرض {{ paginationMeta.start }} إلى {{ paginationMeta.end }} من أصل {{ totalItems }}</div>
  <ngb-pagination
    class="d-flex justify-content-center align-items-center"
    [collectionSize]="totalItems || 1"
    [directionLinks]="true"
    [ellipses]="true"
    [boundaryLinks]="true"
    [pageSize]="limit"
    [maxSize]="10"
    [page]="currentPage"
    [disabled]="(contentIsLoading | async) === true"
    (pageChange)="onPageChange($event)"></ngb-pagination>
</div>
