<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a style="margin-top: 1.8rem; margin-bottom: 0" class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo"> &nbsp; </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex">
  <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
    <li class="nav-item d-flex align-items-center">
      <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
        <span [data-feather]="'menu'" [class]="'ficon'"></span>
      </a>
    </li>
  </ul>

  <div class="d-none d-xl-block"></div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
              <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
                <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
                ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
              >
              <div ngbDropdownMenu aria-labelledby="dropdown-flag">
                <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
                  <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
                </a>
              </div>
            </li> -->
    <!--/ Language selection -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <span class="avatar"
          ><img class="round" src="assets/images/avatars/avatar-blank.png" alt="avatar" height="40" width="40" /><span
            class="avatar-status-online"></span
        ></span>
        <div class="user-nav d-sm-flex text-right">
          <span class="user-name font-weight-bolder">{{ (currentUser$ | async)?.name }}</span
          ><span class="user-status w-100 mt-50">{{ (currentUser$ | async)?.code }}</span>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a style="text-align: start" ngbDropdownItem (click)="logout()"
          ><span [data-feather]="'power'" class="ml-50"></span> تسجيل الخروج</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
