export const depositsTypes = {
  CREATE_DEPOSIT_START: 'CREATE_DEPOSIT_START',
  CREATE_DEPOSIT_SUCCESS: 'CREATE_DEPOSIT_SUCCESS',
  CREATE_DEPOSIT_FAILURE: 'CREATE_DEPOSIT_FAILURE',

  UPDATE_DEPOSIT_START: 'UPDATE_DEPOSIT_START',
  UPDATE_DEPOSIT_SUCCESS: 'UPDATE_DEPOSIT_SUCCESS',
  UPDATE_DEPOSIT_FAILURE: 'UPDATE_DEPOSIT_FAILURE',

  DELETE_DEPOSIT_START: 'DELETE_DEPOSIT_START',
  DELETE_DEPOSIT_SUCCESS: 'DELETE_DEPOSIT_SUCCESS',
  DELETE_DEPOSIT_FAILURE: 'DELETE_DEPOSIT_FAILURE',

  GET_DEPOSITS_START: 'GET_DEPOSITS_START',
  GET_DEPOSITS_SUCCESS: 'GET_DEPOSITS_SUCCESS',
  GET_DEPOSITS_FAILURE: 'GET_DEPOSITS_FAILURE',

  GET_MORE_DEPOSITS_START: 'GET_MORE_DEPOSITS_START',
  GET_MORE_DEPOSITS_SUCCESS: 'GET_MORE_DEPOSITS_SUCCESS',
  GET_MORE_DEPOSITS_FAILURE: 'GET_MORE_DEPOSITS_FAILURE',
};
