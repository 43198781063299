import { createAction, props } from '@ngrx/store';
import {
  Shipment,
  ErrorType,
  GetShipmentsResponseType,
  CreateShipmentRequestBody,
  UpdateShipmentRequestBody,
  GetShipmentCountriesResponseType,
  UpdateShipmentCountryRequestBody,
} from '../interfaces';
import { shipmentsTypes } from './shipments.types';

//------------GET SHIPMENT DETAILS------------
export const getShipmentDetailsStart = createAction(
  shipmentsTypes.GET_SHIPMENT_DETAILS_START,
  props<{ payload: { id: number } }>()
);

export const getShipmentDetailsSuccess = createAction(
  shipmentsTypes.GET_SHIPMENT_DETAILS_SUCCESS,
  props<{ payload: Shipment }>()
);

export const getShipmentDetailsFailure = createAction(shipmentsTypes.GET_SHIPMENT_DETAILS_FAILURE, props<ErrorType>());

//------------CREATE SHIPMENT ------------
export const createShipmentStart = createAction(
  shipmentsTypes.CREATE_SHIPMENT_START,
  props<{ payload: CreateShipmentRequestBody }>()
);
export const createShipmentSuccess = createAction(shipmentsTypes.CREATE_SHIPMENT_SUCCESS);
export const createShipmentFailure = createAction(shipmentsTypes.CREATE_SHIPMENT_FAILURE, props<ErrorType>());

//------------EDIT SHIPMENT ------------
export const updateShipmentStart = createAction(
  shipmentsTypes.EDIT_SHIPMENT_START,
  props<{ payload: UpdateShipmentRequestBody; id: number; isBox?: boolean }>()
);
export const updateShipmentSuccess = createAction(shipmentsTypes.EDIT_SHIPMENT_SUCCESS);
export const updateShipmentFailure = createAction(shipmentsTypes.EDIT_SHIPMENT_FAILURE, props<ErrorType>());

//------------DELETE SHIPMENT------------
export const deleteShipmentStart = createAction(
  shipmentsTypes.DELETE_SHIPMENT_START,
  props<{ payload: { id: number } }>()
);
export const deleteShipmentSuccess = createAction(shipmentsTypes.DELETE_SHIPMENT_SUCCESS);
export const deleteShipmentFailure = createAction(shipmentsTypes.DELETE_SHIPMENT_FAILURE, props<ErrorType>());

//------------GET SHIPMENT COUNTRIES------------
export const getShipmentCountriesStart = createAction(shipmentsTypes.GET_SHIPMENT_COUNTRIES_START);
export const getShipmentCountriesSuccess = createAction(
  shipmentsTypes.GET_SHIPMENT_COUNTRIES_SUCCESS,
  props<{ payload: GetShipmentCountriesResponseType }>()
);
export const getShipmentCountriesFailure = createAction(
  shipmentsTypes.GET_SHIPMENT_COUNTRIES_FAILURE,
  props<ErrorType>()
);

//------------UPDATE SHIPMENT COUNTRIES------------
export const updateShipmentCountriesStart = createAction(
  shipmentsTypes.UPDATE_SHIPMENT_COUNTRIES_START,
  props<{ payload: UpdateShipmentCountryRequestBody }>()
);
export const updateShipmentCountriesSuccess = createAction(shipmentsTypes.UPDATE_SHIPMENT_COUNTRIES_SUCCESS);
export const updateShipmentCountriesFailure = createAction(
  shipmentsTypes.UPDATE_SHIPMENT_COUNTRIES_FAILURE,
  props<ErrorType>()
);

//------------GET SHIPMENTS------------
export const getShipmentsStart = createAction(shipmentsTypes.GET_SHIPMENTS_START, props<{ payload?: object }>());
export const getShipmentsSuccess = createAction(
  shipmentsTypes.GET_SHIPMENTS_SUCCESS,
  props<{ payload: GetShipmentsResponseType }>()
);
export const getShipmentsFailure = createAction(shipmentsTypes.GET_SHIPMENTS_FAILURE, props<ErrorType>());

//------------GET MORE SHIPMENTS------------
export const getMoreShipmentsStart = createAction(
  shipmentsTypes.GET_MORE_SHIPMENTS_START,
  props<{ payload: { [key: string]: any } }>()
);
export const getMoreShipmentsSuccess = createAction(
  shipmentsTypes.GET_MORE_SHIPMENTS_SUCCESS,
  props<{ payload: GetShipmentsResponseType; append?: boolean }>()
);
export const getMoreShipmentsFailure = createAction(shipmentsTypes.GET_MORE_SHIPMENTS_FAILURE, props<ErrorType>());

//------------ADD SHIPMENT PHOTO------------
export const addShipmentPhotoStart = createAction(
  shipmentsTypes.ADD_SHIPMENT_PHOTO_START,
  props<{ payload: { shipment_id: number; image: File } }>()
);
export const addShipmentPhotoSuccess = createAction(shipmentsTypes.ADD_SHIPMENT_PHOTO_SUCCESS);
export const addShipmentPhotoFailure = createAction(shipmentsTypes.ADD_SHIPMENT_PHOTO_FAILURE, props<ErrorType>());

//------------REMOVE SHIPMENT PHOTO------------
export const removeShipmentPhotoStart = createAction(
  shipmentsTypes.REMOVE_SHIPMENT_PHOTO_START,
  props<{ payload: { shipment_id: number; image_id: number } }>()
);
export const removeShipmentPhotoSuccess = createAction(shipmentsTypes.REMOVE_SHIPMENT_PHOTO_SUCCESS);
export const removeShipmentPhotoFailure = createAction(
  shipmentsTypes.REMOVE_SHIPMENT_PHOTO_FAILURE,
  props<ErrorType>()
);
