import { createAction, props } from '@ngrx/store';
import {
  UpdateWithdrawalRequestBody,
  ErrorType,
  GetWithdrawalsResponseType,
  CreateWithdrawalRequestBody,
} from '../interfaces';
import { withdrawalsTypes } from './withdrawals.types';

//------------CREATE WITHDRAWAL ------------
export const createWithdrawalStart = createAction(
  withdrawalsTypes.CREATE_WITHDRAWAL_START,
  props<{ payload: CreateWithdrawalRequestBody }>()
);
export const createWithdrawalSuccess = createAction(withdrawalsTypes.CREATE_WITHDRAWAL_SUCCESS);
export const createWithdrawalFailure = createAction(withdrawalsTypes.CREATE_WITHDRAWAL_FAILURE, props<ErrorType>());

//------------UPDATE WITHDRAWAL ------------
export const updateWithdrawalStart = createAction(
  withdrawalsTypes.UPDATE_WITHDRAWAL_START,
  props<{ payload: UpdateWithdrawalRequestBody; id: number }>()
);
export const updateWithdrawalSuccess = createAction(withdrawalsTypes.UPDATE_WITHDRAWAL_SUCCESS);
export const updateWithdrawalFailure = createAction(withdrawalsTypes.UPDATE_WITHDRAWAL_FAILURE, props<ErrorType>());

//------------DELETE WITHDRAWAL------------
export const deleteWithdrawalStart = createAction(
  withdrawalsTypes.DELETE_WITHDRAWAL_START,
  props<{ payload: { id: number } }>()
);
export const deleteWithdrawalSuccess = createAction(withdrawalsTypes.DELETE_WITHDRAWAL_SUCCESS);
export const deleteWithdrawalFailure = createAction(withdrawalsTypes.DELETE_WITHDRAWAL_FAILURE, props<ErrorType>());

//------------GET WITHDRAWALS------------
export const getWithdrawalsStart = createAction(withdrawalsTypes.GET_WITHDRAWALS_START);
export const getWithdrawalsSuccess = createAction(
  withdrawalsTypes.GET_WITHDRAWALS_SUCCESS,
  props<{ payload: GetWithdrawalsResponseType }>()
);
export const getWithdrawalsFailure = createAction(withdrawalsTypes.GET_WITHDRAWALS_FAILURE, props<ErrorType>());

//------------GET MORE WITHDRAWALS------------
export const getMoreWithdrawalsStart = createAction(
  withdrawalsTypes.GET_MORE_WITHDRAWALS_START,
  props<{ payload: { page: number } }>()
);
export const getMoreWithdrawalsSuccess = createAction(
  withdrawalsTypes.GET_MORE_WITHDRAWALS_SUCCESS,
  props<{ payload: GetWithdrawalsResponseType }>()
);
export const getMoreWithdrawalsFailure = createAction(
  withdrawalsTypes.GET_MORE_WITHDRAWALS_FAILURE,
  props<ErrorType>()
);
