export const walletsTypes = {
  GET_USER_WALLETS_START: 'GET_USER_WALLETS_START',
  GET_USER_WALLETS_SUCCESS: 'GET_USER_WALLETS_SUCCESS',
  GET_USER_WALLETS_FAILURE: 'GET_USER_WALLETS_FAILURE',
  GET_USER_WALLET_OPERATIONS_START: 'GET_USER_WALLET_OPERATIONS_START',
  GET_USER_WALLET_OPERATIONS_SUCCESS: 'GET_USER_WALLET_OPERATIONS_SUCCESS',
  GET_USER_WALLET_OPERATIONS_FAILURE: 'GET_USER_WALLET_OPERATIONS_FAILURE',
  GET_MORE_USER_WALLET_OPERATIONS_START: 'GET_MORE_USER_WALLET_OPERATIONS_START',
  GET_MORE_USER_WALLET_OPERATIONS_SUCCESS: 'GET_MORE_USER_WALLET_OPERATIONS_SUCCESS',
  GET_MORE_USER_WALLET_OPERATIONS_FAILURE: 'GET_MORE_USER_WALLET_OPERATIONS_FAILURE',
};
