import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { TransfersState } from './transfers.interfaces';

const selectTransfer = (state: AppState) => {
  return state.transfers;
};

//---------1ST LEVEL SELECTORS---------
export const selectTransfers = createSelector(selectTransfer, (state: TransfersState) => state.transfers);
export const selectTransfersObject = createSelector(selectTransfer, (state: TransfersState) => {
  return {
    results: state.transfers,
    currentPage: state['transfersMeta']?.['current_page'] | 1,
    total: state['transfersMeta']?.['total'] | 1,
  };
});

export const selectTransfersMeta = createSelector(selectTransfer, (state: TransfersState) => state.transfersMeta);

export const selectTransferIsLoading = createSelector(selectTransfer, (state: TransfersState) => state.isLoading);

export const selectTransferErrorMessages = createSelector(
  selectTransfer,
  (state: TransfersState) => state.errorMessages
);

//---------2ND LEVEL SELECTORS---------

// Transfer selectors
export const selectGetTransfersIsLoading = createSelector(
  selectTransferIsLoading,
  (isLoading) => isLoading['getTransfers']
);
export const selectGetTransfersErrorMessage = createSelector(
  selectTransferErrorMessages,
  (errorMessage) => errorMessage['getTransfers']
);

export const selectGetMoreTransfersIsLoading = createSelector(
  selectTransferIsLoading,
  (isLoading) => isLoading['getMoreTransfers']
);
export const selectGetMoreTransfersErrorMessage = createSelector(
  selectTransferErrorMessages,
  (errorMessage) => errorMessage['getMoreTransfers']
);

export const selectCreateTransferIsLoading = createSelector(
  selectTransferIsLoading,
  (isLoading) => isLoading['createTransfer']
);
export const selectCreateTransferErrorMessage = createSelector(
  selectTransferErrorMessages,
  (errorMessage) => errorMessage['createTransfer']
);

export const selectUpdateTransferIsLoading = createSelector(
  selectTransferIsLoading,
  (isLoading) => isLoading['updateTransfer']
);
export const selectUpdateTransferErrorMessage = createSelector(
  selectTransferErrorMessages,
  (errorMessage) => errorMessage['updateTransfer']
);

export const selectDeleteTransferIsLoading = createSelector(
  selectTransferIsLoading,
  (isLoading) => isLoading['deleteTransfer']
);
export const selectDeleteTransferErrorMessage = createSelector(
  selectTransferErrorMessages,
  (errorMessage) => errorMessage['deleteTransfer']
);
