<div class="d-flex flex-column p-75">
  <span class="text-base font-weight-bold mb-1">{{ data['title'] }}</span>

  <span class="text-sm mb-4">{{ data['content'] }}</span>

  <div class="d-flex justify-content-end w-100">
    <bey-button
      *ngFor="let button of data['buttons']; let i = index; let last = last"
      mat-button
      [ngClass]="last ? '' : 'mr-3'"
      [isLoading]="button['isLoading'] | async"
      [placeholder]="button['placeholder']"
      [outlined]="button['outlined']"
      [color]="button['color']"
      [small]="true"
      (onClick)="onButtonClick(i)"></bey-button>
  </div>
</div>
