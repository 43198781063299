import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absValue',
})
export class AbsValuePipe implements PipeTransform {
  transform(value: number | string): number {
    return Math.abs(Number(value));
  }
}
