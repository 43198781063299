import { createReducer, on } from '@ngrx/store';
import { UserState } from './user.interfaces';
import {
  logOut,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure,
  getUsersMoreStart,
  getUsersMoreSuccess,
  getUsersMoreFailure,
  getUserDetailsStart,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  createUserStart,
  createUserSuccess,
  createUserFailure,
  getAdminsStart,
  getAdminsSuccess,
  getAdminsFailure,
  getAdminsMoreStart,
  getAdminsMoreSuccess,
  getAdminsMoreFailure,
  createAdminStart,
  createAdminSuccess,
  createAdminFailure,
  editUserDetailsStart,
  editUserDetailsSuccess,
  editUserDetailsFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  changeUserStatusStart,
  changeUserStatusSuccess,
  changeUserStatusFailure,
} from '../actions';

const initialState: UserState = {
  userDetails: null,
  adminDetails: null,
  users: null,
  admins: null,
  usersMeta: null,
  adminsMeta: null,
  isLoading: {
    getUserDetails: false,
    getUsers: false,
    getUsersMore: false,
    createUser: false,
    getAdminDetails: false,
    getAdmins: false,
    getAdminsMore: false,
    createAdmin: false,
  },
  errorMessages: {
    getUserDetails: '',
    getUsers: '',
    getUsersMore: '',
    createUser: '',
    getAdminDetails: '',
    getAdmins: '',
    getAdminsMore: '',
    createAdmin: '',
  },
};

export const userReducer = createReducer(
  initialState,
  //----------START----------
  on(getUserDetailsStart, (state) => ({
    ...state,
    userDetails: null,
    isLoading: {
      ...state.isLoading,
      getUserDetails: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getUserDetails: '',
    },
  })),
  on(getUsersStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUsers: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getUsers: '',
    },
  })),
  on(getUsersMoreStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUsersMore: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getUsersMore: '',
    },
  })),
  on(createUserStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createUser: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createUser: '',
    },
  })),
  on(getAdminsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getAdmins: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getAdmins: '',
    },
  })),
  on(getAdminsMoreStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getAdminsMore: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getAdminsMore: '',
    },
  })),
  on(createAdminStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createAdmin: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createAdmin: '',
    },
  })),
  on(editUserDetailsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      editUserDetails: true,
    },
    errorMessages: {
      ...state.errorMessages,
      editUserDetails: '',
    },
  })),
  on(deleteUserStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteUser: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteUser: '',
    },
  })),
  on(resetPasswordStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      resetPassword: true,
    },
    errorMessages: {
      ...state.errorMessages,
      resetPassword: '',
    },
  })),
  on(changeUserStatusStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      changeUserStatus: true,
    },
    errorMessages: {
      ...state.errorMessages,
      changeUserStatus: '',
    },
  })),
  //----------SUCCESS----------
  on(getUserDetailsSuccess, (state, { payload }) => ({
    ...state,
    userDetails: payload,
    isLoading: {
      ...state.isLoading,
      getUserDetails: false,
    },
  })),
  on(getUsersSuccess, (state, { payload }) => ({
    ...state,
    users: payload['data'],
    usersMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getUsers: false,
    },
  })),
  on(getUsersMoreSuccess, (state, { payload, append }) => ({
    ...state,
    users: append ? [...state.users, ...payload['data']] : payload['data'],
    usersMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getUsersMore: false,
    },
  })),
  on(createUserSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createUser: false,
    },
  })),
  on(getAdminsSuccess, (state, { payload }) => ({
    ...state,
    admins: payload['data'],
    adminsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getAdmins: false,
    },
  })),
  on(getAdminsMoreSuccess, (state, { payload }) => ({
    ...state,
    admins: payload['data'],
    adminsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getAdminsMore: false,
    },
  })),
  on(createAdminSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createAdmin: false,
    },
  })),
  on(editUserDetailsSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      editUserDetails: false,
    },
  })),
  on(deleteUserSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteUser: false,
    },
  })),
  on(resetPasswordSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      resetPassword: false,
    },
  })),
  on(changeUserStatusSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      changeUserStatus: false,
    },
  })),
  //----------FAILURE----------
  on(getUserDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUserDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getUserDetails: error,
    },
  })),
  on(getUsersFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUsers: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getUsers: error,
    },
  })),
  on(getUsersMoreFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getUsersMore: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getUsersMore: error,
    },
  })),
  on(createUserFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createUser: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createUser: error,
    },
  })),
  on(getAdminsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getAdmins: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getAdmins: error,
    },
  })),
  on(getAdminsMoreFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getAdminsMore: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getAdminsMore: error,
    },
  })),
  on(createAdminFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createAdmin: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createAdmin: error,
    },
  })),
  on(editUserDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      editUserDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      editUserDetails: error,
    },
  })),
  on(deleteUserFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteUser: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteUser: error,
    },
  })),
  on(resetPasswordFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      resetPassword: false,
    },
    errorMessages: {
      ...state.errorMessages,
      resetPassword: error,
    },
  })),
  on(changeUserStatusFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      changeUserStatus: false,
    },
    errorMessages: {
      ...state.errorMessages,
      changeUserStatus: error,
    },
  })),
  //----------OTHERS----------
  on(logOut, (state) => ({
    ...state,
    userDetails: null,
  }))
);
