import { createAction, props } from '@ngrx/store';
import {
  UpdateTransferRequestBody,
  ErrorType,
  GetTransfersResponseType,
  CreateTransferRequestBody,
} from '../interfaces';
import { transfersTypes } from './transfers.types';

//------------CREATE TRANSFER ------------
export const createTransferStart = createAction(
  transfersTypes.CREATE_TRANSFER_START,
  props<{ payload: CreateTransferRequestBody }>()
);
export const createTransferSuccess = createAction(transfersTypes.CREATE_TRANSFER_SUCCESS);
export const createTransferFailure = createAction(transfersTypes.CREATE_TRANSFER_FAILURE, props<ErrorType>());

//------------UPDATE TRANSFER ------------
export const updateTransferStart = createAction(
  transfersTypes.UPDATE_TRANSFER_START,
  props<{ payload: UpdateTransferRequestBody; id: number }>()
);
export const updateTransferSuccess = createAction(transfersTypes.UPDATE_TRANSFER_SUCCESS);
export const updateTransferFailure = createAction(transfersTypes.UPDATE_TRANSFER_FAILURE, props<ErrorType>());

//------------DELETE TRANSFER------------
export const deleteTransferStart = createAction(
  transfersTypes.DELETE_TRANSFER_START,
  props<{ payload: { id: number } }>()
);
export const deleteTransferSuccess = createAction(transfersTypes.DELETE_TRANSFER_SUCCESS);
export const deleteTransferFailure = createAction(transfersTypes.DELETE_TRANSFER_FAILURE, props<ErrorType>());

//------------GET TRANSFERS------------
export const getTransfersStart = createAction(transfersTypes.GET_TRANSFERS_START);
export const getTransfersSuccess = createAction(
  transfersTypes.GET_TRANSFERS_SUCCESS,
  props<{ payload: GetTransfersResponseType }>()
);
export const getTransfersFailure = createAction(transfersTypes.GET_TRANSFERS_FAILURE, props<ErrorType>());

//------------GET MORE TRANSFERS------------
export const getMoreTransfersStart = createAction(
  transfersTypes.GET_MORE_TRANSFERS_START,
  props<{ payload: { page: number } }>()
);
export const getMoreTransfersSuccess = createAction(
  transfersTypes.GET_MORE_TRANSFERS_SUCCESS,
  props<{ payload: GetTransfersResponseType }>()
);
export const getMoreTransfersFailure = createAction(transfersTypes.GET_MORE_TRANSFERS_FAILURE, props<ErrorType>());
