<div class="dialog-content">
  <div class="header" mat-dialog-title>
    <div>
      <span (click)="onXorCancelClick()" [inner]="true" [size]="20" data-feather="x"></span>
    </div>
    <h3 *ngIf="data?.isBox; else shipmentTitle">تأمين التجميعة</h3>
    <ng-template #shipmentTitle>
      <h3>تأمين الشحنة</h3>
    </ng-template>
  </div>
  <mat-dialog-content>
    <form style="display: flex; flex-direction: column; width: 100%" [formGroup]="form">
      <bey-input
        [label]="!data?.isBox ? 'سعر الشحنة بالدولار' : 'سعر التجميعة بالدولار'"
        type="tel"
        dir="ltr"
        formControlName="shipment_price"
        [displayError]="formUtility.getFieldValidation(form, 'shipment_price')"
        [errorMessage]="formUtility.getFieldErrorMessage(form, 'shipment_price')"></bey-input>
      <bey-input
        [label]="!data?.isBox ? 'وصف الشحنة' : 'وصف التجميعة'"
        type="textarea"
        formControlName="customer_description"
        [displayError]="formUtility.getFieldValidation(form, 'customer_description')"
        [errorMessage]="formUtility.getFieldErrorMessage(form, 'customer_description')"></bey-input>
      <div
        *ngIf="!!form.get('shipment_price').value"
        style="display: flex; flex-direction: column; text-align: right; margin: 15px 0">
        <h5>نسبة عمولة التأمين: %{{ insuranceFeePercentage }}</h5>
        <h3>إجمالي عمولة التأمين: {{ (insuranceFeePercentage / 100) * form.get('shipment_price').value }} دولار</h3>
        <div style="display: flex; align-items: center"></div>
      </div>

      <bey-button
        placeholder="تأمين"
        style="align-self: flex-end"
        [isLoading]="insureShipmentIsLoading$ | async"
        [disabled]="form.invalid"
        (onClick)="onSubmit()"></bey-button>
    </form>
  </mat-dialog-content>
</div>
