import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getFormattedNumber } from '../../../../assets/utility';
import { errorMessages } from '../../../../assets/utility/fieldValidationErrorMessages';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class FormUtilityService {
  subs$: Subscription = new Subscription();

  constructor() {}

  getFieldErrorMessage(form: FormGroup, fieldName: string, customName: string = '', customMessage: string = '') {
    // @ts-ignore
    const hasWarning = form.get(fieldName).warnings;
    const warningType = hasWarning ? Object.keys(hasWarning)[0] : '';

    const hasError = form.get(fieldName).errors;
    let errorType = '';

    if (hasError) {
      let prioritizedError = Object.keys(hasError).filter((error) => error.startsWith('p-'))[0];

      if (prioritizedError) {
        errorType = prioritizedError.split('p-')[1];
      } else {
        errorType = Object.keys(hasError)[0];
      }
    }

    if (hasError) {
      // return custom message | THIS SHOULD BE USED ONLY WITH SELECT COMPONENT BECAUSE IT ONLY REQUIRES ONE VALIDATION RULE (REQUIRED)
      if (customMessage) {
        return customMessage;
      }

      switch (errorType) {
        case 'required':
        case 'xb.required':
          return errorMessages['required'];
        case 'minlength': {
          const {
            minlength: { requiredLength, actualLength },
          } = hasError;
          const toReplace = {
            '{requiredLength}': requiredLength,
            '{actualLength}': actualLength,
          };

          return errorMessages['minlength'].replace(/{field}|{requiredLength}|{actualLength}/g, (m) => {
            return toReplace[m];
          });
        }
        case 'min':
          const { min: minErr } = hasError;
          return errorMessages['min'].replace('{value}', getFormattedNumber(minErr?.['min']));
        case 'max':
          const { max: maxErr } = hasError;
          return errorMessages['max'].replace('{value}', getFormattedNumber(maxErr?.['max']));
        case 'pattern':
          return errorMessages[form.get(fieldName).errors['pattern']['requiredPattern']];

        case 'xb.max':
        case 'xb.min':
          const toReplace = {
            '{value}': getFormattedNumber(hasError?.['value']),
            '{currency}': hasError?.['currency'],
          };

          return errorMessages[errorType].replace(/{field}|{value}|{currency}/g, (m) => {
            return toReplace[m];
          });

        case 'amountExceedWalletLimit': {
          // todo in future we need to use this way to send data instead of hasError?.[errorType] and use one replace method but we change to replace object
          const { currency, value } = hasError;

          const toReplace = {
            '{value}': getFormattedNumber(value, true),
            '{currency}': currency,
          };

          return errorMessages[errorType].replace(/{value}|{currency}/g, (m) => toReplace[m]);
        }

        case 'transactionsLimitExceededCollection':
        case 'transactionsLimitExceeded':
        case 'topUpLimitExceeded': {
          const toReplace = {
            '{value}': getFormattedNumber(hasError?.[errorType]),
            '{currency}': 'UGX', // will be UGX by default for now
          };

          return errorMessages[errorType].replace(/{value}|{currency}/g, (m) => {
            return toReplace[m];
          });
        }

        case 'transactionsLimitExceededCollectionLink': {
          const toReplace = {
            '{activeMerchantName}': hasError?.['activeMerchantName'],
          };

          return errorMessages[errorType].replace(/{activeMerchantName}/g, (m) => toReplace[m]);
        }

        default:
          return errorMessages[errorType];
      }
    }
  }

  getFieldValidation(form: FormGroup, fieldName: string): boolean {
    return form.get(fieldName).invalid && form.get(fieldName).touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /********
   *  THIS METHOD IS USED TO GET THE ERROR MESSAGE FOR COUNTRY CODE PAIRED WITH PHONE NUMBER SELECTOR OR INPUT
   *          <bey-input
   class="w-7/12"
   label="Phone number"
   type="tel"
   name="phone_number"
   formControlName="phone_number"
   errorMessage="Phone is invalid"
   [errorMessage]="phoneFieldErrorMessage"
   [displayError]="phoneFieldErrorMessage"
   [id]="getElementId.getElementId('phone_number', 'input')"></bey-input>
   * @param form
   */
  phoneFieldErrorMessage(form) {
    const {
      phone: { code, phone_number },
    } = form.value;

    //  only return the validation for the group if the code is available
    if (code && phone_number) {
      if (this.getFieldValidation(form, 'phone')) {
        return this.getFieldErrorMessage(form, 'phone');
      }
    }

    if (this.getFieldValidation(form, 'phone.phone_number')) {
      return this.getFieldErrorMessage(form, 'phone.phone_number', 'Phone');
    }

    return null;
  }

  formatDate(value: Date | string, customFormat: string = 'YYYY/MM/DD'): unknown {
    return value ? dayjs(value).format(customFormat) : '-';
  }
}
