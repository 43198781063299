import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { ExpensesState } from './expenses.interfaces';

const selectExpense = (state: AppState) => {
  return state.expenses;
};

//---------1ST LEVEL SELECTORS---------
export const selectExpenses = createSelector(selectExpense, (state: ExpensesState) => state.expenses);
export const selectExpensesObject = createSelector(selectExpense, (state: ExpensesState) => {
  return {
    results: state.expenses,
    currentPage: state['expensesMeta']?.['current_page'] | 1,
    total: state['expensesMeta']?.['total'] | 1,
  };
});

export const selectExpensesMeta = createSelector(selectExpense, (state: ExpensesState) => state.expensesMeta);

export const selectExpenseIsLoading = createSelector(selectExpense, (state: ExpensesState) => state.isLoading);

export const selectExpenseErrorMessages = createSelector(selectExpense, (state: ExpensesState) => state.errorMessages);

//---------2ND LEVEL SELECTORS---------

// Expense selectors
export const selectGetExpensesIsLoading = createSelector(
  selectExpenseIsLoading,
  (isLoading) => isLoading['getExpenses']
);
export const selectGetExpensesErrorMessage = createSelector(
  selectExpenseErrorMessages,
  (errorMessage) => errorMessage['getExpenses']
);

export const selectGetMoreExpensesIsLoading = createSelector(
  selectExpenseIsLoading,
  (isLoading) => isLoading['getMoreExpenses']
);
export const selectGetMoreExpensesErrorMessage = createSelector(
  selectExpenseErrorMessages,
  (errorMessage) => errorMessage['getMoreExpenses']
);

export const selectCreateExpenseIsLoading = createSelector(
  selectExpenseIsLoading,
  (isLoading) => isLoading['createExpense']
);
export const selectCreateExpenseErrorMessage = createSelector(
  selectExpenseErrorMessages,
  (errorMessage) => errorMessage['createExpense']
);

export const selectDeleteExpenseIsLoading = createSelector(
  selectExpenseIsLoading,
  (isLoading) => isLoading['deleteExpense']
);
export const selectDeleteExpenseErrorMessage = createSelector(
  selectExpenseErrorMessages,
  (errorMessage) => errorMessage['deleteExpense']
);

export const selectUpdateExpenseIsLoading = createSelector(
  selectExpenseIsLoading,
  (isLoading) => isLoading['updateExpense']
);
export const selectUpdateExpenseErrorMessage = createSelector(
  selectExpenseErrorMessages,
  (errorMessage) => errorMessage['updateExpense']
);
