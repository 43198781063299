import { createReducer, on } from '@ngrx/store';
import { PurchasesState } from './purchases.interfaces';
import {
  getPurchasesStart,
  getPurchasesSuccess,
  getPurchasesFailure,
  getMorePurchasesStart,
  getMorePurchasesSuccess,
  getMorePurchasesFailure,
  createPurchaseStart,
  createPurchaseSuccess,
  createPurchaseFailure,
  deletePurchaseStart,
  deletePurchaseSuccess,
  deletePurchaseFailure,
  updatePurchaseStart,
  updatePurchaseSuccess,
  updatePurchaseFailure,
  getPurchaseDetailsStart,
  getPurchaseDetailsSuccess,
  getPurchaseDetailsFailure,
} from '../actions';

const initialState: PurchasesState = {
  purchaseDetails: null,
  purchases: null,
  purchasesMeta: null,
  isLoading: {
    getPurchases: false,
    getPurchaseDetails: false,
    getMorePurchases: false,
    createPurchase: false,
    deletePurchase: false,
    updatePurchase: false,
  },
  errorMessages: {
    getPurchases: '',
    getPurchaseDetails: '',
    getMorePurchases: '',
    createPurchase: '',
    deletePurchase: '',
    updatePurchase: '',
  },
};

export const purchaseReducer = createReducer(
  initialState,
  //----------START----------
  on(getPurchaseDetailsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getPurchaseDetails: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getPurchaseDetails: '',
    },
  })),
  on(getPurchasesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getPurchases: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getPurchases: '',
    },
  })),
  on(getMorePurchasesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMorePurchases: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMorePurchases: '',
    },
  })),
  on(createPurchaseStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createPurchase: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createPurchase: '',
    },
  })),
  on(updatePurchaseStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updatePurchase: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updatePurchase: '',
    },
  })),
  on(deletePurchaseStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deletePurchase: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deletePurchase: '',
    },
  })),
  //----------SUCCESS----------
  on(getPurchaseDetailsSuccess, (state, { payload: { purchase } }) => ({
    ...state,
    purchaseDetails: purchase,
    isLoading: {
      ...state.isLoading,
      getPurchaseDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getPurchaseDetails: '',
    },
  })),
  on(getPurchasesSuccess, (state, { payload }) => ({
    ...state,
    purchases: payload['data'],
    purchasesMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getPurchases: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getPurchases: '',
      getMorePurchases: '',
    },
  })),
  on(getMorePurchasesSuccess, (state, { payload, append }) => ({
    ...state,
    purchases: append ? [...state.purchases, ...payload['data']] : payload['data'],
    purchasesMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMorePurchases: false,
    },
  })),
  on(createPurchaseSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createPurchase: false,
    },
  })),
  on(updatePurchaseSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updatePurchase: false,
    },
  })),
  on(deletePurchaseSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deletePurchase: false,
    },
  })),
  //----------FAILURE----------
  on(getPurchaseDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getPurchaseDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getPurchaseDetails: error,
    },
  })),
  on(getPurchasesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getPurchases: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getPurchases: error,
    },
  })),
  on(getMorePurchasesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMorePurchases: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMorePurchases: error,
    },
  })),
  on(createPurchaseFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createPurchase: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createPurchase: error,
    },
  })),
  on(updatePurchaseFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updatePurchase: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updatePurchase: error,
    },
  })),
  on(deletePurchaseFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deletePurchase: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deletePurchase: error,
    },
  }))
);
