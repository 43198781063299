export const authTypes = {
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  GENERATE_RESET_PASSWORD_TOKEN_START: 'GENERATE_RESET_PASSWORD_TOKEN_START',
  GENERATE_RESET_PASSWORD_TOKEN_SUCCESS: 'GENERATE_RESET_PASSWORD_TOKEN_SUCCESS',
  GENERATE_RESET_PASSWORD_TOKEN_FAILURE: 'GENERATE_RESET_PASSWORD_TOKEN_FAILURE',
  GET_SETTINGS_START: 'GET_SETTINGS_START',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
  UPDATE_SETTINGS_START: 'UPDATE_SETTINGS_START',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_FAILURE: 'UPDATE_SETTINGS_FAILURE',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOG_OUT: 'LOG_OUT',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  EMPTY: 'EMPTY',
};
