<img
  class="rounded-circle"
  width="24"
  height="24"
  [src]="'https://flagcdn.com/' + countryCode + '.svg'"
  [alt]="countryCode"
  *ngIf="countryCode; else test" />

<ng-template #test>
  <span style="font-size: 1.16rem">👨‍💻</span>
</ng-template>
