import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import {
  User,
  Admin,
  GetUsersResponseType,
  GetAdminsResponseType,
  CreateUserRequestBody,
  CreateAdminRequestBody,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  // User-related methods

  getUserDetails() {
    return this.http.get<{ data: User }>(environment.API_URL + 'api/v1/me');
  }

  getUsers() {
    return this.http.get<GetUsersResponseType>(environment.API_URL + 'api/v1/endusers');
  }

  getUsersMore(paramsValues: object) {
    let params = new HttpParams();

    if (paramsValues) {
      Object.keys(paramsValues).forEach((key) => {
        const val = paramsValues[key];
        val && (params = params.append(key, val));
      });
    }
    return this.http.get<GetUsersResponseType>(environment.API_URL + 'api/v1/endusers', { params });
  }

  createUser(payload: CreateUserRequestBody) {
    return this.http.post<any>(environment.API_URL + 'api/v1/signup', payload);
  }

  editUserDetails(id: number, data: any) {
    return this.http.put<any>(environment.API_URL + 'api/v1/me', data);
  }

  deleteUser(id: number, admin: boolean) {
    return this.http.delete<any>(environment.API_URL + `api/v1/${admin ? 'admins' : 'endusers'}/${id}`);
  }

  resetPassword(id: number, admin: boolean) {
    return this.http.patch<any>(environment.API_URL + `api/v1/${admin ? 'admins' : 'endusers'}/${id}`, {
      password: 'libyanexpress',
    });
  }

  changeUserStatus(id: number, status: string, admin: boolean) {
    return this.http.put<any>(environment.API_URL + `api/v1/${admin ? 'admins' : 'endusers'}/${id}/banning`, {
      status,
    });
  }

  getAdmins() {
    return this.http.get<GetAdminsResponseType>(environment.API_URL + 'api/v1/admins');
  }

  getAdminsMore(page: number) {
    return this.http.get<GetAdminsResponseType>(environment.API_URL + `api/v1/admins?page=${page}`);
  }

  createAdmin(payload: CreateAdminRequestBody) {
    return this.http.post<any>(environment.API_URL + 'api/v1/admins', payload);
  }
}
