import { AppState } from './root-store.module';
import { ActionReducerMap } from '@ngrx/store';

//-------------AUTH-------------
import { authReducer } from './auth/auth.reducer';
import { AuthEffects } from './auth/auth.effects';

//-------------USER-------------
import { userReducer } from './user/user.reducer';
import { UserEffects } from './user/user.effects';

//-------------SITES-------------
import { walletsReducer } from './wallets/wallets.reducer';
import { WalletsEffects } from './wallets/wallets.effects';

//-------------EXPENSES-------------
import { expenseReducer } from './expenses/expenses.reducer';
import { ExpenseEffects } from './expenses/expenses.effects';

//-------------TRANSFERS-------------
import { transferReducer } from './transfers/transfers.reducer';
import { TransferEffects } from './transfers/transfers.effects';

//-------------WITHDRAWALS-------------
import { withdrawalReducer } from './withdrawals/withdrawals.reducer';
import { WithdrawalEffects } from './withdrawals/withdrawals.effects';

//-------------DEPOSITS-------------
import { depositReducer } from './deposits/deposits.reducer';
import { DepositEffects } from './deposits/deposits.effects';

//-------------PURCHASES-------------
import { purchaseReducer } from './purchases/purchases.reducer';
import { PurchaseEffects } from './purchases/purchases.effects';

//-------------SHIPMENTS-------------
import { shipmentReducer } from './shipments/shipments.reducer';
import { ShipmentEffects } from './shipments/shipments.effects';

//-------------BOXES-------------
import { boxReducer } from './boxes/boxes.reducer';
import { BoxEffects } from './boxes/boxes.effects';

//-------------MISC-------------
import { MiscReducer } from './misc/misc.reducer';
import { MiscEffects } from './misc/misc.effects';

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  user: userReducer,
  wallets: walletsReducer,
  expenses: expenseReducer,
  transfers: transferReducer,
  withdrawals: withdrawalReducer,
  deposits: depositReducer,
  purchases: purchaseReducer,
  shipments: shipmentReducer,
  boxes: boxReducer,
  misc: MiscReducer,
};

export const effects: any[] = [
  AuthEffects,
  UserEffects,
  WalletsEffects,
  ExpenseEffects,
  TransferEffects,
  WithdrawalEffects,
  DepositEffects,
  PurchaseEffects,
  ShipmentEffects,
  BoxEffects,
  MiscEffects,
];
