import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyInputComponent),
  multi: true,
};

@Component({
  selector: 'bey-input',
  templateUrl: './bey-input.component.html',
  styleUrls: ['./bey-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})

/***
 *  Beyonic Input field used across the application
 */
export class BeyInputComponent implements ControlValueAccessor {
  /***
   *  Text for input field placeholder attribute
   */
  @Input()
  placeholder: string = '';

  /***
   *  Text for input field label
   */
  @Input()
  label: string = '';

  /***
   *  Input field name attribute
   */
  @Input()
  name: string = '';

  /***
   *  Input field ID attribute
   */
  @Input()
  id: string = '';

  /***
   *  Boolean flag to toggle hiding/showing error
   */
  @Input()
  displayError: boolean = false;

  /***
   * Field's validation error message
   */
  @Input()
  errorMessage: string = '';

  /***
   *  Hide the validation feedback message showing under the field
   */
  @Input()
  hideValidationFeedback: boolean = false;

  /****
   *  This includes all HTML default types like text | tel | email | password | etc.
   */
  @Input()
  type: 'text' | 'email' | 'password' | 'checkbox' | 'date' | 'number' | 'textarea' | 'amount' = 'text';

  /***
   *  Make the field readonly and un editable
   */
  @Input()
  readonly: boolean = false;

  /***
   *  determines the size of the input element
   */
  @Input()
  size: 'large' | 'small' | 'default' = 'default';

  /***
   *  if it's true then an 'X' sign will be displayed and it will clear the value of the input if it was clicked
   */
  @Input()
  clearable: boolean = false;

  /***
   *  Optional attribute to display for the user the characters limits for the input
   */
  @Input()
  charactersLimit?: number;

  /***
   *  Element direction
   */
  @Input()
  dir: 'ltr' | 'rtl' = 'rtl';

  /***
   *  Allow decimals in amount
   */
  @Input()
  allowDecimals: boolean = false;

  @Output()
  onEnterClick: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onClear: EventEmitter<boolean> = new EventEmitter();

  @Output()
  valueChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  private inputValue: any = '';
  private onTouched = () => {};
  private onChange = (value: any) => {};

  get value(): any {
    return this.inputValue;
  }

  set value(v: any) {
    if (v !== this.inputValue) {
      this.inputValue = v;
      this.onChange(v);
    }
  }

  onValueChange(e): void {
    this.valueChanged.emit(e.target.value);
  }

  onBlur() {
    this.onTouched();
  }

  onXSignClick() {
    this.value = '';
    this.onClear.emit(true);
  }

  enterClickHandler(): void {
    this.onEnterClick.emit(true);
  }

  //-----From ControlValueAccessor interface-----
  writeValue(value: any) {
    if (value !== this.inputValue) {
      this.inputValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  //---------------------------------------------
}
