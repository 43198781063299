import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { ShipmentsState } from './shipments.interfaces';

const selectShipment = (state: AppState) => {
  return state.shipments; // Assuming you have a 'shipments' property in your state
};

//---------1ST LEVEL SELECTORS---------
export const selectShipmentCountries = createSelector(
  selectShipment,
  (state: ShipmentsState) => state.shipmentCountries
);
export const selectShipmentDetails = createSelector(selectShipment, (state: ShipmentsState) => state.shipmentDetails);
export const selectShipments = createSelector(selectShipment, (state: ShipmentsState) => state.shipments);
export const selectShipmentsObject = createSelector(selectShipment, (state: ShipmentsState) => {
  return {
    results: state.shipments,
    currentPage: state['shipmentsMeta']?.['current_page'] | 1,
    total: state['shipmentsMeta']?.['total'] | 1,
  };
});

export const selectShipmentsMeta = createSelector(selectShipment, (state: ShipmentsState) => state.shipmentsMeta);

export const selectShipmentIsLoading = createSelector(selectShipment, (state: ShipmentsState) => state.isLoading);

export const selectShipmentErrorMessages = createSelector(
  selectShipment,
  (state: ShipmentsState) => state.errorMessages
);

//---------2ND LEVEL SELECTORS---------
export const selectGetMoreShipmentsNextUrlAndCurrentPage = createSelector(selectShipmentsMeta, (meta) => {
  return { url: meta?.['links']?.find((link) => link.label.includes('Next'))?.url, currentPage: meta?.current_page };
});

export const selectGetShipmentsIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['getShipments']
);
export const selectGetShipmentsErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['getShipments']
);

export const selectGetMoreShipmentsIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['getMoreShipments']
);
export const selectGetMoreShipmentsErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['getMoreShipments']
);

export const selectCreateShipmentIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['createShipment']
);
export const selectCreateShipmentErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['createShipment']
);

export const selectUpdateShipmentIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['updateShipment']
);
export const selectUpdateShipmentErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['updateShipment']
);

export const selectDeleteShipmentIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['deleteShipment']
);
export const selectDeleteShipmentErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['deleteShipment']
);

export const selectGetShipmentDetailsIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['getShipmentDetails']
);
export const selectGetShipmentDetailsErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['getShipmentDetails']
);

export const selectGetShipmentCountriesIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['getShipmentCountries']
);
export const selectGetShipmentCountriesErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['getShipmentCountries']
);

export const selectUpdateShipmentCountriesIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['updateShipmentCountries']
);
export const selectUpdateShipmentCountriesErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['updateShipmentCountries']
);

export const selectAddShipmentPhotoIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['addShipmentPhoto']
);
export const selectAddShipmentPhotoErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['addShipmentPhoto']
);

export const selectRemoveShipmentPhotoIsLoading = createSelector(
  selectShipmentIsLoading,
  (isLoading) => isLoading['removeShipmentPhoto']
);
export const selectRemoveShipmentPhotoErrorMessage = createSelector(
  selectShipmentErrorMessages,
  (errorMessage) => errorMessage['removeShipmentPhoto']
);
