import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { DepositService } from './deposits.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getDepositsStart,
  getDepositsSuccess,
  getDepositsFailure,
  getMoreDepositsStart,
  getMoreDepositsFailure,
  getMoreDepositsSuccess,
  createDepositStart,
  createDepositSuccess,
  createDepositFailure,
  deleteDepositStart,
  deleteDepositSuccess,
  deleteDepositFailure,
  updateDepositStart,
  updateDepositSuccess,
  updateDepositFailure,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class DepositEffects {
  constructor(
    private depositService: DepositService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  getDepositsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDepositsStart),
      switchMap(() =>
        this.depositService.getDeposits().pipe(
          mergeMap((data) => [getDepositsSuccess({ payload: data })]),
          catchError((error) => observableOf(getDepositsFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreDepositsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreDepositsStart),
      switchMap(({ payload: { page } }) =>
        this.depositService.getMoreDeposits(page).pipe(
          mergeMap((data) => [getMoreDepositsSuccess({ payload: data })]),
          catchError((error) => observableOf(getMoreDepositsFailure({ error: error?.message })))
        )
      )
    )
  );

  createDepositEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDepositStart),
      switchMap(({ payload }) =>
        this.depositService.createDeposit(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createDepositSuccess(), getDepositsStart()];
          }),
          catchError((error) => observableOf(createDepositFailure({ error: error?.message })))
        )
      )
    )
  );

  updateDepositEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDepositStart),
      switchMap(({ payload, id }) =>
        this.depositService.updateDeposit(payload, id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [updateDepositSuccess(), getDepositsStart()];
          }),
          catchError((error) => observableOf(updateDepositFailure({ error: error?.message })))
        )
      )
    )
  );

  deleteDepositEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDepositStart),
      switchMap(({ payload: { id } }) =>
        this.depositService.deleteDeposit(id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [deleteDepositSuccess(), getDepositsStart()];
          }),
          catchError((error) => observableOf(deleteDepositFailure({ error: error?.message })))
        )
      )
    )
  );
}
