import { Component, Input, OnInit } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';

@Component({
  selector: 'bey-country-flag',
  templateUrl: './bey-country-flag.component.html',
  styleUrls: ['./bey-country-flag.component.scss'],
})
export class BeyCountryFlagComponent implements OnInit {
  constructor() {}

  /***
   *  Text for input field placeholder attribute
   */
  @Input()
  phoneNumber: string | any;

  countryCode: string = null;

  ngOnInit(): void {
    if (this.phoneNumber) {
      const { country } = parsePhoneNumber(this.phoneNumber);

      if (country) {
        this.countryCode = country.toLowerCase();
      }
    }
  }
}
