<div class="menu" [ngClass]="{ 'multi-options': multipleSelection, opened: opened }">
  <ul class="list">
    <li
      *ngFor="let option of options"
      [ngClass]="checkIfOptionIsSelected(option.value) && 'selected'"
      (click)="multipleSelection ? null : onSelect(option)">
      <div (click)="multipleSelection ? onSelect(option) : null" class="check-box">
        <img src="assets/images/icons/white-check-mark.png" />
      </div>
      <span class="label">{{ option.label }}</span>
    </li>
  </ul>
</div>
