import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { WalletsService } from './wallets.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getUserWalletsFailure,
  getUserWalletsStart,
  getUserWalletsSuccess,
  getUserWalletOperationsFailure,
  getUserWalletOperationsStart,
  getUserWalletOperationsSuccess,
  getMoreUserWalletOperationsFailure,
  getMoreUserWalletOperationsStart,
  getMoreUserWalletOperationsSuccess,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class WalletsEffects {
  constructor(
    private walletsService: WalletsService,
    private actions$: Actions,
    private router: Router,
    private dialog: MatDialog
  ) {}

  getUserWalletsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserWalletsStart),
      switchMap(() =>
        this.walletsService.getUserWallets().pipe(
          mergeMap((data) => [getUserWalletsSuccess({ payload: data })]),
          catchError((error) => observableOf(getUserWalletsFailure({ error: error?.message })))
        )
      )
    )
  );

  getUserWalletOperationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserWalletOperationsStart),
      switchMap(({ payload: { wallet_id } }) =>
        this.walletsService.getUserWalletOperations(wallet_id).pipe(
          mergeMap((data) => [getUserWalletOperationsSuccess({ payload: data })]),
          catchError((error) => observableOf(getUserWalletOperationsFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreUserWalletOperationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreUserWalletOperationsStart),
      switchMap(({ payload: { wallet_id, page } }) =>
        this.walletsService.getMoreUserWalletOperations(wallet_id, page).pipe(
          mergeMap((data) => [getMoreUserWalletOperationsSuccess({ payload: data })]),
          catchError((error) => observableOf(getMoreUserWalletOperationsFailure({ error: error?.message })))
        )
      )
    )
  );
}
