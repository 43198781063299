export const shipmentsTypes = {
  CREATE_SHIPMENT_START: 'CREATE_SHIPMENT_START',
  CREATE_SHIPMENT_SUCCESS: 'CREATE_SHIPMENT_SUCCESS',
  CREATE_SHIPMENT_FAILURE: 'CREATE_SHIPMENT_FAILURE',
  EDIT_SHIPMENT_START: 'EDIT_SHIPMENT_START',
  EDIT_SHIPMENT_SUCCESS: 'EDIT_SHIPMENT_SUCCESS',
  EDIT_SHIPMENT_FAILURE: 'EDIT_SHIPMENT_FAILURE',
  DELETE_SHIPMENT_START: 'DELETE_SHIPMENT_START',
  DELETE_SHIPMENT_SUCCESS: 'DELETE_SHIPMENT_SUCCESS',
  DELETE_SHIPMENT_FAILURE: 'DELETE_SHIPMENT_FAILURE',
  GET_SHIPMENT_COUNTRIES_START: 'GET_SHIPMENT_COUNTRIES_START',
  GET_SHIPMENT_COUNTRIES_SUCCESS: 'GET_SHIPMENT_COUNTRIES_SUCCESS',
  GET_SHIPMENT_COUNTRIES_FAILURE: 'GET_SHIPMENT_COUNTRIES_FAILURE',
  UPDATE_SHIPMENT_COUNTRIES_START: 'UPDATE_SHIPMENT_COUNTRIES_START',
  UPDATE_SHIPMENT_COUNTRIES_SUCCESS: 'UPDATE_SHIPMENT_COUNTRIES_SUCCESS',
  UPDATE_SHIPMENT_COUNTRIES_FAILURE: 'UPDATE_SHIPMENT_COUNTRIES_FAILURE',
  GET_SHIPMENTS_START: 'GET_SHIPMENTS_START',
  GET_SHIPMENTS_SUCCESS: 'GET_SHIPMENTS_SUCCESS',
  GET_SHIPMENTS_FAILURE: 'GET_SHIPMENTS_FAILURE',
  GET_MORE_SHIPMENTS_START: 'GET_MORE_SHIPMENTS_START',
  GET_MORE_SHIPMENTS_SUCCESS: 'GET_MORE_SHIPMENTS_SUCCESS',
  GET_MORE_SHIPMENTS_FAILURE: 'GET_MORE_SHIPMENTS_FAILURE',
  GET_SHIPMENT_DETAILS_START: 'GET_SHIPMENT_DETAILS_START',
  GET_SHIPMENT_DETAILS_SUCCESS: 'GET_SHIPMENT_DETAILS_SUCCESS',
  GET_SHIPMENT_DETAILS_FAILURE: 'GET_SHIPMENT_DETAILS_FAILURE',
  ADD_SHIPMENT_PHOTO_START: 'ADD_SHIPMENT_PHOTO_START',
  ADD_SHIPMENT_PHOTO_SUCCESS: 'ADD_SHIPMENT_PHOTO_SUCCESS',
  ADD_SHIPMENT_PHOTO_FAILURE: 'ADD_SHIPMENT_PHOTO_FAILURE',
  REMOVE_SHIPMENT_PHOTO_START: 'REMOVE_SHIPMENT_PHOTO_START',
  REMOVE_SHIPMENT_PHOTO_SUCCESS: 'REMOVE_SHIPMENT_PHOTO_SUCCESS',
  REMOVE_SHIPMENT_PHOTO_FAILURE: 'REMOVE_SHIPMENT_PHOTO_FAILURE',
};
