import { Injectable, OnDestroy } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '../../../ngrx/selectors';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, OnDestroy {
  private subs$ = new Subscription();

  constructor(private _router: Router, private store$: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = null;
    this.subs$.add(this.store$.select(selectCurrentUser).subscribe((user) => (currentUser = user)));

    if (currentUser) {
      // check if route is restricted by role
      // if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
      //   this._router.navigate(['/pages/miscellaneous/not-authorized']);
      //   return false;
      // }

      // authorised so return true
      return true;
    }

    this._router.navigate(['/login']);
    return false;
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
