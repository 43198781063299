import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { WalletsState } from './wallets.interfaces';

const selectWallets = (state: AppState) => {
  return state.wallets;
};

//---------1ST LEVEL SELECTORS---------
export const selectUserWallets = createSelector(selectWallets, (state: WalletsState) => state.userWallets);
export const selectUserWalletOperations = createSelector(
  selectWallets,
  (state: WalletsState) => state.userWalletOperations
);
export const selectUserWalletOperationsObject = createSelector(selectWallets, (state: WalletsState) => {
  return {
    results: state.userWalletOperations,
    currentPage: state['userWalletsMeta']?.['current_page'] | 1,
    total: state['userWalletsMeta']?.['total'] | 1,
  };
});

export const selectUserWalletOperationsMeta = createSelector(
  selectWallets,
  (state: WalletsState) => state.userWalletOperationsMeta
);

export const selectWalletsIsLoading = createSelector(selectWallets, (state: WalletsState) => state.isLoading);

export const selectWalletsErrorMessages = createSelector(selectWallets, (state: WalletsState) => state.errorMessages);
//---------2ND LEVEL SELECTORS---------
export const selectGetUserWalletsIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getUserWallets']
);
export const selectGetUserWalletsErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessage) => errorMessage['getUserWallets']
);

export const selectGetUserWalletOperationsIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getUserWalletOperations']
);
export const selectGetUserWalletOperationsErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessage) => errorMessage['getUserWalletOperations']
);

export const selectGetMoreUserWalletOperationsIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getMoreUserWalletOperations']
);
export const selectGetMoreUserWalletOperationsErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessage) => errorMessage['getMoreUserWalletOperations']
);
