import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { GetWithdrawalsResponseType } from '../interfaces'; // Adjust the import based on your actual withdrawal interfaces

@Injectable({
  providedIn: 'root',
})
export class WithdrawalService {
  constructor(private http: HttpClient) {}

  getWithdrawals() {
    return this.http.get<GetWithdrawalsResponseType>(environment.API_URL + 'api/v1/withdraws');
  }

  getMoreWithdrawals(page: number) {
    return this.http.get<GetWithdrawalsResponseType>(environment.API_URL + `api/v1/withdraws?page=${page}`);
  }

  createWithdrawal(payload: any) {
    return this.http.post<any>(environment.API_URL + 'api/v1/withdraws', payload);
  }

  updateWithdrawal(payload: any, id: number) {
    return this.http.put<any>(environment.API_URL + 'api/v1/withdraws/' + id, payload);
  }

  deleteWithdrawal(id: number) {
    return this.http.delete<any>(environment.API_URL + `api/v1/withdraws/${id}`);
  }
}
