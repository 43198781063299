import { createAction, props } from '@ngrx/store';
import {
  CreateUserRequestBody,
  GetUsersResponseType,
  Admin,
  GetAdminsResponseType,
  CreateAdminRequestBody,
  User,
  ErrorType,
  EditUserDetailsRequestBody,
} from '../interfaces';
import { userTypes } from './user.types';

//------------CREATE USER ------------
export const createUserStart = createAction(userTypes.CREATE_USER_START, props<{ payload: CreateUserRequestBody }>());
export const createUserSuccess = createAction(userTypes.CREATE_USER_SUCCESS);
export const createUserFailure = createAction(userTypes.CREATE_USER_FAILURE, props<ErrorType>());

//------------GET USER DETAILS------------
export const getUserDetailsStart = createAction(userTypes.GET_USER_DETAILS_START);
export const getUserDetailsSuccess = createAction(userTypes.GET_USER_DETAILS_SUCCESS, props<{ payload: User }>());
export const getUserDetailsFailure = createAction(userTypes.GET_USER_DETAILS_FAILURE, props<ErrorType>());

//------------GET USERS------------
export const getUsersStart = createAction(userTypes.GET_USERS_START);
export const getUsersSuccess = createAction(userTypes.GET_USERS_SUCCESS, props<{ payload: GetUsersResponseType }>());
export const getUsersFailure = createAction(userTypes.GET_USERS_FAILURE, props<ErrorType>());

//------------GET USERS MORE------------
export const getUsersMoreStart = createAction(userTypes.GET_USERS_MORE_START, props<{ payload: object }>());
export const getUsersMoreSuccess = createAction(
  userTypes.GET_USERS_MORE_SUCCESS,
  props<{ payload: GetUsersResponseType; append?: boolean }>()
);
export const getUsersMoreFailure = createAction(userTypes.GET_USERS_MORE_FAILURE, props<ErrorType>());

//------------EDIT USER DETAILS------------
export const editUserDetailsStart = createAction(
  userTypes.EDIT_USER_DETAILS_START,
  props<{ payload: EditUserDetailsRequestBody; callBack?: Function }>()
);
export const editUserDetailsSuccess = createAction(userTypes.EDIT_USER_DETAILS_SUCCESS);
export const editUserDetailsFailure = createAction(userTypes.EDIT_USER_DETAILS_FAILURE, props<ErrorType>());

//------------DELETE USER------------
export const deleteUserStart = createAction(
  userTypes.DELETE_USER_START,
  props<{ payload: { id: number }; admin?: boolean }>()
);
export const deleteUserSuccess = createAction(userTypes.DELETE_USER_SUCCESS);
export const deleteUserFailure = createAction(userTypes.DELETE_USER_FAILURE, props<ErrorType>());

//------------CHANGE USER STATUS------------
export const changeUserStatusStart = createAction(
  userTypes.CHANGE_USER_STATUS_START,
  props<{ payload: { id: number; status: 'active' | 'banned' }; admin?: boolean }>()
);
export const changeUserStatusSuccess = createAction(userTypes.CHANGE_USER_STATUS_SUCCESS);
export const changeUserStatusFailure = createAction(userTypes.CHANGE_USER_STATUS_FAILURE, props<ErrorType>());

//------------CREATE ADMIN ------------
export const createAdminStart = createAction(
  userTypes.CREATE_ADMIN_START,
  props<{ payload: CreateAdminRequestBody }>()
);
export const createAdminSuccess = createAction(userTypes.CREATE_ADMIN_SUCCESS);
export const createAdminFailure = createAction(userTypes.CREATE_ADMIN_FAILURE, props<ErrorType>());

//------------GET ADMINS------------
export const getAdminsStart = createAction(userTypes.GET_ADMINS_START);
export const getAdminsSuccess = createAction(userTypes.GET_ADMINS_SUCCESS, props<{ payload: GetAdminsResponseType }>());
export const getAdminsFailure = createAction(userTypes.GET_ADMINS_FAILURE, props<ErrorType>());

//------------GET ADMINS MORE------------
export const getAdminsMoreStart = createAction(userTypes.GET_ADMINS_MORE_START, props<{ payload: { page: number } }>());
export const getAdminsMoreSuccess = createAction(
  userTypes.GET_ADMINS_MORE_SUCCESS,
  props<{ payload: GetAdminsResponseType }>()
);
export const getAdminsMoreFailure = createAction(userTypes.GET_ADMINS_MORE_FAILURE, props<ErrorType>());
