import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[outsideClickListener]',
})
export class OutsideClickListenerDirective {
  @Output() outsideClicked: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:click', ['$event']) clickout(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.outsideClicked.emit();
    }
  }

  constructor(private _eref: ElementRef) {}
}
