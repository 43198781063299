export const badgeColors = {
  active: 'success',
  inactive: 'danger',
};
export const OPERATING_COUNTRIES: Array<{ label: string; value: string; isoCode: string }> = [
  {
    label: 'Uganda',
    value: '+256',
    isoCode: 'ug',
  },
];

export const ENV_NAMES = {
  STAGING: 'STAGING',
  PROD: 'PROD',
  DEV: 'DEV',
};

export const weekDays = ['إث', 'ثلاث', 'أربع', 'خم', 'جمع', 'سبت', 'أحد'];
