import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getUserDetailsStart,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure,
  getUsersMoreStart,
  getUsersMoreFailure,
  getUsersMoreSuccess,
  createUserStart,
  createUserSuccess,
  createUserFailure,
  // Add user actions
  editUserDetailsStart,
  editUserDetailsSuccess,
  editUserDetailsFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  changeUserStatusStart,
  changeUserStatusSuccess,
  changeUserStatusFailure,
  // Add admin actions
  getAdminsStart,
  getAdminsSuccess,
  getAdminsFailure,
  getAdminsMoreStart,
  getAdminsMoreFailure,
  getAdminsMoreSuccess,
  createAdminStart,
  createAdminSuccess,
  createAdminFailure,
  loginStart,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class UserEffects {
  constructor(
    private userService: UserService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  getUserDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetailsStart),
      switchMap(() =>
        this.userService.getUserDetails().pipe(
          mergeMap(({ data }) => [getUserDetailsSuccess({ payload: data })]),
          catchError((error) => observableOf(getUserDetailsFailure({ error: error?.message })))
        )
      )
    )
  );

  getUsersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsersStart),
      switchMap(() =>
        this.userService.getUsers().pipe(
          mergeMap((data) => [getUsersSuccess({ payload: data })]),
          catchError((error) => observableOf(getUsersFailure({ error: error?.message })))
        )
      )
    )
  );

  getUsersMoreEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsersMoreStart),
      switchMap(({ payload }) =>
        this.userService.getUsersMore(payload).pipe(
          mergeMap((data) => [getUsersMoreSuccess({ payload: data, append: payload['append'] })]),
          catchError((error) => observableOf(getUsersMoreFailure({ error: error?.message })))
        )
      )
    )
  );

  createUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserStart),
      switchMap(({ payload }) =>
        this.userService.createUser(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            const { email, password: pin } = payload;
            return [createUserSuccess(), loginStart({ payload: { email, pin } })];
          }),
          catchError((error) => {
            this.toast.open(
              error.error.first === 'This field has already been taken.'
                ? 'هذا البريد الإلكتروني مستخدم بالفعل'
                : 'حدث خطأ ما، يرجى إعادة المحاولة',
              'error'
            );
            return observableOf(createUserFailure({ error: error?.message }));
          })
        )
      )
    )
  );

  editUserDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editUserDetailsStart),
      switchMap(({ payload: { id, data }, callBack }) =>
        this.userService.editUserDetails(id, data).pipe(
          mergeMap(() => {
            callBack();
            return [editUserDetailsSuccess(), getUserDetailsStart()];
          }),
          catchError((error) => observableOf(editUserDetailsFailure({ error: error?.message })))
        )
      )
    )
  );

  deleteUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserStart),
      switchMap(({ payload: { id }, admin }) =>
        this.userService.deleteUser(id, admin).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            this.router.navigate(['/']);
            return [deleteUserSuccess()];
          }),
          catchError((error) => observableOf(deleteUserFailure({ error: error?.message })))
        )
      )
    )
  );

  changeUserStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeUserStatusStart),
      switchMap(({ payload: { id, status }, admin }) =>
        this.userService.changeUserStatus(id, status, admin).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [changeUserStatusSuccess(), getUserDetailsStart()];
          }),
          catchError((error) => observableOf(changeUserStatusFailure({ error: error?.message })))
        )
      )
    )
  );

  // Admin effects

  getAdminsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminsStart),
      switchMap(() =>
        this.userService.getAdmins().pipe(
          mergeMap((data) => [getAdminsSuccess({ payload: data })]),
          catchError((error) => observableOf(getAdminsFailure({ error: error?.message })))
        )
      )
    )
  );

  getAdminsMoreEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminsMoreStart),
      switchMap(({ payload: { page } }) =>
        this.userService.getAdminsMore(page).pipe(
          mergeMap((data) => [getAdminsMoreSuccess({ payload: data })]),
          catchError((error) => observableOf(getAdminsMoreFailure({ error: error?.message })))
        )
      )
    )
  );

  createAdminEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAdminStart),
      switchMap(({ payload }) =>
        this.userService.createAdmin(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createAdminSuccess(), getAdminsStart()];
          }),
          catchError((error) => observableOf(createAdminFailure({ error: error?.message })))
        )
      )
    )
  );
}
