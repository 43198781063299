import { createReducer, on } from '@ngrx/store';
import { ExpensesState } from './expenses.interfaces';
import {
  getExpensesStart,
  getExpensesSuccess,
  getExpensesFailure,
  getMoreExpensesStart,
  getMoreExpensesSuccess,
  getMoreExpensesFailure,
  createExpenseStart,
  createExpenseSuccess,
  createExpenseFailure,
  deleteExpenseStart,
  deleteExpenseSuccess,
  deleteExpenseFailure,
  updateExpenseStart,
  updateExpenseSuccess,
  updateExpenseFailure,
} from '../actions';

const initialState: ExpensesState = {
  expenses: null,
  expensesMeta: null,
  isLoading: {
    getExpenses: false,
    getMoreExpenses: false,
    createExpense: false,
    deleteExpense: false,
    updateExpense: false,
  },
  errorMessages: {
    getExpenses: '',
    getMoreExpenses: '',
    createExpense: '',
    deleteExpense: '',
    updateExpense: '',
  },
};

export const expenseReducer = createReducer(
  initialState,
  //----------START----------
  on(getExpensesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getExpenses: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getExpenses: '',
    },
  })),
  on(getMoreExpensesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreExpenses: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreExpenses: '',
    },
  })),
  on(createExpenseStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createExpense: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createExpense: '',
    },
  })),
  on(updateExpenseStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateExpense: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateExpense: '',
    },
  })),
  on(deleteExpenseStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteExpense: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteExpense: '',
    },
  })),
  //----------SUCCESS----------
  on(getExpensesSuccess, (state, { payload }) => ({
    ...state,
    expenses: payload['data'],
    expensesMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getExpenses: false,
    },
  })),
  on(getMoreExpensesSuccess, (state, { payload }) => ({
    ...state,
    expenses: payload['data'],
    expensesMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreExpenses: false,
    },
  })),
  on(createExpenseSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createExpense: false,
    },
  })),
  on(updateExpenseSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateExpense: false,
    },
  })),
  on(deleteExpenseSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteExpense: false,
    },
  })),
  //----------FAILURE----------
  on(getExpensesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getExpenses: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getExpenses: error,
    },
  })),
  on(getMoreExpensesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreExpenses: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreExpenses: error,
    },
  })),
  on(createExpenseFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createExpense: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createExpense: error,
    },
  })),
  on(updateExpenseFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateExpense: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateExpense: error,
    },
  })),
  on(deleteExpenseFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteExpense: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteExpense: error,
    },
  }))
);
