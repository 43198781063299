import { createReducer, on } from '@ngrx/store';
import { ShipmentsState } from './shipments.interfaces';
import {
  getShipmentsStart,
  getShipmentsSuccess,
  getShipmentsFailure,
  getMoreShipmentsStart,
  getMoreShipmentsSuccess,
  getMoreShipmentsFailure,
  createShipmentStart,
  createShipmentSuccess,
  createShipmentFailure,
  deleteShipmentStart,
  deleteShipmentSuccess,
  deleteShipmentFailure,
  updateShipmentStart,
  updateShipmentSuccess,
  updateShipmentFailure,
  getShipmentDetailsStart,
  getShipmentDetailsSuccess,
  getShipmentDetailsFailure,
  getShipmentCountriesStart,
  getShipmentCountriesSuccess,
  getShipmentCountriesFailure,
  addShipmentPhotoStart,
  addShipmentPhotoSuccess,
  addShipmentPhotoFailure,
  removeShipmentPhotoStart,
  removeShipmentPhotoSuccess,
  removeShipmentPhotoFailure,
  updateShipmentCountriesStart,
  updateShipmentCountriesSuccess,
  updateShipmentCountriesFailure,
} from '../actions';

const initialState: ShipmentsState = {
  shipmentDetails: null,
  shipments: null,
  shipmentsMeta: null,
  shipmentCountries: null,
  isLoading: {
    getShipments: false,
    getMoreShipments: false,
    createShipment: false,
    deleteShipment: false,
    updateShipment: false,
    getShipmentDetails: false,
    getShipmentCountries: false,
    updateShipmentCountries: false,
    addShipmentPhoto: false,
    removeShipmentPhoto: false,
  },
  errorMessages: {
    getShipments: '',
    getMoreShipments: '',
    createShipment: '',
    deleteShipment: '',
    updateShipment: '',
    getShipmentDetails: '',
    getShipmentCountries: '',
    updateShipmentCountries: '',
    addShipmentPhoto: '',
    removeShipmentPhoto: '',
  },
};

export const shipmentReducer = createReducer(
  initialState,
  //----------START----------
  on(addShipmentPhotoStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      addShipmentPhoto: true,
    },
    errorMessages: {
      ...state.errorMessages,
      addShipmentPhoto: '',
    },
  })),
  on(removeShipmentPhotoStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      removeShipmentPhoto: true,
    },
    errorMessages: {
      ...state.errorMessages,
      removeShipmentPhoto: '',
    },
  })),
  on(getShipmentCountriesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getShipmentCountries: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipmentCountries: '',
    },
  })),
  on(updateShipmentCountriesStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateShipmentCountries: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateShipmentCountries: '',
    },
  })),
  on(getShipmentsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getShipments: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipments: '',
    },
  })),
  on(getShipmentDetailsStart, (state) => ({
    ...state,
    shipmentDetails: null,
    isLoading: {
      ...state.isLoading,
      getShipmentDetails: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipmentDetails: '',
    },
  })),
  on(getMoreShipmentsStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreShipments: true,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreShipments: '',
    },
  })),
  on(createShipmentStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createShipment: true,
    },
    errorMessages: {
      ...state.errorMessages,
      createShipment: '',
    },
  })),
  on(updateShipmentStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateShipment: true,
    },
    errorMessages: {
      ...state.errorMessages,
      updateShipment: '',
    },
  })),
  on(deleteShipmentStart, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteShipment: true,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteShipment: '',
    },
  })),
  //----------SUCCESS----------
  on(addShipmentPhotoSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      addShipmentPhoto: false,
    },
    errorMessages: {
      ...state.errorMessages,
      addShipmentPhoto: '',
    },
  })),
  on(removeShipmentPhotoSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      removeShipmentPhoto: false,
    },
    errorMessages: {
      ...state.errorMessages,
      removeShipmentPhoto: '',
    },
  })),
  on(getShipmentCountriesSuccess, (state, { payload }) => ({
    ...state,
    shipmentCountries: payload['data'],
    isLoading: {
      ...state.isLoading,
      getShipmentCountries: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipmentCountries: '',
    },
  })),
  on(updateShipmentCountriesSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateShipmentCountries: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateShipmentCountries: '',
    },
  })),
  on(getShipmentsSuccess, (state, { payload }) => ({
    ...state,
    shipments: payload['data'],
    shipmentsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getShipments: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipments: '',
      getMoreShipments: '',
    },
  })),
  on(getMoreShipmentsSuccess, (state, { payload, append }) => ({
    ...state,
    shipments: append ? [...state.shipments, ...payload['data']] : payload['data'],
    shipmentsMeta: payload['meta'],
    isLoading: {
      ...state.isLoading,
      getMoreShipments: false,
    },
  })),
  on(getShipmentDetailsSuccess, (state, { payload }) => ({
    ...state,
    shipmentDetails: payload,
    isLoading: {
      ...state.isLoading,
      getShipmentDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipmentDetails: '',
    },
  })),
  on(createShipmentSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createShipment: false,
    },
  })),
  on(updateShipmentSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateShipment: false,
    },
  })),
  on(deleteShipmentSuccess, (state) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteShipment: false,
    },
  })),
  //----------FAILURE----------
  on(addShipmentPhotoFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      addShipmentPhoto: false,
    },
    errorMessages: {
      ...state.errorMessages,
      addShipmentPhoto: error,
    },
  })),
  on(removeShipmentPhotoFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      removeShipmentPhoto: false,
    },
    errorMessages: {
      ...state.errorMessages,
      removeShipmentPhoto: error,
    },
  })),
  on(getShipmentCountriesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getShipmentCountries: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipmentCountries: error,
    },
  })),
  on(updateShipmentCountriesFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateShipmentCountries: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateShipmentCountries: error,
    },
  })),
  on(getShipmentsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getShipments: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipments: error,
    },
  })),
  on(getMoreShipmentsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getMoreShipments: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getMoreShipments: error,
    },
  })),
  on(createShipmentFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createShipment: false,
    },
    errorMessages: {
      ...state.errorMessages,
      createShipment: error,
    },
  })),
  on(updateShipmentFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      updateShipment: false,
    },
    errorMessages: {
      ...state.errorMessages,
      updateShipment: error,
    },
  })),
  on(deleteShipmentFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      deleteShipment: false,
    },
    errorMessages: {
      ...state.errorMessages,
      deleteShipment: error,
    },
  })),
  on(getShipmentDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getShipmentDetails: false,
    },
    errorMessages: {
      ...state.errorMessages,
      getShipmentDetails: error,
    },
  }))
);
