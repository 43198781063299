import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { PurchasesState } from './purchases.interfaces';

const selectPurchase = (state: AppState) => {
  return state.purchases;
};

//---------1ST LEVEL SELECTORS---------
export const selectPurchaseDetails = createSelector(selectPurchase, (state: PurchasesState) => state.purchaseDetails);
export const selectPurchases = createSelector(selectPurchase, (state: PurchasesState) => state.purchases);
export const selectPurchasesObject = createSelector(selectPurchase, (state: PurchasesState) => {
  return {
    results: state.purchases,
    currentPage: state['purchasesMeta']?.['current_page'] | 1,
    total: state['purchasesMeta']?.['total'] | 1,
  };
});

export const selectPurchasesMeta = createSelector(selectPurchase, (state: PurchasesState) => state.purchasesMeta);

export const selectPurchaseIsLoading = createSelector(selectPurchase, (state: PurchasesState) => state.isLoading);

export const selectPurchaseErrorMessages = createSelector(
  selectPurchase,
  (state: PurchasesState) => state.errorMessages
);

//---------2ND LEVEL SELECTORS---------
export const selectGetMorePurchasesNextUrlAndCurrentPage = createSelector(selectPurchasesMeta, (meta) => {
  return { url: meta?.['links']?.find((link) => link.label.includes('Next'))?.url, currentPage: meta?.current_page };
});

export const selectGetPurchasesIsLoading = createSelector(
  selectPurchaseIsLoading,
  (isLoading) => isLoading['getPurchases']
);
export const selectGetPurchasesErrorMessage = createSelector(
  selectPurchaseErrorMessages,
  (errorMessage) => errorMessage['getPurchases']
);

export const selectGetMorePurchasesIsLoading = createSelector(
  selectPurchaseIsLoading,
  (isLoading) => isLoading['getMorePurchases']
);
export const selectGetMorePurchasesErrorMessage = createSelector(
  selectPurchaseErrorMessages,
  (errorMessage) => errorMessage['getMorePurchases']
);

export const selectCreatePurchaseIsLoading = createSelector(
  selectPurchaseIsLoading,
  (isLoading) => isLoading['createPurchase']
);
export const selectCreatePurchaseErrorMessage = createSelector(
  selectPurchaseErrorMessages,
  (errorMessage) => errorMessage['createPurchase']
);

export const selectUpdatePurchaseIsLoading = createSelector(
  selectPurchaseIsLoading,
  (isLoading) => isLoading['updatePurchase']
);
export const selectUpdatePurchaseErrorMessage = createSelector(
  selectPurchaseErrorMessages,
  (errorMessage) => errorMessage['updatePurchase']
);

export const selectDeletePurchaseIsLoading = createSelector(
  selectPurchaseIsLoading,
  (isLoading) => isLoading['deletePurchase']
);
export const selectDeletePurchaseErrorMessage = createSelector(
  selectPurchaseErrorMessages,
  (errorMessage) => errorMessage['deletePurchase']
);

export const selectGetPurchaseDetailsIsLoading = createSelector(
  selectPurchaseIsLoading,
  (isLoading) => isLoading['getPurchaseDetails']
);
export const selectGetPurchaseDetailsErrorMessage = createSelector(
  selectPurchaseErrorMessages,
  (errorMessage) => errorMessage['getPurchaseDetails']
);
