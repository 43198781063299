import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { DepositsState } from './deposits.interfaces';

const selectDeposit = (state: AppState) => {
  return state.deposits;
};

//---------1ST LEVEL SELECTORS---------
export const selectDeposits = createSelector(selectDeposit, (state: DepositsState) => state.deposits);

export const selectDepositsObject = createSelector(selectDeposit, (state: DepositsState) => {
  return {
    results: state.deposits,
    currentPage: state['depositsMeta']?.['current_page'] | 1,
    total: state['depositsMeta']?.['total'] | 1,
  };
});

export const selectDepositsMeta = createSelector(selectDeposit, (state: DepositsState) => state.depositsMeta);

export const selectDepositIsLoading = createSelector(selectDeposit, (state: DepositsState) => state.isLoading);

export const selectDepositErrorMessages = createSelector(selectDeposit, (state: DepositsState) => state.errorMessages);

//---------2ND LEVEL SELECTORS---------
export const selectGetDepositsIsLoading = createSelector(
  selectDepositIsLoading,
  (isLoading) => isLoading['getDeposits']
);
export const selectGetDepositsErrorMessage = createSelector(
  selectDepositErrorMessages,
  (errorMessage) => errorMessage['getDeposits']
);

export const selectGetMoreDepositsIsLoading = createSelector(
  selectDepositIsLoading,
  (isLoading) => isLoading['getMoreDeposits']
);
export const selectGetMoreDepositsErrorMessage = createSelector(
  selectDepositErrorMessages,
  (errorMessage) => errorMessage['getMoreDeposits']
);

export const selectCreateDepositIsLoading = createSelector(
  selectDepositIsLoading,
  (isLoading) => isLoading['createDeposit']
);
export const selectCreateDepositErrorMessage = createSelector(
  selectDepositErrorMessages,
  (errorMessage) => errorMessage['createDeposit']
);

export const selectDeleteDepositIsLoading = createSelector(
  selectDepositIsLoading,
  (isLoading) => isLoading['deleteDeposit']
);
export const selectDeleteDepositErrorMessage = createSelector(
  selectDepositErrorMessages,
  (errorMessage) => errorMessage['deleteDeposit']
);

export const selectUpdateDepositIsLoading = createSelector(
  selectDepositIsLoading,
  (isLoading) => isLoading['updateDeposit']
);
export const selectUpdateDepositErrorMessage = createSelector(
  selectDepositErrorMessages,
  (errorMessage) => errorMessage['updateDeposit']
);
