<div outsideClickListener (outsideClicked)="closeMenu()" [ngClass]="{ opened }" class="bey-select">
  <label style="color: #6e6b7b; font-size: 12px; line-height: 17.4px; width: 100%; text-align: right">{{
    label || '&nbsp;'
  }}</label>
  <div dir="rtl" [ngClass]="{ disabled: disabled || (isLoading | async) }" class="button" (click)="onClick()">
    <div class="placeholder" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
      <span *ngIf="!isMulti; else multi">{{
        name === 'code'
          ? selectedOption?.value?.replace('+', '') + '+'
          : selectedOption?.label
          ? selectedOption?.label
          : placeholder
      }}</span>
      <ng-template #multi>
        <span>قمت باختيار {{ selectedOptions?.length || 0 }} من العناصر</span>
      </ng-template>
    </div>
    <div style="display: flex; align-items: center">
      <div class="spinner-border" *ngIf="isLoading | async">&nbsp;</div>
      <img class="icon icon-chevron_down" src="assets/images/icons/chevron-down-grey.png" />
    </div>
  </div>

  <div class="menu" [ngClass]="(search?.isLoading | async) && 'menu-loading'">
    <bey-input
      *ngIf="syncSearch"
      class="search-bar"
      [(ngModel)]="syncSearchBar"
      (ngModelChange)="onSearchBarChange($event)"
      [hideValidationFeedback]="true"
      placeholder="بحث"></bey-input>
    <bey-input
      *ngIf="search"
      class="search-bar"
      [(ngModel)]="searchBar"
      (ngModelChange)="onSearchBarChange($event)"
      [hideValidationFeedback]="true"
      [placeholder]="search?.placeholder"
      (onEnterClick)="onSearchFieldEnterClick()"></bey-input>
    <ul *ngIf="options?.length; else noResults">
      <li
        *ngFor="let option of syncSearchBar ? filteredOptions : options; let last = last"
        [ngClass]="(!isMulti ? option.value === selectedOption?.value : value?.includes(option.value)) && 'selected'"
        (click)="onSelect(option)">
        <span class="label" style="text-align: right">{{ option.label }}</span>
        <div *ngIf="!(loadOnScroll?.isLoading | async) && last" observeVisibility (visible)="onBottomSeen()">
          &nbsp;
        </div>
      </li>
      <div class="spinner-border" *ngIf="loadOnScroll?.isLoading | async; else notLoading">&nbsp;</div>
    </ul>
    <ng-template #noResults>
      <div class="no-results">
        <span class="no-results-text">لا يوجد اختيارات</span>
      </div>
    </ng-template>
  </div>
</div>
