import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { WithdrawalService } from './withdrawals.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getWithdrawalsStart,
  getWithdrawalsSuccess,
  getWithdrawalsFailure,
  getMoreWithdrawalsStart,
  getMoreWithdrawalsFailure,
  getMoreWithdrawalsSuccess,
  createWithdrawalStart,
  createWithdrawalSuccess,
  createWithdrawalFailure,
  deleteWithdrawalStart,
  deleteWithdrawalSuccess,
  deleteWithdrawalFailure,
  updateWithdrawalStart,
  updateWithdrawalSuccess,
  updateWithdrawalFailure,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class WithdrawalEffects {
  constructor(
    private withdrawalService: WithdrawalService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  getWithdrawalsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWithdrawalsStart),
      switchMap(() =>
        this.withdrawalService.getWithdrawals().pipe(
          mergeMap((data) => [getWithdrawalsSuccess({ payload: data })]),
          catchError((error) => observableOf(getWithdrawalsFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreWithdrawalsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreWithdrawalsStart),
      switchMap(({ payload: { page } }) =>
        this.withdrawalService.getMoreWithdrawals(page).pipe(
          mergeMap((data) => [getMoreWithdrawalsSuccess({ payload: data })]),
          catchError((error) => observableOf(getMoreWithdrawalsFailure({ error: error?.message })))
        )
      )
    )
  );

  createWithdrawalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWithdrawalStart),
      switchMap(({ payload }) =>
        this.withdrawalService.createWithdrawal(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createWithdrawalSuccess(), getWithdrawalsStart()];
          }),
          catchError((error) => observableOf(createWithdrawalFailure({ error: error?.message })))
        )
      )
    )
  );

  updateWithdrawalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWithdrawalStart),
      switchMap(({ payload, id }) =>
        this.withdrawalService.updateWithdrawal(payload, id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [updateWithdrawalSuccess(), getWithdrawalsStart()];
          }),
          catchError((error) => observableOf(updateWithdrawalFailure({ error: error?.message })))
        )
      )
    )
  );

  deleteWithdrawalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteWithdrawalStart),
      switchMap(({ payload: { id } }) =>
        this.withdrawalService.deleteWithdrawal(id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [deleteWithdrawalSuccess(), getWithdrawalsStart()];
          }),
          catchError((error) => observableOf(deleteWithdrawalFailure({ error: error?.message })))
        )
      )
    )
  );
}
