<div *ngIf="options.length" [ngClass]="{ opened}" class="bey-select multi-options">
  <div class="button" (click)="onClick()">
    <img class="icon icon-filter" src="assets/images/icons/Filter.png" />
    <span class="placeholder">{{ placeholder || 'تصفية' }}</span>
    <span *ngIf="multipleSelection">({{ selectedOptions.size }})</span>
    <img class="icon icon-chevron_down" src="assets/images/icons/Chevron-down.png" />
  </div>

  <div class="menu">
    <ul class="list" *ngFor="let option of options">
      <li>
        <ul>
          <li class="text-capitalize font-weight-bolder">{{ option.placeholder }}:</li>
          <li class="py-0">
            <ul class="list">
              <li
                *ngFor="let itm of option.values"
                [ngClass]="{
                  selected: selectedOptions.has(itm.label)
                }"
                (click)="onSelect(itm, option.name)"
                class="px-0">
                <div class="check-box">
                  <img src="assets/images/icons/white-check-mark.png" />
                </div>
                <span class="label">{{ itm.label }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
