import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bey-badge',
  templateUrl: './bey-badge.component.html',
  styleUrls: ['./bey-badge.component.scss'],
})
export class BeyBadgeComponent implements OnInit {
  /****
   * the content of the badge
   */
  @Input() placeholder: string;

  /****
   * determines if the badge is light or not
   */
  @Input() light: boolean = false;

  /****
   * detemines if the badge going to look like a pill or not
   */
  @Input() pill: boolean = false;

  /****
   * determines the color of the badge
   */
  @Input() color: 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'dark' = 'primary';

  /****
   * icon name to be used from feather icons
   */
  @Input() icon: string;

  constructor() {}

  ngOnInit(): void {}

  getClasses(): string {
    return `badge ${this.pill ? 'badge-pill' : ''} badge${this.light ? '-light' : ''}-${this.color} text-capitalize`;
  }
}
