import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'الرئيسية',
    type: 'item',
    icon: 'home',
    url: 'home',
  },
  {
    id: 'profile',
    title: 'الشخصية',
    type: 'item',
    icon: 'user',
    url: 'profile',
  },
  {
    id: 'shipments',
    title: 'شحناتي',
    type: 'item',
    icon: 'truck',
    url: 'shipments',
  },
  {
    id: 'boxes',
    title: 'تجميعاتي',
    type: 'item',
    icon: 'box',
    url: 'boxes',
  },
  {
    id: 'wallets',
    title: 'محافظي',
    type: 'item',
    icon: 'archive',
    url: 'wallets',
  },
  {
    id: 'deposits',
    title: 'شراءاتي',
    type: 'item',
    icon: 'dollar-sign',
    url: 'purchases',
  },
  {
    id: 'addresses',
    title: 'عناويني',
    type: 'item',
    icon: 'map-pin',
    url: 'addresses',
  },
  {
    id: 'deposits',
    title: 'إيداعاتي',
    type: 'item',
    icon: 'arrow-down',
    url: 'deposits',
  },
  {
    id: 'withdrawals',
    title: 'سحباتي',
    type: 'item',
    icon: 'external-link',
    url: 'withdrawals',
  },
  {
    id: 'about',
    title: 'تواصل معنا',
    type: 'item',
    icon: 'phone',
    url: 'about',
  },
  {
    id: 'calculator',
    title: 'الآلة الحاسبة',
    type: 'item',
    icon: 'clipboard',
    url: 'calculator',
  },
  {
    id: 'prices',
    title: 'الأسعار',
    type: 'item',
    icon: 'trending-up',
    url: 'prices',
  },
  {
    id: 'logout',
    title: 'تسجيل الخروج',
    type: 'item',
    icon: 'power',
    url: 'logout',
  },
];
