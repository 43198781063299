<form
  dir="rtl"
  class="form-inline"
  style="display: flex; flex-direction: column; align-items: self-start; padding-bottom: 20px">
  <label class="mb-50">{{ label }}</label>
  <div class="form-group w-100">
    <div class="input-group w-100">
      <div (click)="onClick()" [ngClass]="{ 'p-e-n': disabled }" class="input-group-append">
        <button
          class="btn btn-outline-secondary feather icon-calendar"
          (click)="basicDP.toggle()"
          type="button"
          rippleEffect></button>
      </div>
      <input
        class="form-control"
        name="Basic Date Picker"
        [(ngModel)]="basicDPdata"
        (ngModelChange)="onDateChange($event)"
        ngbDatepicker
        #basicDP="ngbDatepicker" />
    </div>
  </div>
</form>
