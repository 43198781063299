import { AfterContentChecked, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { weekDays } from 'assets/utility/consts';

export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyDatePickerComponent),
  multi: true,
};

@Component({
  selector: 'bey-date-picker',
  templateUrl: './bey-date-picker.component.html',
  styleUrls: ['./bey-date-picker.component.scss'],
  providers: [CONTROL_VALUE_ACCESSOR],
})
export class BeyDatePickerComponent implements OnInit, AfterContentChecked, ControlValueAccessor {
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() initialValue: NgbDateStruct;

  changesDone: boolean = false;
  basicDPdata: NgbDateStruct;
  constructor() {}

  ngOnInit(): void {
    if (this.initialValue) {
      this.basicDPdata = this.initialValue;
    }
  }

  ngAfterContentChecked(): void {
    if (document.getElementsByName('Basic Date Picker').item(0)) {
      document.getElementsByName('Basic Date Picker').item(0).style.pointerEvents = 'none';

      if (!!document.getElementsByClassName('custom-select')?.length && !this.changesDone) {
        [0, 1].forEach((no) => document.getElementsByClassName('custom-select').item(no).setAttribute('dir', 'ltr'));

        [0, 1, 2, 3, 4, 5, 6, 7, 7, 8, 9, 10, 11].forEach((no) => {
          document.getElementsByClassName('custom-select').item(0).children[no].innerHTML = (no + 1).toString();
        });

        [0, 1, 2, 3, 4, 5, 6].forEach(
          (no) =>
            (document.getElementsByClassName('ngb-dp-weekday small ng-star-inserted').item(no).innerHTML = weekDays[no])
        );

        this.changesDone = true;
      }
    }
  }

  onClick(): void {
    this.changesDone = false;
  }

  onDateChange(x): void {
    this.value = x;
  }

  //-----From ControlValueAccessor interface-----
  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  get value(): any {
    return this.basicDPdata;
  }

  set value(v: any) {
    this.onChangeCallback(v);
  }

  writeValue(value: any) {
    this.basicDPdata = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  //---------------------------------------------
}
