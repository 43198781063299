import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BeyTableComponent } from './components/bey-table/bey-table.component';
import { BeyPaginationComponent } from './components/bey-pagination/bey-pagination.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreDirectivesModule } from '@core/directives/directives';
import { AngularMaterialModule } from './modules/angular-material.module';
import { BeyWithSpinnerComponent } from './components/bey-with-spinner/bey-with-spinner.component';
import { BeyInputComponent } from './components/bey-input/bey-input.component';
import { BeyButtonComponent } from './components/bey-button/bey-button.component';
import { BeyBadgeComponent } from './components/bey-badge/bey-badge.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { BeyFileComponent } from './components/bey-file/bey-file.component';
import { BeyDialogComponent } from './components/bey-dialog/bey-dialog.component';
import { ContentHeaderModule } from '../../layout/components/content-header/content-header.module';
import { BeyFilterComponent } from './components/bey-filter/bey-filter.component';
import { BeySelectComponent } from './components/bey-select/bey-select.component';
import { AbsValuePipe } from './pipes/abs-value.pipe';
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { BeyCountryFlagComponent } from './components/bey-country-flag/bey-country-flag.component';
import { BeyMenuComponent } from './components/bey-menu/bey-menu.component';
import { BeySortComponent } from './components/bey-sort/bey-sort.component';
import { OutsideClickListenerDirective } from './directives/outside-click-listener.directive';
import { CountryPhoneNumberInputComponent } from './components/country-phone-number-input/country-phone-number-input.component';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask-beyonic';
import { BeyDatePickerComponent } from './components/bey-date-picker/bey-date-picker.component';
import { NoScrollInputDirective } from './directives/no-scroll-input.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { InsureShipmentDialogContentComponent } from '../users/components/insure-shipment-button/insure-shipment-dialog-content/insure-shipment-dialog-content.component';
import { InsureShipmentButtonComponent } from '../users/components/insure-shipment-button/insure-shipment-button.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};

const components = [
  BeyTableComponent,
  BeyPaginationComponent,
  BeyWithSpinnerComponent,
  BeyInputComponent,
  BeyButtonComponent,
  BeyBadgeComponent,
  BeyFileComponent,
  BeyDialogComponent,
  BeyFilterComponent,
  BeySelectComponent,
  BeyCountryFlagComponent,
  BeyMenuComponent,
  BeySortComponent,
  CountryPhoneNumberInputComponent,
  BeyDatePickerComponent,
  InsureShipmentButtonComponent,
  InsureShipmentDialogContentComponent,
];

const directives = [
  ObserveVisibilityDirective,
  OutsideClickListenerDirective,
  NoScrollInputDirective,
  ScrollToDirective,
];

const pipes = [FormatDatePipe, AbsValuePipe];

@NgModule({
  declarations: [...directives, ...pipes, ...components],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgSelectModule,
    NgbModule,
    CoreDirectivesModule,
    AngularMaterialModule,
    CurrencyMaskModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CoreDirectivesModule,
    AngularMaterialModule,
    ContentHeaderModule,
    NgSelectModule,
    ...directives,
    ...pipes,
    ...components,
  ],
  providers: [{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }],
})
export class SharedModule {}
