import { Directive, ElementRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { setScrollingPosition } from 'app/ngrx/misc/misc.actions';
import { selectScrollingPosition } from 'app/ngrx/misc/misc.selectors';
import { Observable, Subscription, zip } from 'rxjs';

@Directive({
  selector: '[scroll-to]',
})
export class ScrollToDirective {
  currentRoute: string;
  timeout: NodeJS.Timeout;

  @Input() conditions: Array<Observable<any>> = [];

  subs$: Subscription = new Subscription();
  constructor(private _el: ElementRef, private store: Store) {}

  ngOnInit(): void {
    this.currentRoute = window.location.pathname;

    this.subs$.add(
      zip(...this.conditions).subscribe((e) => {
        // reduce conditions to one boolean value
        let reducedArray = e.reduce((total, value) => {
          if (!!!value && total) {
            return !!value;
          }

          return total;
        }, true);

        if (reducedArray) {
          this.subs$.add(
            this.store.select(selectScrollingPosition).subscribe((data) => {
              if (this.currentRoute === data?.route) {
                this.timeout = setTimeout(() => {
                  window.scrollTo({ top: data['position'] });
                }, 1);
              }
            })
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      setScrollingPosition({
        payload: {
          position: document.documentElement.scrollTop || document.body.scrollTop,
          route: this.currentRoute,
        },
      })
    );

    clearTimeout(this.timeout);
    this.subs$.unsubscribe();
  }
}
