<!-- Error page-->
<div class="misc-wrapper">
  <a class="brand-logo" href="javascript:void(0);">
    <span>&nbsp;</span>
    <h2 class="brand-text text-primary ml-1">ليبيان إكسبريس</h2></a
  >
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">الصفحة غير موجودة 🕵🏻‍♀️</h2>
      <a class="btn btn-primary mb-2 btn-sm-block" (click)="onButtonClick()" rippleEffect>العودة إلى الرئيسية</a
      ><img
        class="img-fluid"
        [src]="
          coreConfig.layout.skin === 'dark' ? 'assets/images/pages/error-dark.svg' : 'assets/images/pages/error.svg'
        "
        alt="Error page" />
    </div>
  </div>
</div>
<!-- / Error page-->
