import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BoxService } from './boxes.service';
import { Router } from '@angular/router';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import {
  getShipmentsStart,
  getBoxesStart,
  getBoxesSuccess,
  getBoxesFailure,
  getMoreBoxesStart,
  getMoreBoxesFailure,
  getMoreBoxesSuccess,
  createBoxStart,
  createBoxSuccess,
  createBoxFailure,
  deleteBoxStart,
  deleteBoxSuccess,
  deleteBoxFailure,
  updateBoxStart,
  updateBoxSuccess,
  updateBoxFailure,
  getBoxDetailsStart,
  getBoxDetailsSuccess,
  getBoxDetailsFailure,
  getBoxShipmentsStart,
  getBoxShipmentsSuccess,
  getBoxShipmentsFailure,
  getMoreBoxShipmentsStart,
  getMoreBoxShipmentsSuccess,
  getMoreBoxShipmentsFailure,
  addShipmentToBoxStart,
  addShipmentToBoxSuccess,
  addShipmentToBoxFailure,
  updateBoxStatusStart,
  updateBoxStatusSuccess,
  updateBoxStatusFailure,
  removeShipmentFromBoxStart,
  removeShipmentFromBoxSuccess,
  removeShipmentFromBoxFailure,
  getShipmentDetailsStart,
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { BeyToastService } from 'app/modules/shared/services/bey-toast.service';

@Injectable()
export class BoxEffects {
  constructor(
    private boxService: BoxService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  removeShipmentFromBoxEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeShipmentFromBoxStart),
      switchMap(({ payload }) =>
        this.boxService.removeShipmentFromBox(payload).pipe(
          mergeMap((data) => {
            this.dialog.closeAll();
            return [
              removeShipmentFromBoxSuccess(),
              // getShipmentsStart({ payload: { 'filter[collection_id]': payload['collection_id'] } }),
            ];
          }),
          catchError((error) => observableOf(removeShipmentFromBoxFailure({ error: error?.message })))
        )
      )
    )
  );

  addShipmentToBoxEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addShipmentToBoxStart),
      switchMap(({ payload }) =>
        this.boxService.addShipmentToBox(payload).pipe(
          mergeMap((data) => {
            this.dialog.closeAll();
            return [
              addShipmentToBoxSuccess(),
              // getShipmentsStart({ payload: { 'filter[collection_id]': payload['collection_id'] } }),
            ];
          }),
          catchError((error) => observableOf(addShipmentToBoxFailure({ error: error?.message })))
        )
      )
    )
  );

  updateBoxStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBoxStatusStart),
      switchMap(({ payload }) =>
        this.boxService.updateBoxStatus(payload).pipe(
          mergeMap((data) => {
            this.dialog.closeAll();
            let observables = [updateBoxStatusSuccess()];

            if (payload.isShipment) {
              observables.push(getShipmentDetailsStart({ payload: { id: payload.boxId } }));
            } else {
              observables.push(
                // getShipmentsStart({ payload: { 'filter[collection_id]': payload.boxId } }),
                getBoxDetailsStart({ payload: { id: payload['boxId'] } })
              );
            }
            return observables;
          }),
          catchError((error) => observableOf(updateBoxStatusFailure({ error: error?.message })))
        )
      )
    )
  );

  getBoxesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBoxesStart),
      switchMap(({ payload }) =>
        this.boxService.getBoxes(payload).pipe(
          mergeMap((data) => [getBoxesSuccess({ payload: data })]),
          catchError((error) => observableOf(getBoxesFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreBoxesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreBoxesStart),
      switchMap(({ payload }) =>
        this.boxService.getMoreBoxes(payload).pipe(
          mergeMap((data) => [getMoreBoxesSuccess({ payload: data })]),
          catchError((error) => observableOf(getMoreBoxesFailure({ error: error?.message })))
        )
      )
    )
  );

  createBoxEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBoxStart),
      switchMap(({ payload }) =>
        this.boxService.createBox(payload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [createBoxSuccess(), getBoxesStart({})];
          }),
          catchError((error) => observableOf(createBoxFailure({ error: error?.message })))
        )
      )
    )
  );

  updateBoxEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBoxStart),
      switchMap(({ payload, id, callBack }) =>
        this.boxService.updateBox(payload, id).pipe(
          mergeMap(() => {
            callBack();
            return [updateBoxSuccess(), getBoxesStart({})];
          }),
          catchError((error) => observableOf(updateBoxFailure({ error: error?.message })))
        )
      )
    )
  );

  deleteBoxEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBoxStart),
      switchMap(({ payload: { id } }) =>
        this.boxService.deleteBox(id).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            return [deleteBoxSuccess(), getBoxesStart({})];
          }),
          catchError((error) => observableOf(deleteBoxFailure({ error: error?.message })))
        )
      )
    )
  );

  getBoxDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBoxDetailsStart),
      switchMap(({ payload: { id } }) =>
        this.boxService.getBoxDetails(id).pipe(
          mergeMap(({ data }) => [getBoxDetailsSuccess({ payload: data })]),
          catchError((error) => observableOf(getBoxDetailsFailure({ error: error?.message })))
        )
      )
    )
  );

  getBoxShipmentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBoxShipmentsStart),
      switchMap(({ payload: { boxId } }) =>
        this.boxService.getBoxShipments(boxId).pipe(
          mergeMap((data) => [getBoxShipmentsSuccess({ payload: data })]),
          catchError((error) => observableOf(getBoxShipmentsFailure({ error: error?.message })))
        )
      )
    )
  );

  getMoreBoxShipmentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreBoxShipmentsStart),
      switchMap(({ payload: { boxId, page } }) =>
        this.boxService.getMoreBoxShipments(boxId, page).pipe(
          mergeMap((data) => [getMoreBoxShipmentsSuccess({ payload: data })]),
          catchError((error) => observableOf(getMoreBoxShipmentsFailure({ error: error?.message })))
        )
      )
    )
  );
}
