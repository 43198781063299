import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import {
  GetBoxesResponseType,
  CreateBoxRequestBody,
  UpdateBoxRequestBody,
  GetBoxShipmentsResponseType,
  Box,
  AddShipmentToBoxRequestBody,
  UpdateBoxStatusRequestBody,
  RemoveShipmentFromBoxRequestBody,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class BoxService {
  constructor(private http: HttpClient) {}

  removeShipmentFromBox({ collection_id, shipment_id }: RemoveShipmentFromBoxRequestBody) {
    return this.http.delete(
      environment.API_URL + `api/v1/shipment-collections/${collection_id}/shipments/${shipment_id}`
    );
  }

  addShipmentToBox({ collection_id, shipments }: AddShipmentToBoxRequestBody) {
    return this.http.post(environment.API_URL + `api/v1/shipment-collections/${collection_id}/shipments`, {
      shipments,
    });
  }

  updateBoxStatus({ boxId, status, description, isShipment }: UpdateBoxStatusRequestBody) {
    return this.http.put(
      isShipment
        ? `${environment.API_URL}api/v1/shipments/${boxId}/done`
        : `${environment.API_URL}api/v1/shipment-collections/${boxId}/${status}`,
      description && !isShipment ? { description } : null
    );
  }

  getBoxDetails(id: number) {
    return this.http.get<{ data: Box }>(environment.API_URL + 'api/v1/shipment-collections/' + id);
  }

  getBoxes(paramsValues: object) {
    let params = new HttpParams();

    if (paramsValues) {
      Object.keys(paramsValues).forEach((key) => {
        const val = paramsValues[key];
        val && (params = params.append(key, val));
      });
    }
    return this.http.get<GetBoxesResponseType>(environment.API_URL + 'api/v1/shipment-collections', { params });
  }

  getMoreBoxes(paramsValues: object) {
    let params = new HttpParams();

    if (paramsValues) {
      Object.keys(paramsValues).forEach((key) => {
        const val = paramsValues[key];
        val && (params = params.append(key, val));
      });
    }
    return this.http.get<GetBoxesResponseType>(environment.API_URL + 'api/v1/shipment-collections', { params });
  }

  createBox(payload: CreateBoxRequestBody) {
    return this.http.post<any>(environment.API_URL + 'api/v1/shipment-collections', payload);
  }

  updateBox(payload: UpdateBoxRequestBody, id: number) {
    return this.http.patch<any>(environment.API_URL + 'api/v1/shipment-collections/' + id, payload);
  }

  deleteBox(id: number) {
    return this.http.delete<any>(environment.API_URL + `api/v1/shipment-collections/${id}`);
  }

  getBoxShipments(boxId: number) {
    return this.http.get<GetBoxShipmentsResponseType>(
      environment.API_URL + `api/v1/shipment-collections/${boxId}/shipments`
    );
  }

  getMoreBoxShipments(boxId: number, page: number) {
    return this.http.get<GetBoxShipmentsResponseType>(
      environment.API_URL + `api/v1/shipment-collections/${boxId}/shipments?page=${page}`
    );
  }
}
