import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BeyCountrySelectorOptionInterface } from 'app/ngrx/interfaces';
import { AsYouType } from 'libphonenumber-js';

/****
 *  Optionally we can use [(ngModel)]="var" on this component
 */
const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CountryPhoneNumberInputComponent),
  multi: true,
};

@Component({
  selector: 'country-phone-number-input',
  templateUrl: './country-phone-number-input.component.html',
  styleUrls: ['./country-phone-number-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryPhoneNumberInputComponent implements OnInit {
  /**
   * Country options following BeyCountrySelectorOptionInterface interface
   */
  @Input()
  options: Array<BeyCountrySelectorOptionInterface> = [{ label: 'ليبيا', value: '+218', isoCode: 'ly' }];

  /***
   * The name of the property that we're using to find the option's name from the options array
   */
  @Input()
  bindLabel: string = 'label';

  /***
   * The name of the property that we're using to find the option's value from the options array
   *  IN CASE YOU WANT THE FULL OBJECT THEN DON'T PROVIDE A BINDING VALUE
   */
  @Input()
  bindValue: string;

  /***
   * Make the selector a readonly state
   */
  @Input()
  readonly: boolean = false;

  /***
   * Display error feedback on validation
   */
  @Input()
  displayError: boolean = false;

  /***
   * Hide the validation feedback if some other method is used to show that
   */
  @Input()
  hideValidationFeedback: boolean = false;

  /***
   * Validation error message
   */
  @Input()
  errorMessage: string = '';

  /***
   * Text for the label displayed above the component
   */
  @Input()
  label: string;

  /****
   * Class list to override in form of object
   * for the following elements: label
   */
  @Input()
  classList: { [label: string]: string };

  /****
   *  Disable the field
   */
  @Input()
  disabled: boolean = false;

  /****
   * Transparent background for input fields
   */
  @Input()
  transparent: boolean = false;

  //The internal data model
  private innerValue: { country: BeyCountrySelectorOptionInterface; phone: number } = {
    country: this.options[0],
    phone: null,
  };

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  public inputFieldValue = null;
  public focused: boolean = false;
  private asYouType;
  private navigationByKeyIndex: number = 0;

  @ViewChildren('countryOption')
  private countryOptions: QueryList<ElementRef>;

  @ViewChild('codeInput')
  private codeInputElement: ElementRef;

  constructor() {
    this.asYouType = new AsYouType();
  }

  ngOnInit(): void {}

  // Accessor of the component value ------NOT THE INNER INPUT FIELD THE ACTUAL VALUE SELECTED BY THE USER-------
  get value(): any {
    return this.innerValue;
  }

  // //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.inputFieldValue = v?.value || '';
      this.onChangeCallback(v);
    }
  }

  // Update the inner input field value to be able to search for a specific value
  updateInputVal(e) {
    const { value = '' } = e.target;
    this.inputFieldValue = value;
    // Rest the selected value
    if (!value) {
      this.value.country = null;
    }
  }

  phoneInputHandler(e) {
    const { value = '' } = e.target;
    /// prevent leading zero
    if (value === '0') {
      e.target.value = '';
      return;
    } // prevent leading zero
    this.innerValue.phone = value;
    // Rest the selected value
    if (!value) {
      this.value.phone = null;
    }

    // Reset the whole thing
    this.asYouType.reset();
    // If we have a country then add it to the parser and concat with the phone number
    if (this.innerValue.country) {
      // some +800 doesn't have a country code
      if (this.innerValue.country?.['isoCode']) {
        this.asYouType.country = this.innerValue.country?.['isoCode'].toUpperCase();
      }

      this.asYouType.input(`${this.innerValue.country?.['value']} ${value}`);
    } else {
      // else just add the number
      this.asYouType.input(`${value}`);
    }
    const exVal = this.asYouType.getNumber()?.number || '';
    this.onChangeCallback(exVal);
  }

  // Opens the options menu
  toggleFocus(isOut: boolean = false) {
    this.focused = true;
    // Rest the input field value to the selected country if available
    if (isOut) {
      this.onBlur();
      this.focused = false;
      // reset the navigation index since we're filtering and the indexes are changing
      this.navigationByKeyIndex = 0;
    }
  }

  selectCountry(country: BeyCountrySelectorOptionInterface) {
    this.innerValue.country = country;
    const val = this.bindValue ? country[this.bindValue] : country;
    this.inputFieldValue = this.bindValue ? country[this.bindValue] : val?.value;

    // Reset the whole thing
    this.asYouType.reset();
    country.isoCode && (this.asYouType.country = country.isoCode.toUpperCase()); // +800 doesn't have an isoCode

    // If we have a country then add it to the parser and concat with the phone number
    if (this.innerValue.phone) {
      this.asYouType.input(`${this.innerValue.country?.['value']} ${this.innerValue.phone}`);
    } else {
      // else just add the number
      this.asYouType.input(`${this.innerValue.country?.['value']}`);
    }

    const exVal = this.asYouType.getNumber()?.number || '';
    this.onChangeCallback(exVal);
    this.toggleFocus(true);
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback(); // it was removed so we show the 'invalid phone number' only when user enters phone number after selecting the country code
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    // Check if the value we have from external change is within our list of options and then update the component value
    if (value) {
      if (typeof value === 'object') {
        const val = this.bindValue ? value[this.bindValue] : value;
        this.inputFieldValue = this.bindValue ? value[this.bindValue] : val?.value || null;
        this.onChangeCallback(val);
        return;
      } else {
        const country = this.getCountryFromOptions(value);
        // This will populate the component on external changes of value
        if (country) {
          this.innerValue.country = country;
          const val = this.bindValue ? country[this.bindValue] : country;
          this.inputFieldValue = this.bindValue ? country[this.bindValue] : val?.value || null;
          this.onChangeCallback(val);
          return;
        }
      }
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    // Set the inner input field value provided from the upper level component
    if (this.value) {
      const country = this.getCountryFromOptions(this.value);

      if (country) {
        this.innerValue.country = country;
        this.inputFieldValue = this.bindValue ? country[this.bindValue] : country?.value || null;
      }
    }
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /*****
   * This function returns country object from the set of options provided
   * @param value
   */
  getCountryFromOptions(value) {
    return this.options.find((c) => c.value === value);
  }

  // remove the selected value
  clear() {
    this.innerValue.country = null;
    this.inputFieldValue = null;
    this.onChangeCallback('');
  }

  /**
   * This method is used to navigate between the input field and the list of the countries
   * @param event
   */
  jumpToList(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      // Navigate to the dropdown list
      event.preventDefault();
      const firstItm = this.countryOptions.first;

      if (firstItm) {
        firstItm.nativeElement.focus();
      }
    } else if (event.key === 'Enter') {
      // close the dropdown and choose the value but only
      // if we have a country selected before and then close
      if (this.innerValue.country) {
        this.toggleFocus(true);
      }
    } else if (event.key === 'Escape') {
      // close the dropdown
      this.toggleFocus(true);
    }
  }

  /****
   *  Handle the key press event on the dropdown
   * @param event
   * @param value
   */
  handleKey(event: KeyboardEvent, value: BeyCountrySelectorOptionInterface) {
    // First find the element from the QueryList using the tabindex attribute saved inside the navigationByKeyIndex
    const element = this.countryOptions.find((el, index) => index === this.navigationByKeyIndex);
    if (!element) {
      return null;
    }

    const index = Number(element.nativeElement.getAttribute('tabindex'));

    switch (event.key) {
      case 'ArrowDown':
        // If the current index is less than the menu items we navigate to the next element
        if (this.navigationByKeyIndex < this.countryOptions.length - 1) {
          this.navigationByKeyIndex = index + 1;
        }
        break;

      case 'ArrowUp':
        // If the current index is zero, and we press up this means the user wants to use the input field
        if (this.navigationByKeyIndex === 0) {
          // close the list and move focus back to the input field
          const id = setTimeout(() => {
            this.toggleFocus(true);
            this.codeInputElement.nativeElement.focus();
            clearTimeout(id);
          }, 0);
        } else {
          // else navigate to the previous item on the list
          this.navigationByKeyIndex = index - 1;
          element.nativeElement.focus();
        }
        break;

      case 'Enter':
        value && this.selectCountry(value);
        break;

      case 'Escape':
        this.toggleFocus(true);
        break;

      case 'Backspace':
        this.clear();
        const id = setTimeout(() => {
          this.countryOptions.first.nativeElement.focus();
          this.codeInputElement.nativeElement.focus();
          this.navigationByKeyIndex = 0;
          clearTimeout(id);
        });
        break;

      default:
        if (/^[a-zA-Z0-9]$/.test(event.key)) {
          // set focus and use the key in the input field
          const id = setTimeout(() => {
            this.codeInputElement.nativeElement.focus();
            this.inputFieldValue = event.key;
            this.navigationByKeyIndex = 0;
            clearTimeout(id);
          }, 0);
        }

        break;
    }

    const newElement = this.countryOptions.find((el, index) => index === this.navigationByKeyIndex);

    if (newElement) {
      newElement.nativeElement.focus();
    }
  }
}
